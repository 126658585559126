.Testimonials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 60px;
  font-family: "Helvetica";
  font-weight: 400;
  font-size: 27px;
  text-align: center;
  color: #e9117c;
}

.Testimonials-Image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 170px;
}
.Testimonials-Image > img {
  width: 362px;
  height: 464px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 25px;
}

.left-arrow{
  color: #b4b9e4;
  font-size: 50px;
  cursor: pointer;
}
.right-arrow{
  color: #b4b9e4;
  font-size: 50px;
  cursor: pointer;
}
.Testimonials-Context {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  color: #494949;
}

.Testimonials-Context > span {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: rgba(67, 66, 66, 0.71);
}

 .desktopHeading{
    display: inline;
  }





/******************* Media Query BIG SCREENS************************/


@media (min-width: 1921px) and (max-width: 2560px) {



}

/**************** Media Query DESKTOPS*****************************/


@media (min-width: 1441px) and (max-width: 1920px) {



}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {



}



/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {



}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {

  .Testimonials-Image >img{
  
    width: 192px;
    height: 240px;
  
  }
  .Testimonials-Image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 30px;
  }

.left-arrow{
  color: #b4b9e4;
  font-size: 50px;
  cursor: pointer;
  
}
.right-arrow{
  color: #b4b9e4;
  font-size: 50px;
  cursor: pointer;
  margin-left: -118px;
}



}

/********************* Media Query MOBILES*********************/

@media screen and (max-width: 425px) {

  .Testimonials-Image>img {
  
    width: 192px;
    height: 240px;
  
  }
  .Testimonials-Image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 30px;
  }
  .left-arrow{
    color: #b4b9e4;
    font-size: 50px;
    cursor: pointer;
   
  }
  .right-arrow{
    color: #b4b9e4;
    font-size: 50px;
    cursor: pointer;
   
  }
  
  .Testimonials-Context {
    font-size:16px;
  }
  .Testimonials-Context > span {
    font-size: 14px;
  }

  .desktopHeading{
    display: none;
  }

}