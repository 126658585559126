.Profile-Setting-Tab {
  width: 382px;
  height: 382px;
}
.Customer-Support {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffecd2;
  padding: 150px 150px 100px 150px;
  gap: 40px;
}

.Customer-Support-Form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  width: 868px;
  height: 553px;
  background: #ffffff;
  border-radius: 25px;
  padding: 40px;
  gap: 40px;
}

.Customer-Support-Form form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.Customer-Support-Form input {
  width: 420px;
  height: 40px;
  border: 1px solid #b4b9e4;
  border-radius: 4px;
  outline: none;
  padding-left: 15px;
}

.Customer-Support-Form label {
  font-size: 14px;
  color: #494949;
}
.Customer-Support-Form button {
  width: 140px;
  height: 40px;
  background: #e9117c;
  color: #fff;
  border-radius: 7px;
  border: none;
}
.Customer-Support-Form-TextArea .k-input-inner {
  width: 419px;
  height: 109px;
  border: 1px solid #b4b9e4;
  border-radius: 4px;
  padding: 15px;
  font-size: 18px;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .MobileViewCustomerSupport {
    margin-bottom: -25px;
    font-size: larger;
  }

  .MobileViewCustomerSupportForm{
    margin-bottom: 20%;
  }

  .MobileViewCustomerSupportButton {
    width: 100px;
    height: 30px;
    background: #e9117c;
    color: #fff;
    border-radius: 15px;
    border: none;
  }

  .Profile-Setting-Tab {
  display: none;
}
}
