.keywordSearchContainer {
  padding: 2rem 6rem;
}

.keywordSearchContainerHead {
  margin: 30px 0px;
}
.KeywordSearch-mid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3rem;
  column-gap: 40px;
}
.KeywordSearch-Input > input {
  width: 419px;
  height: 40px;
  border: 1px solid #b4b9e4;
  border-radius: 4px;
}
.KeywordSearch-Button > button {
  width: 140px;
  height: 40px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  background: #e9117c;
  border-radius: 7px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.KeywordSearch-Examples {
  margin-top: 4rem;
}
.KeywordSearch-Footer > p {
  color: rgba(73, 73, 73, 0.5);
}
.KeywordSearch-Footer-Line {
  height: 1px;
  width: 90%;
  margin: 30px 0px;
  background-color: rgba(73, 73, 73, 0.5);
}

.KeywordSearch-ExamplesMid {
  display: none;
}

.keyword-bottom-mobile-hrTag {
  display: none;
}

.KeywordSearch-Input > i {
  display: none;
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .keywordSearchContainer {
    padding: 2rem 1rem;
  }
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .DesktopSearchHeader {
    display: none;
  }

  .keywordSearchContainer {
    padding: 1rem 2rem;
  }

  .KeywordSearch-mid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    gap: 30px;
    margin: 0rem 3rem;
  }

  .KeywordSearch-ExamplesMid {
    display: inline;
    font-size: small;
    text-align: left;
    margin-left: -45px;
  }

  .KeywordSearch-Examples {
    display: none;
  }

  .KeywordSearch-Button {
    margin-bottom: 30px;
  }

  .keyword-bottom-mobile-hrTag {
    display: inline;
    padding: 10px 0px;
  }

  .KeywordSearch-Footer > p {
    font-size: 0.8em;
    letter-spacing: -0.5px;
  }

  .KeywordSearch-Input {
    position: relative;
    text-align: center;
  }

  .KeywordSearch-Input > i {
    display: inline;
    position: absolute;
    top: 10px;
    left: 10px;
    color: #8e8e8e;
    font-size: 1.2em;
  }

  .KeywordSearch-Input > input {
    width: 379px;
    height: 40px;
    border: 1px solid #b4b9e4;
    border-radius: 4px;
    padding: 0px 50px;
  }

  .KeywordSearch-Input > input :focus {
    border: 1px solid #e9117c;
    outline: 1px solid #e9117c;
  }

   .KeywordSearch-Input > input :hover {
    border: 1px solid #e9117c;
    outline: 1px solid #e9117c;
  }

  .KeywordSearchError{
    color: #e91111;
    font-size: 1em;
    font-weight: 600;
  }
}
