.RSbasicPrefFields {
  padding: 5px 25px;
}

.RSDetailsSectionRow_EditTime{
    padding-top: 10px;
}

.RSDetails_section_label_EditTime {
  padding: 0px;
  width: 100%;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.05em;
  margin-top: 3px;
  color: rgba(73, 73, 73, 0.9);
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {

.RSDetails_section_label_EditTime > .BodyTypeEditTime,
.PartnerDetails_section_colon_EditTime > .BodyTypeEditTime{
  padding-top: 1px;
  padding-bottom: 110px;
}




.RSDetails_section_label_EditTime > .MaritalStatusEditTime,
.PartnerDetails_section_colon_EditTime > .MaritalStatusEditTime{
  padding-bottom: 146px;
}


.RSDetails_section_label_EditTime > .PhysicalStatusEditTime,
.PartnerDetails_section_colon_EditTime > .PhysicalStatusEditTime{
  padding-bottom: 68px;
}

.partnerDetails_section_values > .partnerFieldRange > .partnerPreferenceBigOption,
.partnerDetails_section_values > .partnerFieldRange > .partnerFieldRange > .partnerPreferenceBigOption{
  width: 68px;
  height: 40px;
}
}