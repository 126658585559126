.landingHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 0px 0px;
  height: 75px;
  background-color: #fff;
}
.landingTitle {
  height: 100%;
  width: 241.13px;
}
.landingTitle > img {
  height: 100%;
  width: 100%;
  padding-left: 20%;
}
.landingLogin {
  font-size: 22px;
  margin: auto;
  margin-right: 70px;
}
.landingLogin > button {
  margin-left: 15px;
  border: 2px solid #e9117c;
  color: #e9117c;
  font-size: 22px;
  background-color: #fff;
  padding: 5px;
  width: 125px;
  height: 47px;
  border-radius: 9px;
  outline: none;
}

/* ****************** Mobile ********************** */
@media (min-width: 319px) and (max-width: 426px) {
  .landingHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    /* width: 100%; */
  }

  .landingLogin > span {
    display: none;
  }

  .landingTitle {
    height: 120%;
    width: 241.13px;

    margin-left: 7%;
    margin-top: -2%;
  }

  .landingTitle > img {
    height: 100%;
    width: 80%;
    padding-left: 10%;
  }

  .landingLogin {
    position: relative;
  }

  .landingLogin > button {
        margin-top: 5px;
    border: 1px solid #e9117c;
    color: #e9117c;
    font-size: 13px;
    background-color: #fff;
    padding: 1px;
    width: 67px;
    height: 25px;
    border-radius: 4px;
    outline: none;
    position: absolute;
    right: -41px;
    top: -19px;

    text-transform:lowercase
  }
}
