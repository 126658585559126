.resetContainer {
  background-color: #fff;
  width: 1152px;
  z-index: 2;
  height: 100px;
  position: absolute;
  top: 0;
  margin-top: 140px;
  height: 730px;
  border-radius: 26px;
  overflow: hidden;
}
.resetFormContiner {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}
.resetForm {
  margin-left: -12rem;
}
.resetHeader {
  margin: 80px 0px 60px;
  color: #e9117c;
  font-weight: 500;
  font-size: 20px;
}

.resetErrors{
 margin: -50px 0px 30px;
  color: red;
  font-weight: 500;
  font-size: 17px;
}

  .resetButton {
    margin-top: 50px;
    text-align: center;
    height: 50px;

    margin-left: 10.5rem;
  }
  .resetButton > button {
    height: 50px;
  width: 290px;
    outline: none;
    border: none;
    background-color: #e9117c;
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

.passwrdSuccessFull {
  color: #e9117c;
  display: flex;
  font-weight: 500;
  font-size: 20px;
  margin-top: 14rem;
  margin-bottom: 1.2rem;
}
/* .resetForm > .formContainer > .regPassword {
    margin-bottom: 27px;
    width: 800px;
  } */
.resetForm > .formContainer > .regPassword {
  width: 350px;
}
.resetForm > .formContainer > .regPassword ~ .eyeicon {
  position: absolute;
  right: 210px;
}
.resetFull {
  height: 300px;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .resetHeader {
    margin: 5px 0px 15px;
  }

  .resetFormContiner {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
  }
  .resetForm {
    margin-left: 0rem;
  }

  .resetForm > .formContainer > .regPassword {
    width: 320px;
  }

  .resetButton {
    margin-top: 50px;
    text-align: center;
    height: 50px;

    margin-left: 0rem;
  }
  .resetButton > button {
    height: 42px;
    width: 169px;
    outline: none;
    border: none;
    background-color: #e9117c;
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .resetFull {
    height: 20px;
  }
}
