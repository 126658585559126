.MobileVerificationPopupOn > .editMobileVerificationContainer {
  width: 70%;
  height: fit-content;
  background-color: #ffffff;
  border: none;
  border-radius: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
  text-align: center;
}

.editMobileVerificationContainer > button {
  background: transparent;
  color: #e9117c;
  font-size: medium;
  border: none;
  outline: none;
  font-weight: 600;

  position: absolute;
  right: 20px;
  top: 10px;
}

.editMobileVerificationContainer > .editMobileHeader {
  margin-top: 40px;
}

.editMobileVerificationContainer > .editMobileText {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3em;
  line-height: 29px;

  color: #b4b9e4;
}

.editMobileVerificationContainer > .editMobileText > .MobileNo {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 29px;
  color: #050b38;
}

.editMobileVerificationContainer > .editMobileText > .editButon {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  color: #30b8e3;
  margin-bottom: 30px;
}

.editMobileVerificationContainer > .editMobileInp {
  margin: 30px 20px;
}

.editMobileVerificationContainer > .editMobileInp > form {
  margin: 0px;
}

/* Edit Mobile number page Varification popup */
.MobileVerificationPopupOn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.EditProfilePageEditMobileErr {
  color: red;
  position: absolute;
  top: 40px;
  left: 95px;
  font-weight: 500;
}

.mobileNumberVerificationInput > form {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
}


/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .MobileVerificationPopupOn > .editMobileVerificationContainer {
  width: 90%;
  height: fit-content;
  background-color: #ffffff;
  border: none;
  border-radius: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
  text-align: center;
}

.editMobileVerificationContainer > .editMobileText {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 29px;

  color: #b4b9e4;
  text-align: center;
}

.editMobileVerificationContainer > .editMobileHeader {
  margin-top: 40px;
  text-align: center;
}
}