.verifyBackgroundContainer {
  height: 100vh;
  overflow-y: hidden;
}
.verifyBackground {
  background-image: url("../../assets/images/ProfileRegister/Profile_register_page_BG_img.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.verifyCard {
  width: 1110px;
  overflow: hidden;
  border: none;
  height: fit-content;
  width: 807px;
  border-radius: 25px;
  margin-top: -10rem;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
    .verifyCard {
  /* width: 1110px; */
  overflow: hidden;
  border: none;
  height: fit-content;
  width: 350px;
  border-radius: 10px;
  margin-top: 0rem;
}
}