.aadharContainer {
  padding-top: 6rem;
  text-align: center;
  display: flex;
  justify-content: center;
  z-index: 100;
}
.backGroundAntique {
  /* z-index: 100; */
  background: antiquewhite;
  height: 100%;
}
.aadharVerificationStatus {
  background-color: #e9117c;
  color: #fff;
  padding: 2.2rem 0px;
}
.aadharVerificationTitle {
  color: #e9117c;
  font-weight: 500;
  margin: 45px 0px;
}
.aadharVerificationBox {
  background-color: #fff;
  padding: 3.5rem;
  width: 500px;
  margin-bottom: 0px;
  border-radius: 25px;
  position: relative;
}
.aadharVerificationBoxTitle {
  font-weight: 500;
}
.aadharVerificationBoxContent {
  margin-top: 1.4rem;
  margin: 1.6rem;
  line-height: 1.4rem;
}
.aadharVerificationBox > button {
  outline: none;
  background: transparent;
  border-radius: 15px;
  width: 160px;
  border: 1px solid #b4b9e4;
  padding: 8px;
  /* margin: 0rem 0rem; */
}
@media (min-width: 1025px) and (max-width: 1440px)
{
  .aadhar-img {
    height: 150px;
    width: 150px;
}
}
