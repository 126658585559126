/* PersonalDetails_ReligionInformation */
.PersonalDetails_ReligionInformation,
.EditTimePersonalDetails_ReligionInformation_Card {
  width: 81.1%;
}

.PersonalDetails_ReligionInformation > div > h6,
.EditTimePersonalDetails_ReligionInformation_Card > div > h6 {
  color: #e9117c;
  margin: 3% 0% 0% 0%;
  font-size: 1.8em;
}

.PersonalDetails_ReligionInformation > .card,
.EditTimePersonalDetails_ReligionInformation_Card > .card {
  background-color: #ffffff;
  height: 5%;
  margin: 3% 0% 2% 0%;
  position: relative;
  align-items: center;
  border-radius: 30px;
  border-left: #e9117c 8px solid;
  border-right: #e9117c 8px solid;
}

.PersonalDetails_ReligionInformation > .card > p,
.EditTimePersonalDetails_ReligionInformation_Card > .card > p {
  width: 95%;
  font-weight: 400;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(73, 73, 73, 0.9);
  padding: 4% 4% 1% 0%;
}


/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .PersonalDetails_ReligionInformation,
  .EditTimePersonalDetails_ReligionInformation_Card {
    width: 90.1%;

    position: relative;
  }

  .PersonalDetails_ReligionInformation > .fewWordBoxHeader,
  .EditTimePersonalDetails_ReligionInformation_Card > .fewWordBoxHeader {
    margin: 8% 0% 8% 0%;

    display: flex;
    align-items: center;
  }

  .DesktopViewEditButton {
    display: none;
  }

  .mobileViewEditButton {
    display: inline;
  }

  .EditPage_EditButton {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .PersonalDetails_ReligionInformation > div > h6,
  .EditTimePersonalDetails_ReligionInformation_Card > div > h6 {
    color: #e9117c;
    margin: 0%;
    font-size: 1.3em;
    font-weight: 500;
  }

  .PersonalDetails_ReligionInformation > div > .mobileViewEditButton,
  .EditTimePersonalDetails_ReligionInformation_Card > div > .mobileViewEditButton {
    color: #e9117c;
    margin: 7.5% 0px;
    font-size: 1em;
    font-weight: 500;
  }

  .PersonalDetails_ReligionInformation > .card {
    background-color: #ffffff;
    height: 5%;
    margin: 3% 0% 2% 0%;
    /* position: relative; */
    align-items: center;
    border-radius: 30px;
    border-left: #e9117c 4px solid;
    border-right: #e9117c 4px solid;
  }

  
  .EditTimePersonalDetails_ReligionInformation_Card > .card {
    background-color: #ffffff;
    height: 250px;
    margin: 3% 0% 2% 0%;
    /* position: relative; */
    align-items: center;
    border-radius: 30px;
    border-left: #e9117c 4px solid;
    border-right: #e9117c 4px solid;
  }

  .FedwWordsInputBox {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 21px;
    letter-spacing: 0.5em;

    color: rgba(73, 73, 73, 0.6);
  }
}
