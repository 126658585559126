.authBorderRegPink {
  background: #e9117c;
  width: 50%;
  margin-left: 50%;
  height: 6px;
  border-radius: 5px;
}
.registerContainer {
  background-color: #fff;
  width: 1152px;
  z-index: 2;
  height: 100px;
  position: absolute;
  top: 0;
  margin-top: 100px;
  height: 800px;
  border-radius: 26px;
  overflow: hidden;
}
.regFormContiner {
  margin-top: 35px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -50px;

  overflow-y: hidden;
}
.registerForm > .formContainer > input {
  margin-bottom: 23px;
  width: 540px;
}
.registerForm > .formContainer > .regPassword {
  width: 300px;
}
.registerForm > .formContainer > .regPassword ~ .eyeicon {
  position: absolute;
  right: 210px;
}
.registerErr {
  color: red;
  margin: 10px 0px;
  font-weight: 500;
}
.googleloginReg {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.googleIconReg {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.googleIconReg > img {
  height: 100%;
  width: 100%;
}

.registerFull {
  height: 80px;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .regFormContiner {
    margin-top: 35px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 700px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .authBorderRegPink {
    background: #e9117c;
    width: 50%;
    margin-left: 50%;
    height: 6px;
    border-radius: 5px;
  }

  .registerMobileContainer {
    background-color: #fff;
    width: 85%;
    height: fit-content;
    z-index: 2;
    position: absolute;
    top: 0;
    margin-top: 140px;
    border-radius: 26px;

    overflow: hidden;
  }

  .registerContainer {
    background-color: #fff;
    width: 1152px;
    z-index: 2;
    /* height: 100px; */
    position: absolute;
    top: 0;
    margin-top: 100px;
    height: 1000px;
    border-radius: 26px;

    /* overflow: hidden; */
  }
  .regFormContiner {
    margin-top: 15px;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: -22px;
    margin-bottom: 30px;

    overflow-y: hidden;
  }
  .registerForm > .formContainer > input {
    margin-bottom: 10px;
    width: 103%;
  }
  .registerForm > .formContainer > .regPassword {
    width: 300px;
  }
  .registerForm > .formContainer > .regPassword ~ .eyeicon {
    position: absolute;
    right: 210px;
  }
  .registerErr {
    color: red;
    margin: 20px 0px 0px 0px;
    font-weight: 500;
  }
  .googleloginReg {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .googleIconReg {
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
  .googleIconReg > img {
    height: 100%;
    width: 100%;
  }

  .registerFull {
    height: 10px;
  }
}
