.FilterControlButtons {
  display: none;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .FilterBoxContainer {
    height: fit-content;
    background-color: #ffecce;
  }

  .MobieFilterBoxContentSection {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .FiltersBoxHeaderBox {
    background: #ffffff;
    border: 1px solid #e9117c;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 1.3em;
    line-height: 18px;

    color: #e9117c;
    padding: 30px 0px;
    width: 100%;
    text-align: center;
  }

  .FilterControlButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3%;
    background-color: transparent;

    padding: 2% 0% 10%;
  }

  .dropDownComponentTitle{
    padding-bottom: 2%;
  }

  .FilterControlButtons > .ClearButton {
    background: #ffffff;
    border: 1px solid #e9117c;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    padding: 10px 40px;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 16px;
    text-align: center;

    color: #e9117c;
  }

  .FilterControlButtons > .ApplyButton {
    background: #e9117c;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    padding: 10px 40px;
    border: none;
    outline: none;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 16px;
    text-align: center;

    color: #ffffff;
  }

  
}
