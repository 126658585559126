.InterestPopUp {
  position: fixed;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
  background-color: #fff;
  width: 1064px;
  height: 256px;
  border-radius: 25px;
}

.InterestPopUp-Box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.InterestPopUp-Close {
  text-align: right;
  height: 25px;
  width: 1055px;
  padding: 20px;
  cursor: pointer;
}
.InterestPopUp-Close > i {
  text-align: right;
  color: #e9117c;
}

.InterestPopUp-Context > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #b4b9e4;
  padding-top: 50px;
}

.InterestPopUp-Button-2 > button {
  width: 142px;
  height: 60px;
  color: #ffffff;
  background: #e9117c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .InterestPopUp-Context > p {
    font-size: 20px;
  }
  .InterestPopUp {
    width: 940px;
    height: 260px;
  }
  .InterestPopUp-Button-1 > button {
    width: 220px;
    height: 50px;
    font-size: 16px;
  }
  .InterestPopUp-Button-2 > button {
    width: 220px;
    height: 50px;
    font-size: 16px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {





}
@media (min-width: 319px) and (max-width: 426px){ 
  
  .InterestPopUp {
    width: 350px;
    height: 165px;
    border-radius: 5px;
    margin-right: 30px;
    margin-bottom: 529px;
  }
  .InterestPopUp-Context > p {
    font-size: 14px;
    line-height: 16px;
    padding:0px 10px;
  }
.InterestPopUp-Button-2 > button {

  width: 120px;
  height: 40px;
  font-size: 12px;
  line-height: 14px;
}
}
/********************* Media Query MOBILES*********************/

/* @media screen and (max-width: 425px) {

  .InterestPopUp {
    width: 350px;
    height: 165px;
    border-radius: 5px;
    margin-right: 150px;
    margin-bottom: 529px;
  }
  .InterestPopUp-Context > p {
    font-size: 14px;
    line-height: 16px;
    padding:0px 10px;
  }
.InterestPopUp-Button-2 > button {

  width: 120px;
  height: 40px;
  font-size: 12px;
  line-height: 14px;
}
  
} */
