
.changeEmailHeaders {
    background-color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
  }
  
  .changeEmailHeaders div:last-child {
    border-bottom: none;
  }
  .changeEmailHeader {
    padding: 1.3rem 1.3rem 0.6rem;
    border-bottom: 2px solid rgba(158, 158, 158, 0.24);
    transition: all 0.6s;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .changeEmailHeader div:last-child {
    color: #494949;
  }
  .changeEmailPink {
    color: #e9117c;
  }
  .changeEmailPink div:last-child {
    color: #e9117c;
  }
  