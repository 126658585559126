.auth {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0px;
}

.MobileAuth {
  display: none;
}

.loginErr {
  color: red;
  margin-top: -40px;
  margin-bottom: 20px;
  font-weight: 500;
}

/* login page container */
.loginContainer {
  background-color: #fff;
  width: 1152px;
  z-index: 2;
  height: 100px;
  position: absolute;
  top: 0;
  margin-top: 140px;
  height: 730px;
  border-radius: 26px;

  overflow: hidden;
}
.loginRow {
  padding: 0px;
  display: flex;
  flex-direction: row;
}
.authLoginContentConainer {
  padding: 0px;
}
.authImageContainer {
  background: url("../../../assets/images/Auth/authImg.png");
  background-position: -90px;
  background-size: cover;
  background-repeat: no-repeat;
}

/* login header */
.loginHeader {
  display: flex;
  font-size: 25px;
  color: #e9117c;
  font-weight: 500;
  padding-top: 34px;
  position: relative;
  padding-left: 110px;
}
.loginHeader > div {
  cursor: pointer;
}
.loginPageLink {
  margin-right: 300px;
}

.authBorder {
  width: 100%;
  height: 6px;
  background: rgba(158, 158, 158, 0.62);
  margin-top: 55px;
  margin-left: 0px;
  position: absolute;
  left: -5px;
  border-radius: 5px;
}

.authBorderLoginPink {
  background: #e9117c;
  width: 45%;
  height: 6px;
  border-radius: 5px;
}

/* login form container */
/* .loginForm */

.loginFormContainer {
  margin-top: 97px;
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loginHr {
  width: 70%;
  height: 2px;
  background: rgba(158, 158, 158, 0.62);
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
  position: relative;
}

.loginHr > div {
  background: #fff;
  top: -10px;
  padding: 0px 10px;
  color: grey;
  font-weight: 500;
  position: absolute;
}

.logineyeicon {
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
}
.loginButton {
  margin-top: 24px;
  text-align: center;
  /* margin-left: 50%; */
  /* transform: translateX(-70%); */
}
.loginButton > button {
  height: 50px;
  width: 290px;
  outline: none;
  border: none;
  background-color: #e9117c;
  color: #fff;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
}
.authLink {
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
  color: grey;
  font-size: 17px;
}
.authLink > span {
  color: #e9117c;
  font-weight: 500;
}
.googlelogin {
  margin-top: 20px;
  text-align: center;
}
.googleloginText {
  color: grey;
}
.forgotPassLink {
  color: #e9117c;
  font-weight: 500;
  text-align: right;
  margin-top: -10px;
  margin-bottom: 50px;
  cursor: pointer;
  /* position:absolute;
  right: 0; */

  /* float: right; */
  /* letter-spacing: 2px;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 6s steps(40) infinite; */
}

/* @keyframes typing {
  from{
    width: 0%;
  }
  to{
    width: 20%;
  }
} */

.googleIcon {
  margin-top: 20px;
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.googleIcon > img {
  height: 100%;
  width: 100%;
}
.loginFull {
  height: 120px;
}

/* @media screen and (max-width: 1281px) {
  .landingBlack1 {
    height: 100%;
  }
} */

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  /* .Login{
  width: 500px;
  height: 500px;

} */
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {


  .auth {
    display: none;
  }

  .MobileAuth {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loginMobileContainer {
    background-color: #fff;
    width: 85%;
    height: 720px;
    z-index: 2;
    position: absolute;
    top: 0;
    margin-top: 140px;
    border-radius: 26px;

    overflow: hidden;
  }

  

  .authPageImage {
    height: 100%;
    width: 100%;
  }

  .authImageContainer {
    /* background: url("../../../assets/images/Auth/authImg.png");
    background-position: 90px;
    background-size: cover;
    background-repeat: no-repeat; */

    height: 210px;
    width: 100%;
  }

  .MobileloginRow {
    display: flex;
    flex-direction: column;
  }

  /* login header */
  .loginHeader {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-size: 1.3em;
    color: #e9117c;
    font-weight: 500;
    padding: 15px 0px 0px 40px;
    position: relative;
    margin-left: 3px;

    width: 110%;
  }

  .loginPageLink {
    margin: 0px 125px 0px 23px;
  }

  .authBorder {
    width: 92%;
    height: 6px;
    background: rgba(158, 158, 158, 0.62);
    margin-top: 55px;
    /* margin-left: 20px; */
    position: absolute;
    left: -5px;
    border-radius: 5px;
  }

  .loginFormContainer {
    margin-top: 50px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* width: 50%; */
  }

  .loginErr {
    color: red;
    margin-top: -20px;
    margin-bottom: -5px;
    font-weight: 500;
    font-size: 0.8em;
    letter-spacing: -0.2px;
  }

  .loginButton {
    margin-top: 15px;
    text-align: center;
    height: 50px;
    width: 290px;
    /* margin-left: 50%; */
    /* transform: translateX(-70%); */
  }
  .loginButton > button {
    height: 42px;
    width: 169px;
    outline: none;
    border: none;
    background-color: #e9117c;
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .forgotPassLink {
    color: #e9117c;
    font-weight: 500;
    text-align: right;
    margin-top: -2px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 1em;
  }

  .authLink {
    margin-top: 4px;
    text-align: center;
    cursor: pointer;
    color: grey;
    font-size: 14px;
  }

  .authLink > span {
    color: #e9117c;
    font-weight: 500;
  }

  .loginHr {
  width: 55%;
  height: 2px;
  background: rgba(158, 158, 158, 0.62);
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 15px;
  position: relative;
}

.loginHr > div {
  background: #fff;
  top: -13px;
  padding: 0px 10px;
  color: grey;
  font-weight: 500;
  font-size: 1em;
  position: absolute;
}

.googlelogin {
  margin-top: 20px;
  text-align: center;
}
.googleloginText {
  color: grey;
  font-size: .95em;
}

.googleIcon {
  margin-top: 20px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.googleIcon > img {
  height: 100%;
  width: 100%;
}
.loginFull {
  height: 120px;
}

.authBorderLoginPink {
  background: #e9117c;
  width: 50%;
  height: 6px;
  border-radius: 5px;
}

}
