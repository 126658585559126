.homeHeaderProfileContainer {
  position: absolute;
  width: 25rem;
  top: 4rem;
  right: 3rem;
  background-color: #fff;
  border: 1px solid #e9117c;
  border-radius: 25px;
  padding: 1.5rem;
  z-index: 3;
  box-shadow: 0px 4px 10px rgba(247, 38, 101, 0.3);
}
.homeHeaderProfileName {
  text-align: center;
  font-size: 25px;
}
.homeHeaderprofileAccountDetails {
  margin: 20px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(73, 73, 73, 0.23);
}
.homeHeaderprofileAccountDetails > div > span{
    color:#e9117c;
    font-weight: 500;
}
.homeHeaderProfileOption {
  display: flex;
  gap: 20px;
  margin-top: 12px;
  cursor: pointer;
}
.homeHeaderProfileOptionImage > img {
  height: 20px;
  width: 20px;
}
.homeHeaderProfileOption > a{
  text-decoration: none;
  color: #111;
}
.homeHeaderProfileOption > a:hover{
  text-decoration: none;
  color: #e9117c  ;
}

