.FAQAccordian {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding-bottom: 5%;
}

.AccordianTab {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: rgba(99, 95, 95, 0.71);
  padding: 1.5% 2.5%;
  border-radius: 20px;
  background: #ffffff;
  border: 2px solid rgba(67, 66, 66, 0.1);
  width: 60%;
  height: fit-content;
  
}

.AccordianButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.plusButton {
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: rgba(105, 101, 101, 0.71);
}

.ChangeTabColor {
  color: #e9117c;
}

.FAQTABPara {
  padding: 30px 10px 0px 10px;
  margin: 0px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 22px;
  color: rgba(67, 66, 66, 0.71);

}


/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {

  .FAQAccordian {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding-bottom: 10%;
}

  .tearmsAndConditionsHeaderRow > h4{
    font-size: 1.2em;
    font-weight: 600;
  }

  .AccordianTab {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: rgba(99, 95, 95, 0.71);
  padding: 1.5% 2.5%;
  border-radius: 0px;
  background: #ffffff;
  border: 1px solid rgba(67, 66, 66, 0.1);
  width: 100%;
  height: fit-content;
}

.ChangeTabColor {
  color: #e9117c;
  background-color:  #FFE8F7;;
}

.AccordianButton > span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  font-size: .8em;
  padding-left: 12px;
}

}