/* PersonalDetails_FewWords_about_{her} */
.PersonalDetails_FewWords {
  width: 81.1%;
}

.PersonalDetails_FewWords > div > h6 {
  color: #e9117c;
  margin: 5% 0% 0% 0%;
  font-size: 1.8em;
}

.PersonalDetails_FewWords > .card {
  background-color: #ffffff;
  height: 5%;
  margin: 3% 0% 2% 0%;
  /* position: relative; */
  align-items: center;
  border-radius: 30px;
  border-left: #e9117c 8px solid;
  border-right: #e9117c 8px solid;
}

.PersonalDetails_FewWords > .EditTime_PersonalDetails_card{
     background-color: #ffffff;
  height: 340px;
  margin: 3% 0% 2% 0%;
  padding: 2px 80px;
  position: relative;
  align-items: center;
  border-radius: 30px;
  border-left: #e9117c 8px solid;
  border-right: #e9117c 8px solid;
  }

.PersonalDetails_FewWords_PTag {
  width: 95%;
  font-weight: 400;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(73, 73, 73, 0.9);
  padding: 4% 4% 1% 0%;
}

.FedwWordsInputBox {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 21px;
  letter-spacing: 0.5em;

  color: rgba(73, 73, 73, 0.6);
}

.PersonalDetails_FewWords_EditTime > .partnerFieldAns {
  width: 1380px;
  height: 150px;
  margin: 6% 0% 8% 0%;
}
.partnerFieldAns > .FedwWordsInputBox {
  height: 100px;
}

.partnerFieldAns > span > .k-input-inner {
  width: 100%;
  height: 100%;
  border: 2px solid #e9117c;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.09em;
  line-height: 21px;
  letter-spacing: 0.04em;

  padding: 20px;
  color: rgba(73, 73, 73, 0.6);
}

.PersonalDetails_FewWords
  > .PersonalDetails_card
  > .Details_section_update_buttons {
  margin-bottom: 35px;
}

.PersonalDetails_FewWords
  > .EditTime_PersonalDetails_card
  > .Details_section_update_buttons {
  margin-bottom: 30px;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .PersonalDetails_FewWords_PTag {
    font-size: 14px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .PersonalDetails_FewWords {
    width: 90.1%;

    position: relative;
  }

  .PersonalDetails_FewWords > .fewWordBoxHeader {
    margin: 8% 0% 8% 0%;

    display: flex;
    align-items: center;
  }

  .DesktopViewEditButton {
    display: none;
  }

  .mobileViewEditButton {
    display: inline;
  }

  .EditPage_EditButton {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .PersonalDetails_FewWords > div > h6 {
    color: #e9117c;
    margin: 0%;
    font-size: 1.3em;
    font-weight: 500;
  }

  .PersonalDetails_FewWords > div > .mobileViewEditButton {
    color: #e9117c;
    margin: 7.5% 0px;
    font-size: 1em;
    font-weight: 500;
  }

  .PersonalDetails_FewWords > .card {
    background-color: #ffffff;
    height: 8%;
    margin: 3% 0% 2% 0%;
    /* position: relative; */
    align-items: center;
    border-radius: 30px;
    border-left: #e9117c 4px solid;
    border-right: #e9117c 4px solid;
  }

  .PersonalDetails_FewWords > .EditTime_PersonalDetails_card{
    background-color: #ffffff;
    /* height: 8%; */
    margin: 3% 0% 2% 0%;
    /* position: relative; */
    align-items: center;
    border-radius: 30px;
    border-left: #e9117c 4px solid;
    border-right: #e9117c 4px solid;
    height: 200px;
    padding: 10px 25px 0px;
  }

  .FedwWordsInputBox {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 21px;
    letter-spacing: 0.5em;

    color: rgba(73, 73, 73, 0.6);
  }

  .PersonalDetails_FewWords_PTag > p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 0.7em;
    line-height: 21px;
    color: #494949;
    text-align: justify;
  }

  /* Edit Time */
  .PersonalDetails_FewWords_EditTime > .partnerFieldAns {
    width: 320px;
    height: 100px;
    margin: 6% 0% 8% 0%;
  }

  .partnerFieldAns > span > .k-input-inner {
  width: 100%;
  height: 100%;
  border: 2px solid #e9117c;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: .8em;
  line-height: 20px;
  letter-spacing: 0em;

  padding: 5px 10px;
  color: rgba(73, 73, 73, 0.6);
}

.PersonalDetails_FewWords
  > .PersonalDetails_card
  > .Details_section_update_buttons {
  margin-bottom: -30px;
}

.PersonalDetails_FewWords
  > .EditTime_PersonalDetails_card
  > .Details_section_update_buttons {
  margin-bottom: 20px;
}
}
