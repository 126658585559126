.landingPageContainer {
  padding: 0px;
  overflow-x: hidden;
}
/* hero section */
.landingHero {
  display: inline;

  height: 626px;
  background: url("../../assets/images/Landing/landinghero.png");
  /* background-size: 100vw 626px; */
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: justify;
  padding: 0px;
}

.mobileLandingHero {
  display: none;
}

.black1 {
  height: 100%;
  background: rgba(63, 54, 54, 0.6);
  z-index: 0;
  padding: 0px;
}

.landingBlack1 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(145, 125, 125, 0.5);
  /* height: 82.7%; */
  height: 100%;
  margin-top: 75px;
  z-index: 1;
}

.landingHeroWelc {
  text-align: center;
  padding-top: 120px;
  font-size: 78px;
  height: 100%;
  z-index: 3;
  font-family: "Great Vibes", cursive;
}
.landingHeroWelcText {
  margin-top: 70px;
}
.landingHeroButton {
  width: 292px;
  height: 90px;
  background-color: #e9117c;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 25px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* landing registration */
.landReg {
  background: rgba(233, 17, 124, 0.4);
  height: 166px;
  color: #111;
  padding-left: 104px;
  padding-top: 30px;
  padding: 30px;
  font-weight: 500;
  position: relative;
}
.landingRegHeading {
  margin: 0px -5px;
}

.landDropDown {
  border: none;
  border-right: 10px solid #fff;
  outline: none;
  height: 48px;
  width: 245px;
  border-radius: 7px;
  display: block;
  margin-top: 12px;
  padding-left: 10px;
}
.landingRegInp {
  border: none;
  outline: none;
  height: 48px;
  width: 245px;
  border-radius: 7px;
  display: block;
  margin-top: 12px;
  padding-left: 10px;
}
.landingRegInp::placeholder {
  padding-left: 2px;
}
.landingRegDropIcon {
  position: absolute;
  top: 80px;
  left: 310px;
  color: rgb(186, 183, 183);
}
.landingRegButton {
  width: 206px;
  height: 48px;
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  border-radius: 5px;
  margin-top: 40px;
}
.landRegInpErr {
  color: red;
  text-shadow: 1px 0px 1px rgba(128, 128, 31, 0.1);
}

.landReg-Row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 10px;
}

/* landing about */
.landingAbout {
  padding-left: 104px;
}

.landingPageParaContent {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1%;
}

.landingAbout > .heading {
  margin: 40px 0px 49px;
  color: #e9117c;
  font-size: 35px;
}

.landingAboutBold {
  font-size: 1.8em;
  margin-bottom: 50px;
  line-height: 45px;
}

.ring > img {
  height: 25px;
  width: 25px;
}
.aboutImg {
  height: 367px;
  width: 389px;
  margin-right: 50px;
  margin-bottom: 80px;
}
.aboutImg > img {
  height: 100%;
  width: 100%;
}

.withRingParas {
  padding-left: 0%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  text-align: left;
}

.ring {
  margin-right: 10px;
}
.aboutPoints {
  display: flex;
  flex-direction: row;
  /* margin-bottom: 20px; */
}
.aboutP {
  display: inline;
}

/* story */
.landingStory {
  height: 168px;
  border-radius: 34px;
  background: rgba(63, 54, 54, 0.72);
  color: #fff;
  margin-bottom: 100px;
}
.landingStoryImg {
  height: 220px;
  width: 220px;
  margin-left: 140px;
  margin-top: -20px;
}
.landingStoryImg > img {
  height: 100%;
  width: 100%;
  margin-left: -71px;
}
.landingStoryText {
  text-align: center;
  font-family: "Great Vibes", cursive;
  font-size: 4rem;
  font-weight: 400;
  margin-top: 40px;
  margin-left: -140px;
}

/* success story */
.successStory {
  margin-bottom: 110px;
  position: relative;
}

.successStoryHead {
  text-align: center;
  color: #e9117c;
  font-size: 2.2rem;
  margin-bottom: 80px;
}

.successImg {
  height: 20px;
  width: 20px;
}

.successImg > img {
  height: 270px;
  width: 100px;
  border-radius: 50%;
  border-left: 5px solid #e9117c;
}

.slides {
  display: flex;
  justify-content: center;
  column-gap: 120px;
  text-align: center;
  z-index: 0;
}

.slides > .card {
  border-radius: 14px;
  background-color: #f2f2f2;
  box-shadow: 3px 3px 4px 1px #ccc;
  border: none;
  position: relative;
}

.successImgTitle {
  color: #e9117c;
  margin: 50px 0px 20px;
}

.successContent {
  width: 350px;
  margin: auto;
}

.successImg {
  height: 105px;
  width: 105px;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}

.arrow {
  padding-left: 203px;
  display: flex;
  position: absolute;
  justify-content: space-between;
  margin-top: 3.5rem;
  width: 90vw;
}

.arrow > button {
  font-size: 60px;
  border: none;
  outline: none;
  background: #fff;
  color: #111;
}

.landingTestimonialArrowLeft > .arrowLeft {
  color: rgb(190, 190, 190);
}

.landingTestimonialArrowRight > .arrowRight {
  color: rgb(190, 190, 190);
}

/* on hover not working */
/* .arrow > button:hover{
  transform:scaleX(1.2rem)
} */
.arrowRight {
  position: absolute;
  right: 60px;
}

.successImg > img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1546px) {
  .successContent {
    width: auto;
    margin: auto;
    text-align: center;
  }
}
/* @media screen and (max-width: 1281px) {
  .aboutImg {
    margin-top: 10px;
    height: 330px;
    width: 330px;
  }
  img {
    height: 100%;
    width: 100%;
  }
} */

/*******************  BIG SCREENS ************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** DESKTOPS *****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/*********  LAPTOPS ********************/

@media (min-width: 1025px) and (max-width: 1440px) {
  .aboutImg {
    margin-top: 10px;
    height: 330px;
    width: 330px;
  }
  img {
    height: 100%;
    width: 100%;
  }
  .landingHeroButton {
    width: 270px;
    height: 65px;
  }
  .landingLogin > button {
    font-size: 18px;
    width: 120px;
    height: 40px;
  }
}

/***********  Small LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .landingHeroButton {
    width: 220px;
    height: 60px;
    font-size: 22px;
  }
  .landingLogin > button {
    font-size: 16px;
    width: 115px;
    height: 40px;
  }
  .landDropDown {
    height: 40px;
    width: 180px;
  }
  .landingRegInp {
    height: 40px;
    width: 180px;
  }
  .landingRegButton {
    width: 175px;
    height: 40px;
  }
  .landingHero {
    background-size: cover;
    background-position: center;
  }
  .landingHeroWelc {
    font-size: 72px;
  }

  .arrow {
    padding-left: 90px;
    width: 95vw;
  }
  .landingAboutBold {
    font-size: 25px;
    margin-bottom: 50px;
  }
  .aboutImg {
    height: 250px;
    width: 250px;
    margin-bottom: 50px;
  }
  .landingAbout {
    padding-bottom: 30px;
  }
  .landingStoryImg {
    margin-left: 80px;
    margin-top: -20px;
  }
  .landingStoryText {
    margin-top: -160px;
    margin-left: 65px;
  }
}

/***********  TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
  .landReg {
    padding-left: 20px;
  }
  .landingHeroButton {
    width: 220px;
    height: 60px;
    font-size: 22px;
  }
  .landingLogin {
    font-size: 20px;
    margin: auto;
    margin-right: 70px;
  }
  .landingLogin > button {
    font-size: 15px;
    width: 100px;
    height: 35px;
  }
  .landDropDown {
    height: 40px;
    width: 165px;
  }
  .landingRegInp {
    height: 40px;
    width: 165px;
  }
  .landingRegButton {
    width: 150px;
    height: 40px;
  }
  .landingHero {
    background-size: cover;
    background-position: center;
  }
  .landingHeroWelc {
    font-size: 70px;
  }

  .arrow {
    padding-left: 0px;
    width: 825px;
  }
  .landingAboutBold {
    font-size: 22px;
  }
  .aboutImg {
    height: 180px;
    width: 180px;
  }
  .landingAbout {
    padding-bottom: 30px;
  }
  .landReg-Row {
    column-gap: 5px;
  }

  .landingStory {
    height: 128px;
  }
  .landingStoryImg {
    height: 150px;
    width: 150px;
    margin-left: 50px;
    margin-top: -20px;
  }
  .landingStoryText {
    font-size: 3rem;
    margin-top: -110px;
    margin-left: 60px;
  }
  .successStory {
    margin-top: -60px;
  }
  .successImg {
    height: 75px;
    width: 75px;
  }
  .slides {
    column-gap: 50px;
  }
  .slides > .card {
    width: 280px;
  }
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .landingHero {
    display: none;
  }

  .mobileLandingHero {
    display: inline;

    height: 300px;
    width: 100%;

    background: url("../../assets/images/Landing/landinghero.png");
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: justify;
    padding: 0px;
  }

  .landingHeroWelcText {
    margin-top: 20px;
  }

  .landingHeroWelc {
    text-align: center;
    padding-top: 40px;
    font-size: 43px;
    height: 100%;
    z-index: 3;
    font-family: "Great Vibes", cursive;
  }

  .landingHeroWelc > .landingHeroWelcText + div {
    margin-top: -15px;
  }

  .landingHeroButton {
    width: 169px;
    height: 53px;
    background-color: #e9117c;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    margin-top: 30px;
  }

  .landReg {
    background: rgba(233, 17, 124, 0.4);
    height: fit-content;
    color: #111;
    padding-left: 104px;
    padding-top: 30px;
    padding: 30px;
    font-weight: 500;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .landReg-Row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 15px;
  }

  .landingRegHeading {
    margin-top: 10px;
  }

  .landDropDown {
    border: none;
    border-right: 10px solid #fff;
    outline: none;
    height: 48px;
    width: 280px;
    border-radius: 7px;
    display: block;
    margin-top: 12px;
    padding-left: 10px;
  }

  .landingRegInp {
    border: none;
    outline: none;
    height: 48px;
    width: 280px;
    border-radius: 7px;
    display: block;
    margin-top: 12px;
    padding-left: 10px;
  }

  .landingRegButton {
    width: 280px;
    height: 48px;
    border: none;
    outline: none;
    background-color: #e9117c;
    color: #fff;
    border-radius: 5px;
    margin-top: 20px;
  }

  /* Landing about section */
  .landingAbout {
    padding-left: 0px;
    text-align: center;
  }
  .landingAbout > .heading {
    margin: 0px;
    padding: 40px 0px;
    color: #e9117c;
    font-size: 1.9em;
    font-weight: 500;
    text-align: center;
  }

  .landingPageParaContent {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 9%;
  }

  .landingAboutBold {
    font-size: 1.3em;
    font-weight: 500;
    line-height: 30px;
    width: 85%;
    margin-left: 36px;
    margin-bottom: 50px;

  }

  .aboutImg {
    height: 220px;
    width: 220px;
    margin-right: 0px;
    margin-bottom: 40px;
    border-radius: 1px;
  }

  .withRingParas {
    margin-left: 49px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 75%;
    text-align: left;
  }

  .aboutP {
    font-size: 1.05em;
  }

  /* Story content */
  .landingStoryImg {
    height: 102px;
    width: 193px;
    margin-left: 126px;
    margin-top: -44px;
  }
  .landingStoryImg > img {
    height: 100%;
    width: 100%;
  }
  .landingStoryText {
    text-align: center;
    font-family: "Great Vibes", cursive;
    font-size: 1.9em;
    font-weight: 400;
    margin-top: 32px;
    margin-left: 0px;
  }

  .slide1 {
    display: none;
  }

  /* Testimonial cards */

  .slides {
    display: flex;
    justify-content: center;
    column-gap: 120px;
    text-align: center;
    z-index: 0;
  }

  .slides > .card {
    border-radius: 1px;
    background-color: #f2f2f2;
    box-shadow: 3px 3px 4px 1px #ccc;
    border: none;
    position: relative;
  }

  .successImgTitle {
    color: #e9117c;
    font-size: 1.1em;
    margin: 30px 0px 20px;
  }

  .successContent {
    width: 250px;
    font-size: 1em;
    letter-spacing: 0.2px;
    height: fit-content;
    margin: auto;
  }

  .successImg {
    height: 85px;
    width: 85px;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
  }

  .landingStory {
    height: 168px;
    border-radius: 34px;
    background: rgba(63, 54, 54, 0.72);
    color: #fff;
    margin-bottom: 50px;
  }

  .successStory {
    margin-bottom: 50px;
  }

  /* Arrow symbol */
  /* .successStory > .arrow {
    display: none;
  } */

  .arrow > button {
    
      font-size: 60px;
      border: none;
      outline: none;
      background: #fff;
      color: #111;
      margin-left: -207px;
  }

  .landingTestimonialArrowLeft {
    width: 100%;

    display: flex;
  }

  .landingTestimonialArrowLeft > .arrowLeft {
    color: rgb(190, 190, 190);
    z-index: 1;
    margin-left: -212px;
  }

  .landingTestimonialArrowRight > .arrowRight {
    color: rgb(190, 190, 190);
    z-index: 2;
    margin-right: -113px;
  }

  .arrowRight {
    position: absolute;
    right: 60px;
  }

  .landingBlack1 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(145, 125, 125, 0.5);
    /* height: 82.7%; */
    height: 300%;
    margin-top: 55px;
    z-index: 1;
  }
}
