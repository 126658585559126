.homeHeaderContainer {
  /* margin: 10px 0px; */
  margin-top: 0px;
}
.homeHeader {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 40px;
}
.homeHeaderTitle {
  height: 60.50274658203125px;
  width: 241.12774658203125px;
  display: inline;
}
.homeHeaderTitle > img {
  height: 100%;
  width: 100%;
}

.homeHeaderNav {
  padding-top: 20px;
  /* margin-left: -250px; */
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.homeHeaderNav > div,
.homeHeaderNav > a {
  text-decoration: none;
  color: #494949;
  margin-right: 47px;
  font-size: 15px;
  font-weight: 500;
}
.homeHeaderNav > .active,
.homeHeaderNav > .headerSearchPink,
.homeHeaderNav > .headerMatchPink {
  color: #e9117c;
}
.homeHeaderProfile {
  padding-top: 20px;
  display: flex;
  column-gap: 30px;
}
.headerProfilePic {
  height: 47px;
  width: 47px;

  background: rgba(180, 185, 228, 0.44);
border-radius: 50%;
}
.homeHeaderNotify {
  font-size: 22px;
  cursor: pointer;
  position: relative;
}

.notificationButton{
  display: inline;

}

.homeHeaderNotify i:first-child {
  font-size: 24px;
}
.homeHeaderNotify i:last-child {
  color: #04923c;
  position: absolute;
  font-size: 8px;
  background-color: #fff;
  padding: 1px;
  top: 8px;
  left: 12px;
}

.homeHeaderProfileDisplay {
  display: flex;
  margin: -10px 0px 10px;
  column-gap: 20px;
  align-items: center;
  flex-direction: row;
}

.homeHeaderProfileDisplay div:first-child {
  font-weight: 500;
}
.homeHeaderProfileDisplay div:last-child {
  cursor: pointer;
}
.headerProfilePic > img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.homeHeaderBorder {
  background-color: #e9117c;
  height: 3px;
  width: 100%;
}

.SidebarProfileDetails > .welcomeTag,
.SidebarProfileDetails > .profileID{
  display: none;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .homeHeaderTitle > img {
    height: 55px;
    width: 130px;
    margin-right: 20px;
    margin-top: 10px;
}
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .homeHeader {
    background-color: #fff;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 0px 40px;
  }

  .homeHeaderNav {
    padding-top: 20px;
    /* margin-left: -250px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .homeHeaderTitle {
 
  display: none;
}

.notificationButton {
  
  display: none;
}

.AngleDownArrowButton{
  display: none;
}

.homeHeaderBorder {
  display: none;
}

.homeHeaderProfileDisplay {
  display: flex;
  margin: -10px 0px 10px;
  column-gap: 20px;
  align-items: left;
  flex-direction: row-reverse;
      justify-content: flex-end;
  background: url("../../../assets/images/Sidebar/profileCardBG.png");
  height: 200px;
  width: 350px;
}

.SidebarProfileDetails{
  display: flex;
  flex-direction: column;
}

.SidebarProfileDetails > .welcomeTag,
.SidebarProfileDetails > .profileID{
  display: inline;
}
}
