.homeSearches {
  line-height: 1.6rem;
}
.homeSearch {
  margin: 20px;
  cursor: pointer;
}

.homeSearchContainer {
  position: absolute;
  width: 22rem;
  top: 3.8rem;
  right: 44rem;
  background-color: #fff;
  border: 1px solid #e9117c;
  border-radius: 25px;
  padding: 1.5rem;
  z-index: 3;
  box-shadow: 0px 4px 10px rgba(247, 38, 101, 0.3);
}



/******************* Media Query BIG SCREENS************************/


@media (min-width: 1921px) and (max-width: 2560px) {



}

/**************** Media Query DESKTOPS*****************************/


@media (min-width: 1441px) and (max-width: 1920px) {



}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
  .homeSearchContainer {
   
    right: 28.5rem;
  }



}



/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {

  .homeSearchContainer {

    right: 15rem;
  }

}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {





}

/********************* Media Query MOBILES*********************/

@media screen and (max-width: 245px) {



}