.HomePageContainer {
  padding: 0px;
  position: relative;
}
.HomeHeaderDivision {
  display: inline;
}

.MobileSidebarDivision {
  display: none;
}

.homeContainer {
  margin-top: 75px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 50px;
}

/* home profile box container */
.homeProfileDetails {
  font-weight: 500;
  border-radius: 25px;
  width: 22%;
}
.homeProfileContainer {
  background-image: linear-gradient(#e9117c, rgba(255, 214, 152, 0.5));
  padding: 20px;
  border-radius: 25px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #fff;
}
.homeProfile {
  background: rgba(217, 217, 217, 0.5);
  padding: 16px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homeProfileDetailsImage {
  height: 114px;
  width: 114px;
  margin-bottom: 20px;
  border-radius: 20px;
  overflow: hidden;

  background: rgba(180, 185, 228, 0.44);

  display: flex;
  justify-content: center;
  align-items: center;
}
.homeProfileDetailsImage > img {
  height: 100%;
  width: 100%;
  object-fit: inherit;
  object-position: center;
}

.homeProfileDetailsImage > .placeHolderImg {
  height: 80%;
  width: 80%;
  object-fit: inherit;
  object-position: center;
}

.homeProfileDetailsName {
  font-weight: 500;
  font-size: 19px;
}
.homeProfileCompletion {
  font-family: "Helvetica";
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: space-around;
  margin-top: 30px;
  font-style: italic;
  font-weight: 700;
  font-size: 15px;
}

.homeProfileCompletionStatus {
  height: 7px;
  width: 150px;
  background-color: #fff;
  border-radius: 7px;
  margin: 20px;
  margin-top: 9px;
}
.homeProfileCompletionStatusPink {
  height: 7px;
  width: 45px;
  background-color: #e9117c;
  border-radius: 7px;
}
.homeProfileCompletionButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeProfileCompletionButton > button {
  border: none;
  outline: none;
  color: #fff;
  background: transparent;
}
.homeProfileCompletionButton > button > span {
  margin-left: 7px;
}
.homeProfileSubscibe {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px;
  font-size: 18px;
}
.homeProfileSubscibe > button {
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  width: 280px;
  height: 45px;
  border-radius: 11px;
  margin-top: 20px;
}
.homeProfileOptions {
  border: 1px solid rgba(158, 158, 158, 0.67);
  border-radius: 25px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: none;
  font-weight: 400;
  color: rgba(67, 66, 66, 0.71);
  padding-bottom: 24px;
  padding-left: 24px;
}
.homeProfileOptions > a {
  padding-top: 24px;
  font-size: 20px;
  display: block;
  color: #111;
  text-decoration: none;
}
.homeProfileOptions > a:hover {
  padding-top: 24px;
  font-size: 20px;
  display: block;
  color: #e9117c;
  text-decoration: none;
}
/* .homeProfileOptions > div {
  padding-top: 24px;
  font-size: 20px;
} */

.homeProfileOptions > a > span > img {
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

/* profile completion box */
.profileCompletionContainer {
  background: url("../../assets/images/Home/homeNotificationBackground.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px;
  border-radius: 27px;
  height: 261px;
  width: 870px;
}
.profileCompletionContainerHeader {
  color: #e9117c;
}
.profileCompletionContainerSlide {
  display: flex;
  position: relative;
}
.profileCompletionContainerSlideContentContainer {
  /* height: 90px; */
  display: flex;
  padding: 20px;
  gap: 100px;
  background-color: #fff;
  border-radius: 20px;
  margin: 20px 16px;
  transition: all 0.6s;
}
.profileCompletionContainerSlideContent > div {
  transition: all 0.6s;
}
.profileCompletionContainerSlideContent div:first-child {
  margin-top: 10px;
}
.profileCompletionContainerSlideButton > button {
  border: none;
  background-color: #e9117c;
  color: #ffff;
  padding: 7px;
  border-radius: 15px;
  width: 150px;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.8);
}
.homeSlideArrowLeft {
  border: none;
  font-size: 25px;
  background: transparent;
  /* transition: all 0.6s; */

  /* position: absolute;
  left: -20px;
  top:40px; */
}
.homeSlideArrowRight {
  border: none;
  font-size: 25px;
  background: transparent;
  /* transition: all 0.6s; */

  /* position: absolute;
  right: 30px;
  top:40px */
}
.homeArrowGrey {
  color: rgba(73, 73, 73, 0.15);
}

.homeMatchesContainer {
  width: 900px;
}

.homeProfileCard {
  border: none;
  border-radius: 25px;
  width: 14rem;
}

.homeCardProfileImage{
  height: 200px;
  width:224px;
  overflow: hidden;

}

.homeProfileImg {
  /* object-fit: center; */
  object-position: center;

}
.homeProfileBody {
  background-color: #e9117c;
  color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  text-align: center;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.homeFooter {
  margin-top: 100px;
}

.HomeLogoutPopupOn {
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(63, 54, 54, 0.8);
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 100;
  height: 100vh;
  margin-top: 70px;
  z-index: 100;
  overflow-y: hidden;
}

.HomeLogoutPopupOn > .logoutPopupContainer {
  width: 70%;
  height: fit-content;
  background-color: #ffffff;
  border: none;
  border-radius: 20px;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;

  position: relative;
  padding: 2.5% 0px;
}

.logoutPopupContainer > .LogoutHeader {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 1.9em;
  line-height: 15px;

  color: #e9117c;
}

.logoutPopupContainer > .LogoutSubHeader {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 23px;
  /* identical to box height */

  padding: 5px 0px 20px 0px;
  color: #b4b9e4;
}

.LogoutPopupButtons {
  display: flex;
  gap: 50px;
}

.LogoutPopupButtons > .cancelButton {
  box-sizing: border-box;

  border: 1px solid #9e9e9e;
  border-radius: 10px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 23px;
  /* identical to box height */

  background-color: transparent;
  padding: 5px 100px;
  color: #e9117c;
}

.LogoutPopupButtons > .cancelButton:hover {
  background-color: #fdfafb;
  color: #e9117c;
}

.LogoutPopupButtons > .logoutButton {
  box-sizing: border-box;

  border: 1px solid #e9117c;
  outline: none;
  background: #e9117c;
  border-radius: 10px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 23px;
  padding: 5px 100px;
  background-color: #e9117c;

  color: #ffffff;
}

.LogoutPopupButtons > .logoutButton:hover {
  background-color: #bb1065;
  color: #ffffff;
}

.MobileViewProfileVerify {
  display: none;
}

.MobileViewHrTag {
  display: none;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
  .homeProfileCompletion {
    width: 215px;
  }
  .HomeLogoutPopupOn {
    display: flex;
    justify-content: center;
    align-items: center;
  
    background: rgba(63, 54, 54, 0.8);
    position: absolute;
    top: 0%;
    left: 0;
    right: 0;
    bottom: 100;
    height: 100vh;
    margin-top: 0px;
    z-index: 200;
    overflow-y: hidden;
  }
  .homeProfileSubscibe > button{
    border: none;
    outline: none;
    background-color: #e9117c;
    color: #fff;
    width: 240px;
    height: 45px;
    border-radius: 11px;
    margin-top: 20px;
  }
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .homeContainer {
    gap: 85px;
    width: 98%;
  }
  .homeProfileContainer {
    padding: 5px;
    height: 470px;
    width: 300px;
  }
  .homeProfileDetails {
    font-weight: 500;
    border-radius: 25px;
    width: 40%;
}
  .homeProfileDetailsName {
    font-size: 16px;
  }
  .homeProfileCompletion {
    font-size: 13px;
  }
  .homeProfileCompletionButton > button {
    font-size: 16px;
  }
  .homeProfileOptions {
    width: 300px;
  }
  .homeProfileOptions > a {
    font-size: 18px;
  }
  .homeProfileSubscibe {
    margin: 30px;
    font-size: 18px;
  }
  .homeProfileSubscibe > button {
    width: 200px;
    height: 45px;
    margin-top: 20px;
    font-size: 17px;
  }
  .profileCompletionContainerSlideContentContainer {
    align-items: center;
    height: 135px;
    width: 600px;
    margin: 10px 0px 20px 0px;
    gap: 0px;
  }

  .profileCompletionContainer {
    width: 600px;
  }
  .homeMatchesContainer {
    width: 600px;
  }
  .homeMatchesContainerBox {
    width: 600px;
    padding: 20px;
  }
  .homeProfileCard {
    width: 10rem;
  }
  .homeProfileImg {
    height: 150px;
    width: 150px;
  }
  .homeProfileBody {
    width: 150px;
  }
  .HomeAdvBox {
    height: 135px;
    width: 600px;
    padding: 30px;
  }
  .homeAdvContentContainer {
    column-gap: 30px;
  }
  .homeAdvContentContainer > h5 {
    display: flex;
    font-size: 22px;
    justify-content: center;
    align-items: center;
  }
  .homeAdvChat {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    height: 100px;
  }
  .homeAdvContent > h3 {
    font-size: 22px;
  }
  .homeAdvContent > div {
    font-size: 14px;
  }
  .discoverMatchesImage {
    height: 225px;
    width: 190px;
  }
  .discoverMatchesCardImages img:nth-of-type(2) {
    height: 40px;
    width: 40px;
  }

  .discoverMatchesSeeAll {
    margin-top: 45px;
    margin-left: 55px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
  .homeContainer {
    column-gap: 85px;
    width: 98%;
  }
  .homeProfileContainer {
    padding: 5px;
    height: 470px;
    width: 300px;
  }
  .homeProfileDetailsName {
    font-size: 16px;
  }
  .homeProfileCompletion {
    font-size: 13px;
  }
  .homeProfileCompletionButton > button {
    font-size: 16px;
  }
  .homeProfileOptions {
    width: 300px;
  }
  .homeProfileOptions > a {
    font-size: 18px;
  }
  .homeProfileSubscibe {
    margin: 30px;
    font-size: 18px;
  }
  .homeProfileSubscibe > button {
    width: 200px;
    height: 45px;
    margin-top: 20px;
    font-size: 17px;
  }
  .profileCompletionContainerSlideContentContainer {
    align-items: center;
    height: 135px;
    width: 600px;
    margin: 10px 0px 20px 0px;
    gap: 0px;
  }

  .profileCompletionContainer {
    width: 600px;
  }
  .homeMatchesContainer {
    width: 600px;
  }
  .homeMatchesContainerBox {
    width: 600px;
    padding: 20px;
  }
  .homeProfileCard {
    width: 10rem;
  }
  .homeProfileImg {
    height: 150px;
    width: 150px;
  }
  .homeProfileBody {
    width: 150px;
  }
  .HomeAdvBox {
    height: 135px;
    width: 400px;
    padding: 30px;
  }
  .homeAdvContentContainer {
    column-gap: 30px;
  }
  .homeAdvContentContainer > h5 {
    display: flex;
    font-size: 22px;
    justify-content: center;
    align-items: center;
  }
  .homeAdvChat {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    height: 100px;
  }
  .homeAdvContent > h3 {
    font-size: 22px;
  }
  .homeAdvContent > div {
    font-size: 14px;
  }
  .discoverMatchesImage {
    height: 225px;
    width: 190px;
  }
  .discoverMatchesCardImages img:nth-of-type(2) {
    height: 40px;
    width: 40px;
  }

  .discoverMatchesSeeAll {
    margin-top: 45px;
    margin-left: 55px;
  }
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .MobileHomePageContainer {
    width: 100%;
    height: 100vh;

    background: rgba(235, 235, 235, 0.8);
    backdrop-filter: blur(2.5px);

    border-radius: 0px 20px 20px 0px;

    z-index: 1;
  }

  .container-fluid {
    padding: 0px;
  }

  .HomeHeaderDivision {
    display: none;
  }

  .MobileSidebarDivision {
    display: inline;
    width: 100%;
    height: 100%;

    /* position: relative; */
  }

  #sidebarMenu {
    position: absolute;
    top: 0px;
    left: 0px;

    background: rgba(235, 235, 235, 0.7);
    backdrop-filter: blur(2.5px);

    border-radius: 0px 40px 0px 0px;
    width: 75%;

    transition: 2s;
    height: 100vh;
    overflow: hidden;

    z-index: 2;
  }

  #hideSidebar {
    position: absolute;
    top: 0px;
    left: 0px;

    background: rgba(235, 235, 235, 0.7);
    backdrop-filter: blur(2.5px);

    border-radius: 0px 40px 0px 0px;
    width: 0%;
    /* transition: 2s; */
    height: 100vh;
    overflow: hidden;

    z-index: 2;
  }

  .sidebarIcon {
    position: absolute;
    width: 35px;
    height: 35px;
    left: 25px;
    top: 30px;

    background: rgba(73, 73, 73, 0.42);
    border-radius: 7px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .MobileNotificationIcon {
    background: rgba(73, 73, 73, 0.42);
    border-radius: 7px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 40px;
    height: 35px;
    top: 30px;
    right: 25px;
  }

  .sidebarIcon > img {
    width: 65%;
    height: 50%;
  }

  .MobileNotificationIcon > img {
    width: 60%;
    height: 75%;
    float: right;
  }

  .MobileNotificationIcon > .MobileNotificationPoint {
    width: 20%;
    height: 20%;

    position: absolute;
    top: 10px;
    right: 10px;
  }

  .homeContainer {
    margin: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    column-gap: 50px;
  }

  /* home profile box container */
  .homeProfileDetails {
    font-weight: 500;
    border-radius: 25px;
    width: 100%;
    padding: 0px;
  }

  .homeProfileContainer {
    background: linear-gradient(
      102.23deg,
      #e9117c 1.68%,
      rgba(255, 214, 152, 0.5) 118.95%
    );
    padding: 20px;
    border-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    color: #fff;
    width: 100%;
    height: 280px;
  }

  .homeProfile {
    background: transparent;
    padding: 16px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .homeProfileSubscibe {
    display: none;
  }

  .homeProfileOptions {
    display: none;
  }

  .homeProfileDetailsImage {
    height: 94px;
    width: 94px;
    margin-top: 4%;
    margin-bottom: 0px;
    border-radius: 10px;
    overflow: hidden;
  }

  .homeProfileDetailsImage > img {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }

  .homeProfileDetailsName {
    font-weight: 500;
    font-size: 1em;
    padding: 5px;
  }

  .ProfileCompletedLable {
    display: none;
  }

  .homeProfileCompletion {
    font-family: "Helvetica";
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: space-around;
    margin-top: 10px;
    font-style: italic;
    font-weight: 700;
    font-size: 15px;
  }

  .homeProfileCompletionStatus {
    height: 7px;
    width: 150px;
    background-color: #fff;
    border-radius: 7px;
    margin: 10px;
    margin-top: 9px;
  }

  .MobileViewProfileVerify {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;

    margin-top: -25px;
  }

  .MobileViewProfileVerify > span {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 13px;

    color: #494949;
  }

  .MobileViewProfileVerify > button {
    background: #e9117c;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 11px;

    border: none;
    outline: none;
    padding: 12px 30px;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 13px;
    letter-spacing: 0.5px;

    color: #ffffff;
  }

  .MobileViewHrTag {
    display: inline;
    border: 2px solid rgba(73, 73, 73, 0.3);
    width: 80%;

    margin-top: -10px;
  }

  .homeMatchesContainer {
    width: 100%;
    padding: 0;
  }

  /* profile completion box */
  .profileCompletionContainer {
    background: url("../../assets/images/Home/homeNotificationBackground.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
    border-radius: 27px;
    height: fit-content;
    width: 100%;

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin-top: -20px;
  }
  .profileCompletionContainerHeader {
    color: #e9117c;
    padding-top: 10px;
    text-align: center;
  }

  .profileCompletionContainerHeader > h4 {
    font-weight: 700;
    font-size: 1.4em;
  }

  .profileCompletionContainerHeader > .profileCompletionContainerHeader2 {
    font-weight: 600;
    font-size: 1em;
    margin-top: -5px;
  }
  .profileCompletionContainerSlide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;
  }
  .profileCompletionContainerSlideContentContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    padding: 20px 10px;
    text-align: center;

    background-color: #fff;
    border-radius: 20px;
    margin: 20px 16px;
    transition: all 0.6s;
  }
  .profileCompletionContainerSlideContent > div {
    transition: all 0.6s;
    font-weight: 500;
  }
  .profileCompletionContainerSlideContent div:first-child {
    margin-top: 10px;
  }
  .profileCompletionContainerSlideButton > button {
    border: none;
    background-color: #e9117c;
    color: #ffff;
    padding: 7px;
    border-radius: 15px;
    width: 150px;
    box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.8);
  }
  .homeSlideArrowLeft {
    border: none;
    font-size: 25px;
    background: transparent;
    /* transition: all 0.6s; */

    /* position: absolute;
  left: -20px;
  top:40px; */
  }
  .homeSlideArrowRight {
    border: none;
    font-size: 25px;
    background: transparent;
    /* transition: all 0.6s; */

    /* position: absolute;
  right: 30px;
  top:40px */
  }
  .homeArrowGrey {
    color: rgba(73, 73, 73, 0.15);
  }

  /* Home match boxs */
  .homeMatchesContainerBox {
    width: 100%;
    padding: 40px;
    padding-bottom: 0px;
  }

  .homeProfileCard {
    border: none;
    border-radius: 25px;
    width: 10rem;
  }
  .homeProfileImg {
    height: 140px;
  }

  .homeProfileBody {
    background-color: #e9117c;
    color: #fff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    text-align: center;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-size: 1em;
  }

  .homeProfiletext {
    margin-bottom: -5px;
  }


  /* Logout popup */
  .HomeLogoutPopupOn {
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(63, 54, 54, 0.8);
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 100;
  height: 100vh;
  margin-top: 0px;
  z-index: 1;
  overflow-y: hidden;
}

.HomeLogoutPopupOn > .logoutPopupContainer {
  width: 80%;
  height: fit-content;
  background-color: #ffffff;
  border: none;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: relative;
  padding: 15% 0px 8%;
}

.logoutPopupContainer > .LogoutHeader {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 15px;

  color: #e9117c;
}

.logoutPopupContainer > .LogoutSubHeader {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 23px;
  /* identical to box height */

  padding: 5px 0px 20px 0px;
  color: #b4b9e4;
}

.LogoutPopupButtons {
  display: flex;
  gap: 50px;
}

.LogoutPopupButtons > .cancelButton {
  box-sizing: border-box;

  border: 1px solid #9e9e9e;
  border-radius: 5px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 23px;
  /* identical to box height */

  background-color: transparent;
  padding: 5px 30px;
  color: #e9117c;
}


.LogoutPopupButtons > .logoutButton {
  box-sizing: border-box;

  border: 1px solid #e9117c;
  outline: none;
  background: #e9117c;
  border-radius: 5px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 23px;
  padding: 5px 30px;
  background-color: #e9117c;

  color: #ffffff;
}


}
