/* PersonalDetails_BasicDetails */
.PersonalDetails_BasicDetails,
.EditTimePersonalDetails_card {
  width: 81.1%;
}

.PersonalDetails_BasicDetails > div > h6,
.EditTimePersonalDetails_card > div > h6 {
  color: #e9117c;
  margin: 3% 0% 0% 0%;
  font-size: 1.8em;
}

.PersonalDetails_BasicDetails > .card,
.EditTimePersonalDetails_card > .card {
  background-color: #ffffff;
  height: 5%;
  margin: 3% 0% 2% 0%;
  position: relative;
  align-items: center;
  border-radius: 30px;
  border-left: #e9117c 8px solid;
  border-right: #e9117c 8px solid;
}

.PersonalDetails_BasicDetails > .card > p,
.EditTimePersonalDetails_card > .card > p {
  width: 95%;
  font-weight: 400;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(73, 73, 73, 0.9);
  padding: 4% 4% 1% 0%;
}
/* Details Section */
.DetailsSectionRow {
  display: flex;
  width: 100%;
  padding: 3% 0.5% 3% 0.5%;
}

.DetailsSectionRow_EditTime {
  display: flex;
  width: 100%;
  padding: 4% 0.5% 5% 0.5%;
}

.Details_section {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 50x;
  font-weight: 500;
  font-size: 20px;
  transform: scale(1, 0.98);
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(73, 73, 73, 0.95);
  width: 100%;
  position: relative;
}

.Details_section_label {
  width: 230px;
}

.Details_section_label_EditTime > li {
  padding: 7px 0px 0.2px 0px;
}
.Details_section_values {
  margin-top: 0.15rem;
}

.Details_section_values > li {
  width: 350px;
  display: flex;
  flex-direction: row;
}

.Details_section > ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.Details_section_colon {
  padding: 0px 20px;
  margin-left: 20px;
}

.Details_section_colon_EditTime > li {
  padding: 7px 0px 0.2px 0px;
}

.NeedToSelectValuePink {
  color: #e9117c;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;

  cursor: pointer;
}

.NeedToSelectValuePink > .fa-play {
  font-size: 0.7em;
}

.PersonalDetailsMediumOption {
  width: 183px;
  height: 39px;
  background: #ffffff;
  border-radius: 10px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 14px;
  color: #b4b9e4;
  border: none;
  border-right: 10px solid white;
  outline: 1px solid #b4b9e4;
}

.PersonalDetailsTextBox {
  box-sizing: border-box;
  width: 183px;
  height: 39px;
  background: #ffffff;
  border: 1px solid #b4b9e4;
  border-radius: 10px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  padding-left: 11px;
  outline: none;
  color: rgba(73, 73, 73, 0.9);
}
.PersonalDetailsTextBoxHuge {
  width: 24rem;
}

.Details_section_values_rightLabel {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;

  color: rgba(73, 73, 73, 0.9);
  padding-left: 10px;
}

.Details_section_update_buttons {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.Details_section_update_buttons > .save {
  width: 88px;
  height: 37px;
  background: #e9117c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 16px;
  color: #ffffff;
  border: none;
  outline: none;
}

.Details_section_update_buttons > .cancel {
  box-sizing: border-box;
  width: 88px;
  height: 37px;
  background-color: #ffffff;
  border: 1px solid #e9117c;
  border-radius: 12px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #e9117c;
}

.Details_section_update_buttons {
  margin-bottom: -50px;

  position: absolute;
  bottom: 0px;
  right: 80px;
}

.Details_section_label_EditTime > .EatingEditTime,
.Details_section_colon_EditTime > .EatingEditTime {
  margin-top: -3px;
}

.Details_section_label_EditTime > .SmokingEditTime,
.Details_section_colon_EditTime > .SmokingEditTime {
  margin-top: -8px;
}

.Details_section_label_EditTime > .DrinkingEditTime,
.Details_section_colon_EditTime > .DrinkingEditTime {
  margin-top: -6px;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .Details_section > ul {
    font-size: 11px;
  }

  .Details_section_update_buttons > .save {
    font-size: 14px;
  }
  .Details_section_update_buttons {
    padding: 15px;
  }
  .PersonalDetailsTextBox {
    font-size: 12px;
    height: 25px;
    width: 155px;
  }
  .Details_section_label_EditTime > li {
    padding: 10px 0px 0.2px 0px;
  }
  .Details_section_colon_EditTime > li {
    padding: 10px 0px 0.2px 0px;
  }

  .Details_section_label {
    margin-top: 15px;
  }
  .PersonalDetailsMediumOption {
    height: 30px;
    font-size: 9px;
  }
  .Details_section_values_rightLabel {
    font-size: 12px;
  }
  .Details_section_update_buttons > .save {
    font-size: 12px;
    height: 32px;
  }

  .Details_section_update_buttons > .cancel {
    font-size: 12px;
    height: 32px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .PersonalDetails_BasicDetails,
  .EditTimePersonalDetails_card {
    width: 90.1%;

    position: relative;
  }

  .PersonalDetails_BasicDetails > .fewWordBoxHeader,
  .EditTimePersonalDetails_card > .fewWordBoxHeader {
    margin: 8% 0% 8% 0%;

    display: flex;
    align-items: center;
  }

  .DesktopViewEditButton {
    display: none;
  }

  .mobileViewEditButton {
    display: inline;
  }

  .EditPage_EditButton {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .PersonalDetails_BasicDetails > div > h6,
  .EditTimePersonalDetails_card > div > h6 {
    color: #e9117c;
    margin: 0%;
    font-size: 1.3em;
    font-weight: 500;
  }

  .PersonalDetails_BasicDetails > div > .mobileViewEditButton,
  .EditTimePersonalDetails_card > div > .mobileViewEditButton {
    color: #e9117c;
    margin: 7.5% 0px;
    font-size: 1em;
    font-weight: 500;
  }

  .PersonalDetails_BasicDetails > .card {
    background-color: #ffffff;
    height: 5%;
    margin: 3% 0% 2% 0%;
    /* position: relative; */
    align-items: center;
    border-radius: 30px;
    border-left: #e9117c 4px solid;
    border-right: #e9117c 4px solid;
  }

  .EditTimePersonalDetails_card > .card {
    background-color: #ffffff;
    height: 910px;
    margin: 3% 0% 2% 0%;
    /* position: relative; */
    align-items: center;
    border-radius: 30px;
    border-left: #e9117c 4px solid;
    border-right: #e9117c 4px solid;
  }

  .FedwWordsInputBox {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 21px;
    letter-spacing: 0.5em;

    color: rgba(73, 73, 73, 0.6);
  }

  .Details_section_label {
    width: fit-content;
    font-size: 0.8em;
    margin-left: 0px;
    margin-top: 2px;
    padding-left: 10px;
  }

  .Details_section_colon {
    padding: 0px 0px;
    margin-left: 0px;
    margin-top: 2px;
    font-size: 0.8em;
    width: 10%;
  }

  .Details_section_colon_EditTime {
    width: 5%;
  }

  .NeedToSelectValuePink {
    color: #e9117c;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 0.8em;

    cursor: pointer;
  }

  .Details_section_values {
    padding-left: 0px;
    margin-top: 3px;
  }

  .Details_section_values > li {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .DetailsSectionRow {
    display: flex;
    width: 100%;
    padding: 5% 0.5% 3% 0.5%;
  }

  .DetailsSectionRow_EditTime {
    display: flex;
    width: 100%;
    padding: 4% 0.5% 5% 2%;
  }
  .Details_section_label_EditTime {
    width: 145px;
  }

  .Details_section_colon_EditTime > li {
    padding: 5px 0px;
  }

  .Details_section_label_EditTime > li {
    padding: 5px 0px;
  }

  .EditPartnerPreferenceRadioFields {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
  }

  .Details_section_label_EditTime > .BodyTypeEditTime,
  .Details_section_colon_EditTime > .BodyTypeEditTime {
    padding-bottom: 92px;
  }

  .Details_section_label_EditTime > .EatingEditTime,
  .Details_section_colon_EditTime > .EatingEditTime {
    padding-bottom: 90px;
  }

  .Details_section_label_EditTime > .SmokingEditTime,
  .Details_section_colon_EditTime > .SmokingEditTime {
    padding-bottom: 61px;
  }

  .PersonalDetailsMediumOption {
    width: 159px;
    height: 34px;
    background: #ffffff;
    border-radius: 10px;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 14px;
    color: #b4b9e4;
    border: none;
    border-right: 10px solid white;
    outline: 1px solid #b4b9e4;
  }

  .PersonalDetailsTextBox {
    box-sizing: border-box;
    width: 159px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #b4b9e4;
    border-radius: 10px;

    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    padding-left: 11px;
    outline: none;
    color: rgba(73, 73, 73, 0.9);
  }

  .Details_section_values_rightLabel {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 0.7em;
    line-height: 18px;
    text-align: center;

    color: rgba(73, 73, 73, 0.9);
    padding-left: 6px;
  }

  .NeedToSelectValuePink {
    color: #e9117c;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 1em;

    cursor: pointer;
  }

  .Details_section_values > li {
    font-size: 0.8em;
  }
}
