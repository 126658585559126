.matchesFilterBoxContainer {
  border: 1px solid rgba(158, 158, 158, 0.24);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  overflow: hidden;
  width: 90%;
  font-family: "Helvetica";
  font-weight: 400;
}

.matchesFilterTitle {
  background-color: #e9117c;
  color: #fff;
  padding: 1.3rem;

  display: block;
}

.filterDropDownHeader > .filterDropDownTitle {
  font-size: 17px;
}

.openfilterDropDownHeader > .filterDropDownTitle {
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.openfilterDropDownHeader {
  display: flex;
  justify-content: space-between;
  transition: all 0.6s;

  padding: 1.3rem 1.3rem 0.6rem;
  border: none;
}

.filterDropDownHeader {
  display: flex;
  justify-content: space-between;
  transition: all 0.6s;
  padding: 1.3rem 1.3rem 0.6rem;
}

.dropDownComponent {
  padding: 0.3rem 1.3rem 0.6rem;
}

.filterDropDown {
  /* padding: 1.3rem 1.3rem 0.6rem; */
  border-bottom: 2px solid rgba(158, 158, 158, 0.24);
  transition: all 0.6s;



}

.filterDropDowns div:last-child {
  border: none;


  animation: growDown .3s ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.filterDropDownPlus {
  cursor: pointer;
}

.dropDownLine {
  display: block;
  margin: 0px 0px 0px;
  height: 1px;
  width: 99%;
  background-color: rgba(158, 158, 158, 0.24);
}

.dropDownComponentTitle {
  margin-bottom: 18px;
  margin-top: 18px;
}

.dropDownOption {
  margin: 20px 0px;
}
.partnerFieldRangeFlex {
  display: flex;
  align-items: center;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .matchesFilterTitle {
    display: none;
  }

  .matchesFilterBoxContainer {
    border: 1px solid rgba(158, 158, 158, 0.24);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    overflow: hidden;
    width: 100%;
    font-family: "Helvetica";
    font-weight: 400;

    background-color: #fff;

    margin-top: 6%;
    margin-bottom: 5%;
  }

  .filterDropDown {
    padding: 0rem 0rem 0rem;
    border-bottom: 2px solid rgba(158, 158, 158, 0.24);
    transition: all 0.6s;
  }

  .ProfileTypeCheckBoxsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    column-gap: 60px;
  }

  .dropDownComponentTitle {
    margin-bottom: 18px;
    margin-top: 18px;
  }

  .openfilterDropDownHeader {
    display: flex;
    justify-content: space-between;
    transition: all 0.6s;
    background-color: #ffecce;
    padding: 1.3rem 1.3rem 0.6rem;
    border: 1px solid rgba(158, 158, 158, 0.24);
  }

  .filterDropDownHeader {
    display: flex;
    justify-content: space-between;
    transition: all 0.6s;
    padding: 1.3rem 1.3rem 0.6rem;
  }

  .dropDownLine {
    display: block;
    display: none;
  }

  .filterDropDownHeader > .filterDropDownTitle {
    font-size: 17px;
  }

  .openfilterDropDownHeader > .filterDropDownTitle {
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 0.4px;
  }
}
