/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .MobileChangeEmailContents > p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 40px;

    color: rgba(73, 73, 73, 0.5);
  }

  .ResendEmilLink > p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 40px;

    color: rgba(73, 73, 73, 0.5);
  }

  .ResendEmilLink > h5 {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1.1em;
    line-height: 18px;
    padding: 0px 40px;
    margin-top: -15px;
    color: #e9117c;
  }
}
