.partnerInputs {
  width: 100%;
}

.partnerInputs > ul {
  list-style-type: none;
}

.basicPrefFields {
  position: relative;
}

.PartnerPreferenceDetailsSectionRow {
  margin-top: -15px;
}

.PartnerDetailsSectionRow_EditTime {
  padding: 20px 0px 0px;
}

.PartnerEditPage_EditButton {
  color: #e9117c;
  background: transparent;
  font-weight: 400;
  font-size: 1em;
  border: none;
  outline: none;

  position: absolute;
  top: 0px;
  right: 0px;
}

.PartnerEditPage_EditButton > i {
  font-size: 0.8em;
  padding-right: 5px;
}

.partnerInputs > .PartnerDetails_section_label {
  font-size: 1.1em;
  letter-spacing: 0.5px;
  width: 30%;
}

.PartnerPreferencetolabel {
  padding: 0px 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
}

.PartnerDetails_section_colon {
  padding-left: 10px;
}

.partnerDetails_section_values {
  margin-top: 0px;
  font-size: 1.1em;

  display: flex;
  flex-direction: column;

  width: 100%;
}

.EditPartnerPreferenceRadioFields {
  display: flex;
  flex-direction: row;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .partnerInputs > .PartnerDetails_section_label {
    font-size: 1em;
    letter-spacing: 0.5px;
    width: 100%;
  }

  .PartnerDetails_section_label {
    padding-left: 0px;
  }

  .PartnerDetails_section_label_EditTime {
    padding-left: 0px;
  }

  .partnerInputs > .PartnerDetails_section_label_EditTime {
    font-size: 1em;
    letter-spacing: 0.5px;
    width: 100%;
  }

  .partnerDetails_section_values {
    padding-left: 20px;
  }

  .partnerDetails_section_values > .NeedToSelectValuePink {
    color: #e9117c;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 0.8em;
    cursor: pointer;
  }

  .PartnerDetails_section_colon {
    padding-left: 0px;
  }

  .PartnerDetails_section_colon_EditTime {
    padding-left: 0px;
  }

  .mobileViewEditButton > .partnerPreferenceMobileEdit {
    font-size: 1em;
    margin-top: 6px;
    margin-right: 20px;
  }

  .PRcloseButton {
    font-size: 0.9em;
  }

  .Details_section_values_rightLabel > div {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 18px;
    text-align: center;
    color: rgba(73, 73, 73, 0.9);
    padding-left: -10px;
  }

  .EditPartnerPreferenceRadioFields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .EditPartnerPreferenceRadioFields > .partnerFieldRadio {
    margin-bottom: -10px;
  }

  .Details_section_values_rightLabel{
    display: none;
  }

  .PartnerDetails_section_label_EditTime > .BodyTypeEditTime,
  .PartnerDetails_section_colon_EditTime > .BodyTypeEditTime{
    padding-top: 2px;
    padding-bottom: 108.5px;
  }

  .PartnerDetails_section_label_EditTime > .MaritalStatusEditTime,
  .PartnerDetails_section_colon_EditTime > .MaritalStatusEditTime{
    padding-bottom: 146.5px;
  }
  
  .PartnerDetails_section_label_EditTime > .PhysicalStatusEditTime,
  .PartnerDetails_section_colon_EditTime > .PhysicalStatusEditTime{
    padding-bottom: 68.5px;
  }

  .partnerDetails_section_values {
  margin-top: 0px;
  font-size: .8em;

  display: flex;
  flex-direction: column;

  width: 100%;
}

.PartnerPreferencetolabel {
  padding: 0px 5px;
  font-weight: bolder;
  letter-spacing: 0.5px;
}
}
