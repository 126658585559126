.Contact-Us {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-family: "Helvetica";
  font-weight: 400;
  gap: 50px;
  padding: 100px;
}
.Contact-Us-Header {
  display: flex;
  flex-direction: column;
  font-size: 27px;
  color: #e9117c;
}
.Contact-Us-Header > span {
  font-size: 21px;
  color: #494949;
}
.Contact-Us-Form form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}
.Contact-Us-Form input {
  width: 420px;
  height: 40px;
  font-size: 14px;
  border: 1px solid #b4b9e4;
  border-radius: 4px;
  outline: none;
  padding-left: 15px;
}
.Contact-Us-Form select {
  width: 420px;
  height: 40px;
  font-size: 14px;
  color: #b4b9e4;
  border-radius: 4px;
  border: none;
  border-right: 10px solid #fff;
  outline: 1px solid #b4b9e4;
}

.Contact-Us-Form button {
  width: 140px;
  height: 40px;
  background: #e9117c;
  color: #fff;
  border-radius: 7px;
  border: none;
}
.Contact-Us-TextArea .k-input-inner {
  width: 419px;
  height: 109px;
  font-size: 14px;
  border: 1px solid #b4b9e4;
  border-radius: 4px;
}

.DesktopContactUsHomeHeader {
  display: inline;
}

.MobileContactUsHomeHeader {
  display: none;
}

.profileDetails_pic_ID {
  display: none;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .DesktopContactUsHomeHeader {
    display: none;
  }

  .MobileContactUsHomeHeader {
    display: inline;
    /* padding: 0px; */
  }

  .MobileContactUsHomeHeader > div > .PageHeading {
    font-size: larger;
    margin-top: 2px;
  }

  .profileDetails_pic_ID {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10%;
  }

  .profileDetails_pic_ID > .profileImg {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;

    object-fit: cover;
  }

  .profileDetails_pic_ID > .profileImg > img {
    width: 100%;
    height: 100%;
  }

  .profileDetails_pic_ID > .profileName {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 0.9em;
    line-height: 16px;
    text-align: center;
    padding: 10px 0px;

    color: #000000;
  }

  .profileDetails_pic_ID > .profileID {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: .75em;
    line-height: 11px;

    color: #e9117c;
  }

  .Contact-Us {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-family: "Helvetica";
  font-weight: 400;

  gap: 50px;
  padding: 30px 50px;
}

.Contact-Us > .Contact-Us-Header{
    font-size: 1.5em;

}

.Contact-Us-Header > span {
  font-size: .65em;
  color: #494949;
  padding-top: 10px;
}

.Contact-Us-Form form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.Contact-Us-Form input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  border: 1px solid #b4b9e4;
  border-radius: 4px;
  outline: none;
  padding-left: 15px;
}

.Contact-Us-Form select {
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: #b4b9e4;
  border-radius: 4px;
  border: none;
  border-right: 10px solid #fff;
  outline: 1px solid #b4b9e4;
}

.Contact-Us-TextArea .k-input-inner {
  width: 319px;
  height: 109px;
  font-size: 14px;
  border: 1px solid #b4b9e4;
  border-radius: 4px;
}

.Contact-Us-Form button {
  width: 140px;
  height: 40px;
  background: #e9117c;
  color: #fff;
  border-radius: 15px;
  border: none;
}
}
