.Notification {
  width: 85vw;
  margin: auto;
  /* text-align: right; */
  padding-top: 50px;
}

.Notification-Card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 80px 50px 50px 0px;
}
.Notification-Time {
  font-family: "Helvetica";
  font-size: 14px;
  text-align: right;
  border-bottom: 1px solid rgba(73, 73, 73, 0.23);
  color: #ff9900;
}
.Notification-Card > div > img {
  height: 105px;
  width: 105px;
  border-radius: 50%;
}
.Notification-context {
  font-family: "Helvetica";
  font-weight: 400;
}

.Notification-context div:first-child {
  font-size: 25px;
  color: rgba(73, 73, 73, 0.9);
}

.Notification-View {
  position: absolute;
  color: #e9117c;
  border-bottom: 2px solid;
  font-size: 21px;
  cursor: pointer;
}

.Notification-Buttons {
  display: flex;
  gap: 20px;
  padding-top: 30px;
}

.Notification-Buttons > button:first-child {
  width: 181px;
  height: 60px;
  background-color: #e9117c;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  border-bottom: none;
}

.Notification-Buttons > button:last-child {
  width: 181px;
  height: 60px;
  background-color: #ffffff;
  border: 3px solid #e9117c;
  border-radius: 10px;
}

.MobileHomeHeader {
  display: none;
}

.mobile_MarkAllReadLink {
  display: none;
}

.NTProfileName {
  font-weight: 600;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
  .Notification-Buttons > button:first-child {
    width: 150px;
    height: 50px;
  }
  .Notification-Buttons > button:last-child {
    width: 150px;
    height: 50px;
  }
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .Notification-Card {
    padding: 80px 50px 20px 0px;
  }

  .Notification-context div:first-child {
    font-size: 20px;
  }

  .Notification-View {
    font-size: 18px;
  }

  .Notification-Card > div > img {
    height: 90px;
    width: 90px;
  }
  .Notification-Buttons > button:first-child {
    width: 120px;
    height: 40px;
  }
  .Notification-Buttons > button:last-child {
    width: 120px;
    height: 40px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .DesktopHomeHeader {
    display: none;
  }

  .MobileHomeHeader {
    display: inline;
  }

  .Notification {
    width: 94vw;
    margin: auto;
    /* text-align: right; */
    padding-top: 0px;
    padding-bottom: 30px;
  }

  .Notification-Card {
    padding: 18px 50px 13px 0px;
  }

  .mobile_MarkAllReadLink {
    display: flex;
    justify-content: flex-end;

    border-bottom: 1px solid rgba(73, 73, 73, 0.23);
    padding: 20px 0px;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9em;
    line-height: 14px;
    text-align: right;

    color: #f25a81;
  }

  .Notification-Card > div > img {
    height: 65px;
    width: 65px;
    border-radius: 50%;
  }

  .Notification-context {
    font-family: "Helvetica";
    font-weight: 400;
  }

  .Notification-context div:first-child {
    font-size: 1.1em;
    color: rgba(73, 73, 73, 0.9);
  }

  .Notification-View {
    position: absolute;
    color: #e9117c;
    border-bottom: 2px solid;
    font-size: 1em;
    cursor: pointer;
  }

  .Notification-Buttons {
    display: flex;
    gap: 20px;
    padding-top: 20px;
  }
  .Notification-Buttons > button:first-child {
    width: 120px;
    height: 35px;
    background-color: #e9117c;
    color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    border: none;
    border-bottom: none;
  }

  .Notification-Buttons > button:last-child {
    width: 120px;
    height: 35px;
    background-color: #ffffff;
    border: 3px solid #e9117c;
    border-radius: 15px;
  }

  .Notification-Time {
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .NTBoxViewAllLink {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .NTBoxViewAllLink > div {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 14px;

    color: #59cafc;

    padding: 0px 0px 40px;
  }

  .notificationBoxFooterRow {
    display: none;
  }

  .hideNTBoxViewAllLink{
    display: none;
  }
}
