/* PersonalDetails_AadharVerificationDetails */
.PersonalDetails_AadharVerificationDetails {
  width: 81.1%;
}

.PersonalDetails_AadharVerificationDetails > div > h6 {
  color: #e9117c;
  margin: 3% 0% 0% 0%;
  font-size: 1.8em;
}

.PersonalDetails_AadharVerificationDetails > .card {
  background-color: #ffffff;
  height: 5%;
  margin: 3% 0% 2% 0%;
  position: relative;
  align-items: center;
  border-radius: 30px;
  border-left: #e9117c 8px solid;
  border-right: #e9117c 8px solid;
}

.PersonalDetails_AadharVerificationDetails > .card > p {
  width: 95%;
  font-weight: 400;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(73, 73, 73, 0.9);
  padding: 4% 4% 1% 0%;
}

.aadharverifyPinkLink {
  margin-left: 15%;
  cursor: pointer;
  text-decoration: none;
}


  .DesktopAadharVarification {
    display: inline !important;
  }

.MobileAadharVarification {
  display: none !important;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .MobileAadharVarification {
    display: inline !important;
  }

  .DesktopAadharVarification {
    display: none !important;
  }

  .PersonalDetails_AadharVerificationDetails {
    width: 90.1%;

    position: relative;
  }

  .PersonalDetails_AadharVerificationDetails > .fewWordBoxHeader {
    margin: 8% 0% 8% 0%;

    display: flex;
    align-items: center;
  }

  .DesktopViewEditButton {
    display: none;
  }

  .mobileViewEditButton {
    display: inline;
  }

  .PersonalDetails_AadharVerificationDetails > div > h6 {
    color: #e9117c;
    margin: 0%;
    font-size: 1.3em;
    font-weight: 500;
  }

  .PersonalDetails_AadharVerificationDetails > .card {
    background-color: #ffffff;
    height: 5%;
    margin: 3% 0% 2% 0%;
    /* position: relative; */
    align-items: center;
    border-radius: 30px;
    border-left: #e9117c 4px solid;
    border-right: #e9117c 4px solid;
  }

  .Details_section_values > li > .notAadhaarVerifiedLabel {
    font-size: 1em;
  }
}
