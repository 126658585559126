.searchBoxTapBar {
  width: 100%;
  height: 73px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  letter-spacing: 1px;
  line-height: 21px;

  color: #ffffff;

  background: #e9117c;

  display: flex;
  justify-content: center;
  align-items: center;
}

.searchBoxTapBar > .leftArrowIcon {
  position: absolute;
  top: 23px;
  left: 18px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 100;
  font-size: 1.5em;
  line-height: 21px;

  color: #ffffff;

  z-index: 1;
}

.SearchBoxContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  padding: 50px 0px;
}

.SearchOptionsLabel {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3em;
  line-height: 18px;

  color: #e9117c;
}

.SearchBoxButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 35px;

  width: 100%;
  height: 100%;
  margin-top: 4%;
}

.SearchBoxButtons > button {
  background: #ffe8f7;
  border-radius: 20px;

  border: none;
  outline: none;
  padding: 17px 30px;
  width: 90%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-weight: 300;
}

.SearchBoxButtons > button > label {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.05em;
  line-height: 16px;

  color: #494949;
}

/* MatchesBox */

.MBprofilePhoto {
  width: 100px;
  height: 100px;

  border-radius: 50%;
  overflow: hidden;
}

.MBprofilePhoto > img {
  width: 100%;
  height: 100%;
}

.MatchesBoxCandidateProfile > .candidateName {
  padding-top: 10px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 16px;
  text-align: center;
  margin-bottom: 5px;

  color: #000000;
}

.MatchesBoxCandidateProfile > .candidateId {
  /* padding-top: 10px; */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 11px;
  text-align: center;
  padding-top: 5px;

  color: #e9117c;
}

.SearchBoxContents > .MatchesBoxLabel {
  align-self: flex-start;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 18px;
  color: #e9117c;

  margin-bottom: -35px;
  padding-left: 50px;
}

.MatchesBoxContents > .SearchBoxButtons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}



/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
 
}
