.SearchesHeaderContainer {
    font-family: "Helvetica";
    font-weight: 400;
    display: flex;
    column-gap: 20px;
    /* align-items: center; */
    /* justify-content: center; */
    text-align: center;
    /* width: 90%; */
    margin-top: 5rem;
}
.searchesHeader {
  border: 1px solid rgba(158, 158, 158, 0.24);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  overflow: hidden;
  padding: 0.6rem;
  width: 17%;
  size: 21px;
  font-size: 19.5px;
  cursor: pointer;
}
.searchesHeaderPink {
  background-color: #e9117c;
  color: #fff;
}
.SearchesHeaderContainerLine {
  height: 0.5px;
  width: 95%;
  margin: 30px 0px;
  background-color: rgba(158, 158, 158, 0.24);
  margin: 0px;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .SearchesHeaderContainer {
    padding: 0px 20px;
    width: 140%;
  }
  .searchesHeader {
    font-size: 16px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {


  
}

/********************* Media Query MOBILES*********************/

@media screen and (max-width: 245px) {



}
