.mobileContainer {
  text-align: center;
  position: relative;
}

.mobileHeader {
  margin: 5px 0px 30px;
  color: #e9117c;
  font-weight: 500;
  font-size: 20px;
}

.mobileFull {
  height: 310px;
}

.mobileVerifiedTHeader {
  margin: 100px 0px 30px;
  color: #e9117c;
  font-weight: 500;
  font-size: 28px;
}

.mobileVerifiedText {
  color: #b4b9e4;
  font-size: 21px;
  margin-bottom: 10%;
}

.mobileVerifiedIcon {
  color: #e9117c;
  font-size: 20px;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.registeredheader{
  margin: 6% 0% 0% 0%;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.registeredheader > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 29px;

  color: #b4b9e4;
}

.registeredheader > p > span{
  color: #E9117C;
  font-weight: 500;
}

.MobileverifyHrTag{
  width: 70%;
  margin-top: 3%;
}


/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
    .registeredheader{
  margin: 22% 0% 5% 0%;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.registeredheader > p {
  padding: 5% 5% 0%;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 29px;

  color: #b4b9e4;
}

.MobileverifyHrTag{
  width: 65%;
  margin-top: 3%;
  border: none;
  height: 2px;
  background-color: rgb(128, 124, 124);
}

.mobileHeader {
  margin: 25px 0px 30px;
  color: #e9117c;
  font-weight: 500;
  font-size: 1.2em;
}

.mobileVerifiedText {
  color: #b4b9e4;
  font-size: 1.1em;
  margin-bottom: 10%;
}

.mobileVerifiedIcon {
  color: #e9117c;
  font-size: 1em;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.mobileVerifiedTHeader {
  margin: 80px 0px 30px;
  color: #e9117c;
  font-weight: 500;
  font-size: 1.4em;
}

}