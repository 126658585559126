.HomeAdvBox {
  margin-top: 45px;
  background: url("../../../assets//images/Home/homeNotificationBackground.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px;
  border-radius: 27px;
  height: 151px;
  width: 870px;
  position: relative;
  /* border-bottom-left-radius: url("../../../assets/images/Home/homeRoseLeft.png"); */
}
.homeAdvContentContainer {
  display: flex;
  column-gap: 70px;
}
.homeAdvContentContainer > h5 {
  color: #e9117c;
  display: flex;
  font-size: 26px;
  justify-content: center;
  align-items: center;
  gap: 0px;
}
.homeLeftRose {
  position: absolute;
  top: -20px;
  left: -20px;
}
.homeRightRose {
  position: absolute;
  bottom: -20px;
  right: -20px;
}

.homeAdvChat {
  display: flex;
  gap: 80px;
}
.homeAdvContent {
  padding: 10px;
  padding-left: 40px;
}
.homeAdvContent > h3 {
  font-weight: 400;
}
.homeAdvContent > div {
  font-size: 18px;
}
.homeChatButton {
  display: flex;
  align-items: center;
}

.homeChatButton > button {
  color: #fff;
  background-color: #e9117c;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.8);
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 7px;
  width: 200px;
  font-weight: 500;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .HomeAdvBox {
    margin-top: 45px;
    background: url("../../../assets//images/Home/homeNotificationBackground.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 20px;
    border-radius: 27px;
    height: 201px;
    width: 100%;
    position: relative;
  }

  .homeLeftRose {
    position: absolute;
    top: -20px;
    left: -10px;
  }

  .homeLeftRose > img {
    width: 100px;
  }
  .homeRightRose {
    position: absolute;
    bottom: -20px;
    right: -10px;
  }

  .homeRightRose > img {
    width: 100px;
  }
  .homeAdvContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    margin-top: -20px;
  }

  .homeAdvContentContainer > h5 {
    color: #e9117c;
    display: flex;
    font-size: 1.2em;
    justify-content: center;
    align-items: center;
  }

  .homeAdvChat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;

    text-align: center;
  }

  .homeAdvContent > h3 {
    font-size: 1.3em;
    font-weight: 500;
    margin-top: 20px;
  }

  .homeAdvContent > div {
    font-size: 0.9em;
  }

  .homeChatButton > button {
    color: #fff;
    background-color: #e9117c;
    box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.8);
    outline: none;
    border: none;
    padding: 8px;
    border-radius: 15px;
    width: 180px;
    font-size: 1.1em;
    font-weight: 500;
  }

 
}
