.partnerPreferencePage {
  position: relative;
}
.partnerPreferenceTitleContainer {
  margin: 60px 0px;
  background-image: linear-gradient(90.08deg, #e9117c 0.45%, #ffecd2 128.32%);
  padding: 1rem 8rem;
  color: #fff;
  font-size: 30px;
  display: flex;
  justify-content: center;
}
.partnerPreferenceTitle {
  width: 67vw;
}
.partnerPreference {
  display: flex;
  justify-content: center;
}
.partnerPreferenceContainer {
  margin: 0rem 8rem;
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 25px;
  width: 67vw;
  font-family: "Helvetica";
  color: rgba(73, 73, 73, 0.9);
  box-shadow: 0px 2px 4px rgba(158, 158, 158, 0.75);
}

.partnerFieldHeaderRow {
  /* width: 80%; */
}

.partnerFieldTitle {
  color: #e9117c;
  font-size: 25px;
  margin: 26px 0px 0px;
}

.partnerFieldQue {
  font-size: 18px;
}
.partnerInputs {
  display: flex;
  flex-direction: row;
  line-height: 58px;
  gap: 0px;
}
.partnerFieldRange {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 40px;
}
.partnerPreferenceRadioFields {
  display: flex;
}
.partnerButton > button {
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  width: 212px;
  padding: 10px;
  border-radius: 15px;
  font-size: 25px;
  margin: 40px 0px 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.partnerButton > a {
  text-decoration: none;
  display: block;
  color: #e9117c;
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}
.partnerBackGroundBlack {
  background: rgba(63, 54, 54, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100;
  height: 100%;
  margin-top: 70px;
  z-index: 1;
}
.partnerPreferencePopupContainer {
  background: #fff;
  position: absolute;
  margin: 18rem 30rem;
  z-index: 3;
  border-radius: 25px;
  padding: 50px;
  text-align: center;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
.partnerPreferencePopupTitle {
  color: #e9117c;
  font-size: 25px;
}
.partnerPreferencePopupContents {
  margin: 40px 0px;
  line-height: 1.8rem;
}
.partnerPreferencePopupButons {
  display: flex;
  column-gap: 20px;
  justify-content: center;
}
.partnerPreferencePopupButons > button {
  outline: none;
  height: 60px;
  width: 341px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 18px;
}
.partnerPreferencePopupButons button:first-child {
  color: #e9117c;
  background: #fff;
  border: 1px solid #9e9e9e;
}
.partnerPreferencePopupButons button:last-child {
  background: #e9117c;
  color: #fff;
  border: none;
}
.skipInvisible {
  visibility: hidden;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .partnerPreferenceTitleContainer {
    margin: 60px 0px;
    background-image: #fff;
    padding: 1rem 8rem;
    color: #fff;
    font-size: 30px;
    display: flex;
    justify-content: center;
  }

  .partnerPreferenceTitleContainer {
    display: none;
  }

  .partnerPreferencePage {
    background-color: #fff;
  }

  .partnerPreferenceContainer {
    margin: 2rem 8rem;
    background-color: #fff;
    padding: 0px 20px;
    border-radius: 25px;
    width: 90%;
    font-family: "Helvetica";
    color: rgba(73, 73, 73, 0.9);
    box-shadow: none;

    border-left: none;
    border-right: none;
  }

  .partnerFieldTitle {
    color: #e9117c;
    font-size: 21px;
    margin: 26px 0px 13px;
  }

  .partnerInputs {
    display: flex;
    line-height: 48px;
    gap: 20px;
  }

  .partnerButton {
    margin-top: -40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .partnerButton > button {
    border: none;
    outline: none;
    background-color: #e9117c;
    color: #fff;
    width: 132px;
    padding: 5px;
    border-radius: 15px;
    font-size: 1.2em;
    margin: 40px 0px 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .partnerButton > a {
    text-decoration: none;
    display: block;
    color: #e9117c;
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }

  .partnerFieldRange {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  /* Popup */
  .partnerBackGroundBlack {
    background: rgba(63, 54, 54, 0.8);
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    bottom: 100;
    height: 100%;
    margin-top: 70px;
    z-index: 1;
  }

  .partnerPreferencePopupContainer {
    background: #fff;
    position: absolute;
    margin: 15rem 5rem 5rem 1.5rem;
    z-index: 3;
    border-radius: 10px;
    padding: 40px;
    text-align: center;
    width: 90%;
    height: fit-content;
  }

  .partnerPreferencePopupTitle {
    color: #e9117c;
    font-size: 1.2em;
    font-weight: 600;
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .partnerPreferencePopupContents {
    margin: 40px 0px;
    line-height: 1.6rem;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 0.85em;
    text-align: justify;

    color: #b4b9e4;
  }

  .partnerPreferencePopupContents > .partnerPreferencePopupContent1 {
    padding-bottom: 20px;
  }

  .partnerPreferencePopupButons > button {
    outline: none;
    height: 40px;
    width: 101px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-size: 1.1em;
    font-weight: 500;
    font-family: "Helvetica";
    font-style: normal;
    margin-top: -20px;
  }

  .partnerPreferencePopupButons button:first-child {
    color: #e9117c;
    background: #fff;
    border: 1px solid #9e9e9e;
  }

  .partnerPreferencePopupButons button:last-child {
    background: #e9117c;
    color: #fff;
    border: none;
  }
}
