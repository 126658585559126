.requestPhotoPopup {
  position: fixed;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
  background-color: #fff;
  width: 1064px;
  height: 256px;
  border-radius: 25px;
}
.requestPhotoPopupContent {
  display: flex;
  flex-direction: column;
  z-index: 4;
}
.matchesContainerPageStatic{
    overflow: hidden;
}




@media (min-width: 319px) and (max-width: 426px){
.requestPhotoPopup {
position: fixed;
top: 0%;
left: 0;
right: 0;
bottom: 0;
margin: auto;
z-index: 3;
background-color: #fff;
width: 390px;
height: 183px;
margin-left: 12px;
border-radius: 25px;
}
}