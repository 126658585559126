.MatrimonyIDSearchContainer {
  padding: 2rem 6rem;
  margin-bottom: 8rem;
}
.matrimonialIDSearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1.3rem;
  margin-top: 8rem;
  margin-left: 3rem;
}
.matrimonialIDSearch > div {
  font-size: 20px;
}
.matrimonialIDSearch > input {
  width: 419px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #b4b9e4;
  outline: none;
  padding-left: 15px;
}
.matrimonialIDSearch > button {
  background-color: #e9117c;
  color: #fff;
  border: none;
  border-radius: 7px;
  width: 140px;
  outline: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.KeywordSearchError {
  display: none;
}

.IDSearchError {
  color: red;
  margin-left: 179px;
  margin-top: 14px;
  font-weight: 600;
}
/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .MatrimonyIDSearchContainer {
    padding: 2rem 1rem;
  }
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .matrimonialIDSearch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.3rem;
    margin-top: 8rem;
    margin-left: 0rem;
  }

  .matrimonialIDSearch > button {
    background-color: #e9117c;
    color: #fff;
    border: none;
    border-radius: 7px;
    width: 140px;
    padding: 5px 0px;
    outline: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .KeywordSearch-Input > .matrimonyIdInputBox {
    width: 340px;
  }

  .IDSearchError {
    display: inline;
    color: #e91111;
    font-size: 0.8em;
    font-weight: 600;
  }
}
