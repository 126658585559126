.Profile-Setting-Tab-History {
  width: 382px;
  height: 382px;
}
.History {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffecd2;
  padding: 150px 150px 100px 150px;
  gap: 40px;
}
.History-Profiles {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #494949;
  width: 868px;
  height: 2090px;
  background: #ffffff;
  border-radius: 25px;
  padding: 40px;
}

/* .col-lg-8 {
  flex: none;
  width: 100%;
} */

.HistoryContainerPage {
  position: relative;
}
.HistoryContainerHeaders {
  margin-top: 45px;
  margin-left: 7rem;
  width: 80vw;
}

.HistoryId {
  color: #e9117c;
}
.HistoryContainerHeader1 {
  font-weight: 400;
  font-size: 27px;
}
.HistoryContainerHeaders div:last-child {
  color: #9e9e9e;
  margin-bottom: 45px;
}
.HistoryContainer {
  margin-top: 22px;
  display: flex;
  justify-content: center;
  width: 100%;
  column-gap: 50px;
  font-family: "Helvetica";
}
.HistoryCardContainerBox {
  border-radius: 25px;
  width: 800px;
  margin-bottom: 50px;
  border: 1px solid rgba(158, 158, 158, 0.24);
  border-radius: 15px;

  /* display: flex;
    column-gap: 2.5rem;
    width: 870px;
    margin-bottom: 50px; */
}
.HistoryCardContainer {
  display: flex;
  column-gap: 2.5rem;
  height: 400px;
  width: 789px;
  padding: 20px 30px;
}
.HistoryCardIdContainer {
  display: flex;
  justify-content: flex-end;
  color: #9e9e9e;
  column-gap: 10px;
  font-size: 12px;
  margin: 26px 30px 0px;
}
.HistoryShareIcon {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.HistoryShareIcon > img {
  height: 100%;
  width: 100%;
}
.HistoryCardPicture {
  height: 235px;
  width: 217px;
  border-radius: 15px;
  overflow: hidden;
}
.HistoryCardPicture > img {
  height: 100%;
  width: 100%;
  object-fit: inherit;
  object-position: center;
}
.HistoryCardPictureContainer {
  position: static;
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 200px;
}
.HistoryCardPictureContainer div:last-child {
  cursor: pointer;
}
.HistoryCardPicture ~ div {
  color: #e9117c;
  margin-top: 10px;
  border-bottom: 2px solid #e9117c;
  font-size: 0.9em;
  position: absolute;
  left: 35%;
  bottom: -50px;
}

/* details */

.HistoryCardTick {
  height: 20px;
  width: 20px;
  font-size: 40px;
  color: #04923c;
  z-index: 1;
}
.HistoryCardIsVerified {
  font-size: 12px;
  display: flex;
  column-gap: 4px;
  margin-bottom: 0.5rem;
}
.HistoryCardVerified {
  color: #04923c;
}
.HistoryCardPending {
  color: #ff9900;
}
.HistoryCardYetToVerify {
  color: #ee0909;
}
.HistoryVerifiedIcon {
  height: 14px;
  width: 14px;
}
.HistoryVerifiedIcon > img {
  height: 100%;
  width: 100%;
}
/* .HistoryCardVerified > i {
    margin-right: 10px;
  } */
.HistoryCardName {
  color: #e9117c;
  font-size: 1.4rem;
  font-weight: 500;
}
.HistoryCardLocation {
  font-size: 0.8rem;
}
.HistoryCardDetails {
  margin-top: 14px;
  display: flex;
  gap: 40px;
  line-height: 1.8rem;
  font-size: 15px;
}
.HistoryCardGrey {
  color: rgba(158, 158, 158, 0.8);
}
.HistoryCardBlack {
  color: rgba(67, 66, 66, 0.71);
  font-weight: 500;
}
.HistoryCardHr {
  width: 100%;
  /* color: rgba(158, 158, 158, 0.8); */
}
.HistoryCardButtons > button {
  margin-right: 24px;
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  padding: 9px;
  border-radius: 7px;
  font-size: 15px;
}
.HistoryCardButtons button:first-child > div {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}
.HistoryChatIcon {
  height: 20px;
  width: 20px;
}
.HistoryChatIcon > img {
  height: 100%;
  width: 100%;
}
.HistoryCardButtons > button > i {
  margin-right: 10px;
}

/* share profile container */
.HistoryBlack {
  position: absolute;
  top: -10px;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(145, 125, 125, 0.5);
  height: 2500px;
  margin-top: 75px;
  z-index: 1;
}
.shareProfileContainer {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.shareProfileContainer > div {
  position: absolute;
  background-color: #fff;
  border-radius: 25px;
  width: 80%;
  margin-top: 40%;
  padding: 20px 40px;
}
.HistoryShareClose {
  /* padding: 20px 30px; */
  text-align: right;
  color: #e9117c;
  cursor: pointer;
}
.HistoryShareContainerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.HistoryShareHeader {
  display: flex;
  column-gap: 30px;
}
.HistoryShareBlue {
  height: 30px;
  width: 30px;
}
.HistoryShareBlue > img {
  height: 100%;
  width: 100%;
}
.HistoryShareContent {
  margin-top: 2.2rem;
  line-height: 2rem;
}
.HistoryShareContent > form {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.HistoryCardVerifiedContainer {
  display: inline;
}

.UserVerificationStatus {
  display: none;
}
@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
  .HistoryCardPicture ~ div {
    color: #e9117c;
    margin-top: 10px;
    border-bottom: 2px solid #e9117c;
    font-size: 0.9em;
    position: absolute;
    left: 52%;
    bottom: -247px;
}
 .History {
      display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffecd2;
    padding: 138px 122px 84px 122px;
    gap: 46px;
}
.Profile-Setting-Tab-History {
  width: 112px;
  height: 382px;
}
.History-Profiles {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #494949;
  width: 815px;
  height: 2090px;
  background: #ffffff;
  border-radius: 25px;
  padding: 40px;
}
.HistoryCardContainerBox {
  border-radius: 25px;
  width: 685px;
  margin-bottom: 50px;
  border: 1px solid rgba(158, 158, 158, 0.24);
  border-radius: 15px;
}
.HistoryCardPicture ~ div {
  color: #e9117c;
  margin-top: 10px;
  border-bottom: 2px solid #e9117c;
  font-size: 0.9em;
  position: absolute;
  left: 31%;
  bottom: -50px;
}
}
@media (min-width: 769px) and (max-width: 1024px) {

  .History {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffecd2;
    padding: 137px 122px 74px 31px;
    gap: 15px;
}
.Profile-Setting-Tab-History {
  width: 112px;
  height: 382px;
}
.History-Profiles {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #494949;
  width: 815px;
  height: 2090px;
  background: #ffffff;
  border-radius: 25px;
  padding: 40px;
}
.HistoryCardContainerBox {
  border-radius: 25px;
  width: 685px;
  margin-bottom: 50px;
  border: 1px solid rgba(158, 158, 158, 0.24);
  border-radius: 15px;
}
.HistoryCardPicture ~ div {
  color: #e9117c;
  margin-top: 10px;
  border-bottom: 2px solid #e9117c;
  font-size: 0.9em;
  position: absolute;
  left: 31%;
  bottom: -360px;
}
}
/********************* MOBILES *********************/
@media (min-width: 427px) and (max-width: 768px) {
  .History {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffecd2;
    padding: 137px 122px 74px 31px;
    gap: 15px;
}
.Profile-Setting-Tab-History {
  width: 112px;
  height: 382px;
}
.History-Profiles {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #494949;
  width: 581px;
  height: 2090px;
  background: #ffffff;
  border-radius: 25px;
  padding: 40px;
}
.HistoryCardContainerBox {
  border-radius: 25px;
  width: 503px;
  margin-bottom: 50px;
  border: 1px solid rgba(158, 158, 158, 0.24);
  border-radius: 15px;
}
.HistoryCardPicture > img {
  height: 75%;
  width: 70%;
  object-fit: inherit;
  object-position: center;
}
.HistoryCardDetailsContainer{
  margin-left: -48px;
}
.HistoryCardButtons{
  margin-left: -115px;
}
.HistoryCardHr {
  width: 92%;
}
.HistoryCardPicture ~ div {
  color: #e9117c;
  margin-top: 10px;
  border-bottom: 2px solid #e9117c;
  font-size: 0.9em;
  position: absolute;
  left: 37%;
  bottom: -536px;
}



}

@media (min-width: 319px) and (max-width: 426px) {
  .Profile-Setting-Tab-History {
    display: none;
  }

  .History {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffffff;
    padding: 0px;
    gap: 0px;
  }

  .HistoryContainerHeaders {
    display: none;
  }

  .History-Profiles {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #494949;
    width: 100%;
    height: fit-content;
    background: #ffffff;
    border-radius: 25px;
    padding: 0px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .HistoryPageHeading {
    padding: 40px 0px 20px;
  }

  .HistoryesContainer {
    margin-left: -60px;
    width: 126%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .HistoryCardContainerBox {
    border-radius: 25px;
    width: 93%;
    margin-bottom: 50px;
    border: 1px solid #c5b2b2;
    border-radius: 15px;

    display: flex;
    flex-direction: column-reverse;
  }

  .HistoryCardContainer {
    display: flex;
    column-gap: 1rem;
    height: 286px;
    width: 100%;
    padding: 20px 23px 3px;
  }

  .HistoryCardIdContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9e9e9e;
    column-gap: 10px;
    font-size: 12px;
    margin: 0px 0px 20px;
  }

  .HistoryCardPictureContainer {
    position: relative;
    /* position: static; */
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 200px;
  }

  .HistoryCardPictureContainer > .HistoryViewProfileLink {
    cursor: pointer;
    font-size: .7em;
    margin-left: 8px;
  }

  .HistoryCardPicture ~ div {
    color: #e9117c;
    margin-top: 10px;
    border-bottom: 2px solid #e9117c;
    position: absolute;
    left: 19%;
    bottom: 20px;
  }

  .HistoryCardPicture {
    height: 135px;
    width: 125px;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    margin-top: -6px;
  }
  .HistoryCardPicture > img {
    height: 100%;
    width: 100%;
    object-fit: inherit;
    object-position: center;
  }

  .UserVerificationStatus {
    display: inline;
    font-size: 0.6em;
    border-radius: 12px 12px 0px 0px;
    padding: 4px;

    font-family: "Helvetica";
    font-style: normal;
    line-height: 9px;
    text-align: center;

    color: #ffffff;
    margin-bottom: -5px;
    z-index: 2;

    width: 106%;
  }

  .UserVerificationStatus_verified {
    background: #04923c;
    padding: 4px;
    font-size: 1em;
    letter-spacing: 0.5px;
    border-radius: 12px 12px 0px 0px;
    padding: 5px;
  }

  .UserVerificationStatus_pending {
    background: #ff9900;
    padding: 4px;
    letter-spacing: 0.5px;
    font-size: 1em;
    border-radius: 12px 12px 0px 0px;
    padding: 5px;
  }

  .UserVerificationStatus_yet {
    background: #04923c;
    padding: 4px;
    letter-spacing: 0.5px;
    font-size: 1em;
    border-radius: 12px 12px 0px 0px;
    padding: 5px;
  }

  .HistoryCardVerifiedContainer {
    display: none;
  }
  .HistoryCardName {
    color: #e9117c;
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: -4px;
    margin-bottom: 3px;
  }

  .HistoryCardDetails {
    margin-top: 14px;
    display: flex;
    gap: 10px;
    line-height: 1.8rem;
    font-size: 0.7em;
  }

  .HistoryCardDetailsInfoAns {
    width: 110px;
    display: flex;
    flex-direction: column;
  }

  .HistoryCardGrey {
    color: rgba(158, 158, 158, 0.8);
    margin-bottom: -4px;
  }

  .HistoryCardBlack {
    color: rgba(67, 66, 66, 0.71);
    font-weight: 500;
    margin-bottom: -4px;
  }

  .HistoryCardHr {
    width: 172%;
    margin-left: -150px;
  }

  .HistoryCardButtons {
    margin-left: -140px;
    display: flex;
    justify-content: space-between;
  }

  .HistoryCardButtons > button {
    margin-right: 6px;
    border: none;
    outline: none;
    background-color: #e9117c;
    color: #fff;
    padding: 5px 8px;
    border-radius: 7px;
    font-size: 0.8em;
    width: fit-content;
    height: 30px;
  }
  .HistoryCardButtons button:first-child > div {
    display: flex;
    justify-content: center;
    column-gap: 5px;
  }

  .HistoryChatIcon {
    height: 18px;
    width: 18px;
  }
  .HistoryShareIcon > img {
    height: 100%;
    width: 100%;
    margin-top: -3px;
  }
}
