/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
     .MobileChangeEmailContents > .BackButton {
    background: #e9117c;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    border: none;
    outline: none;
    /* padding: 4px 35px; */
    margin: 10px 40px;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: .9em;
    font-weight: lighter;
    line-height: 16px;
    letter-spacing: .5px;

    color: #ffffff;
    width: 22%;
    height: 30px;
  }

}