.partnerInputs {
  width: 100%;
}

.partnerInputs > ul {
  list-style-type: none;
}

.religiousPrefFields {
  position: relative;
}


.ReligiousPartnerFieldTitle{
color: #e9117c;
    font-size: 25px;
    margin: -20px 0px 0px;
}


.PartnerPreferenceDetailsSectionRow {
  margin-top: -15px;
}

.PartnerDetailsSectionRow_EditTime {
padding: 20px 0px 0px;
}

.PartnerEditPage_EditButton {
  color: #e9117c;
  background: transparent;
  font-weight: 400;
  font-size: 1em;
  border: none;
  outline: none;

  position: absolute;
  top: 0px;
  right: 0px;
}

.PartnerEditPage_EditButton > i {
  font-size: 0.8em;
  padding-right: 5px;
}

.partnerInputs > .Details_section_label {
  font-size: 1.1em;
  letter-spacing: 0.5px;
  width: 30%;
}

.PartnerPreferencetolabel {
  padding: 0px 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
}

.PartnerDetails_section_colon {
  padding-left: 10px;
}

.partnerDetails_section_values {
  margin-top: 0px;
  font-size: 1.1em;

  display: flex;
  flex-direction: column;

  width: 100%;
}

.EditPartnerPreferenceRadioFields {
  display: flex;
  flex-direction: row;
}


/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .ReligiousPartnerFieldTitle{
color: #e9117c;
    font-size: 1.2em;
    font-weight: 500;
    margin: 0px 0px 15px;
}

}