/* PersonalDetails_HoroscopeDetails */
.PersonalDetails_HoroscopeDetails,
.EditTimePersonalDetails_HoroscopeDetails_Card {
  width: 81.1%;
}

.PersonalDetails_HoroscopeDetails > .fewWordBoxHeader > h6,
.EditTimePersonalDetails_HoroscopeDetails_Card > .fewWordBoxHeader > h6 {
  color: #e9117c;
  margin: 3% 0% 0% 0%;
  font-size: 1.8em;
}

.PersonalDetails_HoroscopeDetails > .card,
.EditTimePersonalDetails_HoroscopeDetails_Card > .card {
  background-color: #ffffff;
  height: 5%;
  margin: 3% 0% 2% 0%;
  position: relative;
  align-items: center;
  border-radius: 30px;
  border-left: #e9117c 8px solid;
  border-right: #e9117c 8px solid;
}

.PersonalDetails_HoroscopeDetails > .card > p,
.EditTimePersonalDetails_HoroscopeDetails_Card > .card > p {
  width: 95%;
  font-weight: 400;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(73, 73, 73, 0.9);
  padding: 4% 4% 1% 0%;
}

.Details_section_BlueButton {
  color: white;
  width: 143px;
  height: 36px;
  border: none;
  outline: none;
  background: #3db9f0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  font-size: 0.85em;
  font-style: normal;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}

.HoroscopeCopyFileName {
  min-width: 200px;
  width: fit-content;
  height: 30px;

  background: #ffffff;
  border: 1px solid #e9117c;
  border-radius: 7px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 8px 10px 5px 10px;
  width: 14rem;
  color: rgba(180, 185, 228, 0.6);

  display: flex;
  justify-content: space-between;
}

.HoroscopeCopyFileName > .x {
  color: #e9117c;
  padding-left: 10px;
  cursor: pointer;
}

.EditPrPageDateField {
  width: 200px;
  color: yellow;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .Details_section_BlueButton {
    font-size: 15px;
    padding-top: 7px;
    cursor: pointer;
    text-align: center;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .PersonalDetails_HoroscopeDetails,
  .EditTimePersonalDetails_HoroscopeDetails_Card {
    width: 90.1%;

    position: relative;
  }

  .PersonalDetails_HoroscopeDetails > .fewWordBoxHeader,
  .EditTimePersonalDetails_HoroscopeDetails_Card > .fewWordBoxHeader {
    margin: 3% 0% 8% 0%;

    display: flex;
    align-items: center;
  }

  .DesktopViewEditButton {
    display: none;
  }

  .mobileViewEditButton {
    display: inline;
  }

  .EditPage_EditButton {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .PersonalDetails_HoroscopeDetails > div > h6,
  .EditTimePersonalDetails_HoroscopeDetails_Card > div > h6 {
    color: #e9117c;
    margin: 0%;
    font-size: 1.3em;
    font-weight: 500;
  }

  .PersonalDetails_HoroscopeDetails > div > .mobileViewEditButton,
  .EditTimePersonalDetails_HoroscopeDetails_Card > div > .mobileViewEditButton {
    color: #e9117c;
    margin: 7.5% 0px;
    font-size: 1em;
    font-weight: 500;
  }

  .PersonalDetails_HoroscopeDetails > .card {
    background-color: #ffffff;
    height: 5%;
    margin: 3% 0% 2% 0%;
    /* position: relative; */
    align-items: center;
    border-radius: 30px;
    border-left: #e9117c 4px solid;
    border-right: #e9117c 4px solid;
  }

  .EditTimePersonalDetails_HoroscopeDetails_Card > .card {
    background-color: #ffffff;
    height: 425px;
    margin: 3% 0% 2% 0%;
    /* position: relative; */
    align-items: center;
    border-radius: 30px;
    border-left: #e9117c 4px solid;
    border-right: #e9117c 4px solid;
  }

  .FedwWordsInputBox {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 21px;
    letter-spacing: 0.5em;

    color: rgba(73, 73, 73, 0.6);
  }

  .EditTimePersonalDetails_HoroscopeDetails_Card > .PersonalDetails_card > .DetailsSectionRow_EditTime{
    padding-top: 20px;
  }

  .HoroscopeCopyFileName {
  min-width: 150px;
  width: fit-content;
  height: 30px;

  background: #ffffff;
  border: 1px solid #e9117c;
  border-radius: 7px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 8px 10px 5px 10px;
  width: 12rem;
  color: rgba(180, 185, 228, 0.6);

  display: flex;
  justify-content: space-between;
}

.Details_section_BlueButton {
  color: white;
  width: 133px;
  height: 30px;
  border: none;
  outline: none;
  background: #3db9f0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  margin-top: -3px;
}

.DetailsSectionRow_EditTime > div> ul > .Details_section_BlueButton {
  color: white;
  width: 133px;
  height: 30px;
  border: none;
  outline: none;
  background: #3db9f0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  font-size: .85em;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  padding: 3px;
  cursor: pointer;
  margin-top: 3px;
}

}
