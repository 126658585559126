
/* discover matches card */
.discoverMatches {
    display: flex;
  }
  .discoverMatchesImage {
    height: 260px;
    width: 280px;
    position: relative;
  }
  .discoverMatchesImage > img {
    height: 100%;
  }
  .discoverMatchesCardImages {
    display: flex;
    height: 38px;
    width: 38px;
    margin: 2rem 0.6rem;
  }
  .discoverMatchesCardImages img:nth-of-type(2) {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    margin-top: -10px;
    object-fit: fill;
  }
  .discoverMatchesCardImages > img {
    border-radius: 50%;
    margin: 0px 6px;
    object-fit: contain;
  }
  .discoverMatchesContents {
    position: absolute;
    top: 1rem;
    color: #fff;
    margin-bottom: 1rem;
  }
  .discoverMatchesContent {
    line-height: 1.7rem;
    margin-left: 50px;
    text-align: center;
  }
  .dicoverMatchesTitle {
    font-size: 20px;
    font-weight: 500;
  }
  .discoverMatchesSeeAll {
    margin-top: 73px;
    color: #e9117c;
    font-weight: 700;
    margin-left: 72px;
    cursor: pointer;
  }

  .MobileDiscoverMatches {
    display: none;
  }
  
/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
   .discoverMatches {
    display: none;
  }

  .MobileDiscoverMatches {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    margin-top: 8%;
    margin-bottom: -10%;
  }

  .MobileDiscoverMatchCards{
    display: flex;
    flex-direction: column;
      justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .MobileDiscoverMatchCards > img{    
    width: 80px;
  }

  .MobileDiscoverMatchCardContent > p{
    font-size: 1em;
  }
}