.AddPR_Photos_cards {
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 3%;
}

.Msg_bar {
  background-color: rgba(255, 214, 152, 0.15);
  width: 48%;
  display: inline;
  padding: 5px;
  border-radius: 20px;
  margin-top: 0%;
}

.MsgBar_img {
  width: 85px;
  height: 80px;
  padding: 1.5% 1% 1% 1%;
  margin-left: 1%;
}

.MsgBar_Text {
  display: inline;
  color: #848484;
  font-size: 1.2em;
  font-family: Arial, Helvetica, sans-serif;
}

.WrappedCompnent_card {
  border: #e5dada 1px solid;
  border-radius: 20px;
  width: 48%;
  height: 550px;
}

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
  .WrappedCompnent_card {
    width: 68%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .WrappedCompnent_card {
    width: 68%;
  }
}


/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .Msg_bar {
  background-color: rgba(255, 214, 152, 0.15);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 0px;
  text-align: center;
  margin-top: 5%;
}

.MsgBar_img {
  width: 65px;
  height: 60px;
  padding: 1.5% 1% 1% 1%;
  margin-left: 1%;
}

.MsgBar_Text {
  display: block;
  color: #848484;
  font-size: .95em;
  font-family: Arial, Helvetica, sans-serif;
}

.WrappedCompnent_card {
  border: #e5dada 1.8px solid;
  border-radius: 20px;
  width: 88%;
  height: 482px;
}

}