.partnerFieldRadio {
  margin-right: 40px;
}
.partnerFieldRadio input[type="radio"] {
  color: #e9117c;
  cursor: pointer;
  /* -webkit-appearance: none; */
  width: 13px;
  height:13px;
  border: 1px solid #e9117c;
  border-radius: 50%;
  outline: none;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
.partnerFieldRadio input[type="radio"] {
  color: #e9117c;
  cursor: pointer;
  /* -webkit-appearance: none; */
  width: 12px;
  height:12px;
  border: 1px solid #e9117c;
  border-radius: 50%;
  outline: none;
}

.partnerFieldRadio > label{
  font-size: .8em;
}

.partnerFieldRadio {
  margin-right:0px;
}

}