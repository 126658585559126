

.BlockPopUp{
    /* position: absolute;
    width: 1064px;
    height:283px;
    top: 230px;   
    border-radius: 25px;
    background-color:  #FFFFFF;
    z-index: 5; */
    position: fixed;
    top: 0%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 3;
    background-color: #fff;
    width: 1064px;
    height: 256px;
    border-radius: 25px;
  }
  .BlockPopUpContent {
    display: flex;
    flex-direction: column;
    z-index: 4;
  }
  .BlockPopUp-Box{
   
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  
  
  }
  .BlockPopUp-Close{
  
    text-align: right;
   height: 25px;
   width:  1055px;
   padding: 20px;
   cursor: pointer;
  }
  .BlockPopUp-Close>i{
    text-align: right;
    color: #E9117C;
  
  }
  .BlockPopUp-Header > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: #e9117c;
  }
  
  .BlockPopUp-Context > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #b4b9e4;
  }
  
  .BlockPopUp-Buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 50px;
  }
  
  .BlockPopUp-Button-1 > button {
  width: 245px;
  height: 60px;
  font-family: "Helvetica";
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #e9117c;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #9E9E9E;
  }
  
  .BlockPopUp-Button-2 > button {
    width: 245px;
    height: 60px;
    font-family: "Helvetica";
    font-style: normal;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    background:  #e9117c;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
  }

  
/******************* Media Query BIG SCREENS************************/


@media (min-width: 1921px) and (max-width: 2560px) {



}

/**************** Media Query DESKTOPS*****************************/


@media (min-width: 1441px) and (max-width: 1920px) {


}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {



}



/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {


  .BlockPopUp-Context > p {
    font-size: 20px;
  }
  .BlockPopUp {
    width: 940px;
    height: 260px;
  }
  .BlockPopUp-Button-1 > button {
    width: 220px;
    height: 50px;
    font-size: 16px;
  }
  .BlockPopUp-Button-2 > button {
    width: 220px;
    height: 50px;
    font-size: 16px;
  }

}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {

  .BlockPopUp {
    width: 350px;
    border-radius: 5px;
  }
  .BlockPopUp-Header > p {
    font-size: 14px;
    line-height: 16px;
  }
  .BlockPopUp-Close {
    width: 350px;
    margin-bottom: 25px;
  }
  .BlockPopUp-Context > p {
    font-size: 14px;
    line-height: 16px;
    padding:0px 10px;
  }
  .BlockPopUp-Button-1 > button {
    width: 120px;
    height: 40px;
    font-size: 12px;
    line-height: 14px;
  }
  .BlockPopUp-Button-2 > button {
    width: 120px;
    height: 40px;
    font-size: 12px;
    line-height: 14px;
  }



}

/********************* Media Query MOBILES*********************/

@media screen and (max-width: 425px) {

  .BlockPopUp {
    width: 350px;
    border-radius: 5px;
  }
  .BlockPopUp-Header > p {
    font-size: 14px;
    line-height: 16px;
  }
  .BlockPopUp-Close {
    width: 350px;
    margin-bottom: 25px;
  }
  .BlockPopUp-Context > p {
    font-size: 14px;
    line-height: 16px;
    padding:0px 10px;
  }
  .BlockPopUp-Button-1 > button {
    width: 120px;
    height: 40px;
    font-size: 12px;
    line-height: 14px;
  }
  .BlockPopUp-Button-2 > button {
    width: 120px;
    height: 40px;
    font-size: 12px;
    line-height: 14px;
  }

}