.SBprofileSection {
  background: url("../../../assets/images/Sidebar/profileCardBG.png");
  display: flex;
  flex-direction: row;

  gap: 20px;

  padding: 30px 20px;
}

.SBprofilePhoto {
  width: 100px;
  height: 100px;

  border-radius: 50%;
  overflow: hidden;
}

.SBprofilePhoto > img {
  width: 100%;
  height: 100%;
  border-radius: 0px 20px 0px 0px;

  object-fit: cover;
  object-position: center;
}

.SBprofileDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 7px;
}

.SBprofileDetails > .welomeHead {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 0.9em;
  line-height: 11px;

  color: #ffffff;
}

.SBprofileDetails > .SBname {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 23px;
  padding-bottom: 5px;

  color: #ffffff;
}

.SBprofileDetails > .SBid {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 0.9em;
  line-height: 3px;

  color: #ffffff;
}

.SBLinkContainer{
  padding: 30px 30px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.SBLinksSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;

  width: 100%;
}

.SBLinksSection > .SBLinkIcon {
  color: #e9117c;
  font-size: 1.5em;
  
  width: 12%;
}

.SBLinksSection > .SBLinkName {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  
  margin-top: 7px;

  color: #494949;
}

.SBLinksSection > .SBLinkName:hover {
    color: #e9117c;
}

.SBLinksSection > .SBLinkName:active {
    color: #e9117c;
}