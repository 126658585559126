/* Overall Container */
.PR_container {
  height: 100vh;
  position: relative;
}

/* PR overall Background */
.PR_Background1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100;
  background: url("../../assets/images/ProfileRegister/Profile_register_page_BG_img.png");

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  height: 120%;
  margin-top: 75px;
}

.PR_background {
  /* Flex  */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
}

/*PR Cards */
.PR_card {
  width: 1110px;
  overflow: hidden;
  border: none;
  background-color: transparent;
  margin: 90px 0px 10px 0px;
}

/* NavLinks */

.PR_Page_Tabs_column {
  margin: 20px 0px 0px 0px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 4px;
}

.PR_Page_Tabs {
  background-color: white;
  border-radius: 10px 0px 0px 10px;
  height: 60px;
  width: 60px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  /* Font Styles */
  color: #e9117c;
  font-size: large;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  padding-left: 10px;
}

/* .PR_Page_Tabs:hover {
  background-color: #e9117c;
  color: white;
  cursor: pointer;
} */

/* Active links */
.PR_Page_Tabs_column > .active {
  background-color: #e9117c;
  color: white;
}

/* Form Column */
.PR_card_tab1_form {
  padding: 40px 10px 50px 10px;
  background-color: white;
  border-radius: 20px 0px 0px 20px;

  width: 55%;
}

/* PR_Card_Image */

.Profile_register_page_card_img {
  background-image: url("../../assets/images/ProfileRegister/Profile_register_page_card_img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 20px 20px 0px;

  height: inherit;
  width: 50%;
}

.PRTabs {
  display: flex;
  flex-direction: row;
  
}

/********************* MOBILES - (min-width: 245px) *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .PR_card {
    width: 390px;
    overflow: hidden;
    border: none;
    background-color: transparent;
    margin: 90px 0px 10px 0px;
  }

  .PR_Page_Tabs_column {
    margin: 20px 0px 0px 8px;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    row-gap: 4px;
    gap: 10px;
  }

  .PR_Page_Tabs {
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    height: 44px;
    width: 62.5px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    /* Font Styles */
    color: #e9117c;
    font-size: large;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    padding-left: 0px;
  }

  .PRTabs {
    display: flex;
    flex-direction: column;
    
  }

  .PRTabs :nth-child(1) {
    order: 1;
  }
  .PRTabs :nth-child(2) {
    order: 3;
  }
  .PRTabs :nth-child(3) {
    order: 2;
  }

  .PRTabs > .PR_Page_Tabs_column :nth-child(1) {
    order: 1;
  }
  .PRTabs > .PR_Page_Tabs_column :nth-child(2) {
    order: 2;
  }
  .PRTabs > .PR_Page_Tabs_column :nth-child(3) {
    order: 3;
  }
  .PRTabs > .PR_Page_Tabs_column :nth-child(4) {
    order: 4;
  }
  .PRTabs > .PR_Page_Tabs_column :nth-child(5) {
    order: 5;
  }

  .Profile_register_page_card_img {
    height: 100px;
    width: 100px;
  }

  .Profile_register_page_card_img {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 200px;
  }

  /* Form Column */
  .PR_card_tab1_form {
    padding: 22px 10px 50px 4px;
    background-color: white;
    border-radius: 0px 0px 10px 10px;

    width: 100%;
  }
}
