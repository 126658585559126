/* Input from Headings */
.PR_Card_Headings_Tab1 {
  font-family: Arial, Helvetica, sans-serif;
  font-style: Regular;
  font-size: 2em;
  text-align: Left;
  vertical-align: Top;
  color: #e9117c;
  margin-left: 25px;
  margin-bottom: 1%;
}

/* All_input_Field_error */
.All_input_Field_error {
  color: #ee0909;
  text-align: center;
  width: 100%;
  font-weight: 500;
}

/* Form Input fields */
.PR_Input_fields {
  padding: 10px 10px 10px 10px;
  position: relative;
}

/* Date of Birth Section */
.PR_Card_Tab1_DobBox {
  position: relative;
  padding: 0px;
}

.PR_calender_icon {
  position: absolute;
  top: 35px;
  right: 30px;
  font-size: 1.5em !important;
}

.PR_calender_icon_hide {
  display: none;
}

/* Password Input Section */
.PR_Card_Tab1_passwordBox {
  position: relative;
  padding: 0px;
}

.PR_eye_icon {
  position: absolute;
  top: 21px;
  right: 55px;
  cursor: pointer;
}

.material-symbols-outlined {
  font-size: 1.6em;
  color: rgb(200, 191, 191);
}

/* All input box class */
.PR_Form_Tab1_Input_Fields {
  margin: 20px 0px 15px 25px;
  width: 500px;
  height: 63px;
  font-size: larger;
  font-weight: 450;
  font-family: "Times New Roman", Times, sans-serif;
  border-width: 3px;
  outline: #e9117c;
}

/* Placeholder Section */
.PR_Form_Input_Fields::placeholder {
  font-weight: 400;
  font-size: large;
  color: rgb(200, 191, 191);
  line-height: 5px;
  letter-spacing: 0.2px;
  font-family: Arial, Helvetica, sans-serif;
}
/* Form Submit button Section */
.PR-pink-button {
  background-color: #e9117c;
  color: white;
  font-weight: 700;
  font-size: 23px;
  margin-top: 40px;
  outline: none;
  border: none;
  border-radius: 5px;
  margin-left: 0px;
}

.PR-pink-button:hover {
  background-color: #fa47a1;
}

.PR-pink-button:active {
  background-color: #e9117c;
}

/* Input box focus styles section */
.PR_Input_fields > input[type="text"]:focus,
.PR_Card_Tab1_passwordBox > input[type="password"]:focus,
.PR_Card_Tab1_passwordBox > input[type="text"]:focus,
.PR_Input_fields > input[type="date"]:focus,
.PR_Input_fields > input[type="email"]:focus {
  border-color: #e9117c;
  box-shadow: 0 1px 1px rgba(255, 17, 124, 0.075) inset,
    0 0 8px rgba(126, 239, 104, 0.6);
  box-shadow: none;
  outline: 0 none;
}

/*PR_Page_inputs_validation styles  */
.PR_Page_inputs_validation_Label {
  color: #ee0909;
  margin: -15px 0px 0px 27px;
  font-weight: 500;
  /* width: 53%; */
}

/* PR_FROM_Tabs_selectBox */
.PR_FROM_Tabs_selectBox {
  border: #f4f6f8 3px solid;
  border-radius: 5px;
  color: #111;
  padding-left: 10px;
}
.PR_FROM_Tabs_selectBox > option {
  color: #111;
}
.PR_FORM_Tab1_Select_option_grey {
  color: grey;
}

.PR_FROM_Tabs_selectBox:focus {
  border-color: #e9117c;
  box-shadow: 0 1px 1px rgba(255, 17, 124, 0.075) inset,
    0 0 8px rgba(126, 239, 104, 0.6);
  box-shadow: none;
  outline: 0 none;
}
.PR_FORM_Tab1_Select_option_Grey {
  color: grey;
  border: #f4f6f8 3px solid;
}

.PR_FORM_Tab1_Select_option_Black {
  color: #111;
}

.dropDownMenuBar {
  /* Flex operations */
  display: flex;
  flex-direction: column;
  padding: 0%;

  margin: -10px 0px 0px 25px;
  width: 498px;

  /* Scrolling */
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;

  background-color: #fff;

  border: 0.5px solid rgba(50, 50, 84, 0.25);
  border-top: none;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  /* Position Properties */
  position: absolute;
  top: 16.6%;

  z-index: 1;
}

.dropDownMenuBar::-webkit-scrollbar {
  width: 2px;
  display: none;
}

.dropDownMenuItems {
  font-size: 1em;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  padding: 3px 12px;
}

.dropDownMenuItems:hover {
  background-color: rgb(59, 109, 249);
  color: white;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .PR_Card_Headings_Tab1 {
    font-size: 30px;
  }

  .PR_Input_fields {
    padding: 0px 10px 10px 10px;
    width: 450px;
  }
  .PR_Form_Tab1_Input_Fields {
    width: 430px;
  }
  .PR_card_tab1_form {
    padding: 40px 10px 50px 10px;
    width: 480px;
    height: 650px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* MOBILES - (min-width: 245px) *********************/

@media (min-width: 319px) and (max-width: 426px) {
  /* Input from Headings */
  .PR_Card_Headings_Tab1 {
    font-family: Arial, Helvetica, sans-serif;
    font-style: Regular;
    font-size: 1.44em;
    font-weight: 500;
    text-align: Left;
    vertical-align: Top;
    color: #e9117c;
    margin-left: 22px;
    margin-bottom: 1%;
  }

  .PR_Input_fields {
    padding: 0px 0px 0px 0px;
    position: relative;
  }

  .PR_Form_Tab1_Input_Fields {
    margin: 0px 0px 5px 15px;
    width: 330px;
    height: 45px;
    font-size: 1.1em;
    font-weight: 450;
    font-family: "Times New Roman", Times, sans-serif;
    border-width: 3px;
    outline: #e9117c;
  }

  .dropDownMenuBar {
    /* Flex operations */
    display: flex;
    flex-direction: column;
    padding: 0%;
    margin: -15px 0px 0px 16px;
    width: 327px;

    /* Scrolling */
    max-height: 200px;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #fff;

    border: 0.5px solid rgba(50, 50, 84, 0.25);
    border-top: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    /* Position Properties */
    position: absolute;
    top: 16.6%;
    z-index: 1;
  }

  /*PR_Page_inputs_validation styles  */
.PR_Page_inputs_validation_Label {
  color: #ee0909;
  margin: -30px 0px 10px 19px;
  font-weight: 500;
  font-size: .8em;
}

.dropDownMenuItems {
  font-size: .8em;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  padding: 3px 12px;
}

.PR_eye_icon {
  position: absolute;
  top: 12px;
  right: 25px;
  cursor: pointer;
}

}
