/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .MobileAaharContainer {
    width: 100%;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .aadharUploadStatus {
    margin-top: 10%;

    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 30px;
  }

  .aadharVerifyIMGContainer {
    width: 50%;
  }

  .uploadImgBox {
    position: relative;
    width: 100%;
  }

  .uploadImgBox > .glass {
    width: 100%;
    height: 100%;
    margin-left: -5%;
  }

  .uploadImgBox > .hourglass {
    width: 90%;
    height: 250px;
    margin-left: 0%;
  }

  .uploadImgBox > .CheckMark {
    width: 100%;
    height: 200px;
    margin-left: 0%;
  }

  .uploadImgBox > .xmark {
    width: 100%;
    height: 200px;
    margin-left: 0%;
  }

  .uploadImgBox > .formatCard {
    width: 59px;
    position: absolute;
    top: 46px;
    left: 92px;
  }

  .aadharVerifyIMGContainer > .uploadImgBox > .waterShadow {
    width: 100%;
    height: 15px;
    margin-top: 10%;
  }

  .adharVerifyTopPara {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4em;
    line-height: 21px;
    text-align: center;
    color: #e9117c;
    margin-top: 17%;
  }

  .adharVerifyMiddlePara {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 22px;
    margin-top: 20px;

    color: #e9117c;
  }

  .adharVerifyBottomPara {
    margin-top: 10%;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 21px;
    text-align: justify;

    color: rgba(73, 73, 73, 0.55);
  }

  .AadhaarHightLight {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 1em;

    color: #e9117c;
  }

  .aadharUploadStatus > .StatusLabel {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 16px;

    color: rgba(73, 73, 73, 0.9);

    padding: 10px 50px;
    margin-top: 13%;
    border: 2px solid #b4b9e4;
    border-radius: 25px;
  }
}
