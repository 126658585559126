/* Add profile Photos cards */
.AddProfilePhoto_card {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.AddProfilePhoto_card_Heading {
  color: #b4b9e4;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.05em;
  padding: 0% 0px 3% 0px;
}

.AddProfilePhoto_Placeholder {
  background-image: url("../../assets/images/ProfileRegister/EmptyProfilepic.png");
  background-position: center;
  background-size: 110%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  height: 168px;
  width: 179px;
  border-radius: 5px;
  padding: 0px;
  outline: rgba(246, 242, 242, 0.5) 10px solid;
  margin-bottom: 5%;
  overflow: hidden;
  color: #fff;
  padding: 1rem;
  padding-top: 1rem;
}

.AddProfilePhoto_AfterOnePicUpload {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.AddProfilePhoto_AfterOnePicUpload_img {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.AddProfilePhoto_Placeholder_AfterOnePicUpload {
  background-image: url("../../assets/images/ProfileRegister/EmptyProfilepic.png");
  background-position: center;
  background-size: 110%;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;

  height: 168px;
  width: 179px;

  border-radius: 5px;
  padding: 0px;
  outline: rgba(246, 242, 242, 0.5) 10px solid;
  margin-bottom: 5%;
  overflow: hidden;
  color: #fff;
  padding: 1rem;
  padding-top: 1rem;
}

.AddProfilePhoto_Placeholder > img {
  object-position: center;
  object-fit: fill;
  width: 105%;
  height: 105%;
  margin: -2px;
  border: none;
  outline: none;
}

/* AddProfilePhoto_with_deletebuttonDiv */
.AddProfilePhoto_with_deletebuttonDiv {
  position: relative;
}

.AddProfilePhoto_AfterOnePicUpload_DeleteButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

/*  AddProfilePhoto_card_BrowseDiv */
.AddProfilePhoto_card_BrowseDiv {
  border: 1.5px dotted #b4b9e4;
  border-radius: 3px;
  width: 60%;
  padding: 3.5%;
  margin-top: 3%;
}

.forMobileViewAddProfilePhoto_card_BrowseButton {
  display: none;
}

.AddProfilePhoto_card_BrowseDiv > p {
  font-weight: 500;
  font-size: 17px;
  color: #434242;
  margin-bottom: 10px;
}

.AddProfilePhoto_card_BrowseButton {
  color: white;
  background-color: #e9117c;
  border: none;
  border-radius: 5px;
  padding: 3px 25px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.AddProfilePhoto_card_BrowseButton:hover {
  background-color: #cc1772;
}
.AddProfilePhoto_card_BrowseButton:focus {
  background-color: #e9117c;
}

/* Proceed button */
.AddProfilePhoto_card_ProceedButton {
  color: white;
  background-color: #e9117c;
  border: none;
  border-radius: 5px;
  padding: 5px 28px;
  margin-top: 10%;
  font-weight: 400;
  font-size: 1.1em;
}

.AddProfilePhoto_card_ProceedButton:hover {
  background-color: #cc1772;
}
.AddProfilePhoto_card_ProceedButton:focus {
  background-color: #e9117c;
}

/* Skip Button */

.AddProfilePhoto_card_Skip_link_Div {
  display: flex;
  justify-content: flex-end;
}

.AddProfilePhoto_card_Skip_link {
  color: #e9117c;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.9em;
  font-family: Arial, Helvetica, sans-serif;
  text-align: right;
  cursor: pointer;
}

.AddProfilePhoto_card_Skip_link > .material-symbols-outlined {
  margin-top: 2px;
  font-size: 1.1em;
  font-weight: 600;
  color: #e9117c;
}

/* Skip_Notification Popup */
.popupContainer {
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(4 4 4 / 60%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popupBox {
  background-color: white;
  width: 60%;
  border-radius: 15px;
  padding: 2% 1%;
}

.popupBox > .PopupBox_X_button {
  background-color: transparent;
  border: none;
  outline: none;
  color: #e9117c;
  font-size: medium;
  font-weight: bold;
  float: right;
  margin: -2% 13px 0px 0px;
}

.popupBox > h4 {
  color: #e9117c;
}

.popupBox > p {
  color: #b4b9e4;
  font-size: medium;
  margin-top: 1%;
}

.PopupBox_FuncButton_Div {
  display: flex;
  gap: 2%;
  justify-content: center;
  margin-top: 3%;
}

.PopupBox_withOutPhoto_button {
  background-color: transparent;
  border: 1px #b4b9e4 solid;
  border-radius: 8px;
  color: #e9117c;
  padding: 0.3% 5%;
  font-weight: 500;
}

.PopupBox_AddPhoto_button {
  background-color: #e9117c;
  border: 1px #b4b9e4 solid;
  border-radius: 8px;
  color: white;
  padding: 0.3% 5%;
  font-weight: 500;
}

.PopupBox_withOutPhoto_button > .DesktopViewButton,
.PopupBox_AddPhoto_button > .DesktopViewButton{
  display: inline;
}

.PopupBox_withOutPhoto_button >.MobileViewButton,
.PopupBox_AddPhoto_button >.MobileViewButton{
  display: none;
}


/* photoEditPopUp */
.photoEditPopUp_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(4 4 4 / 60%);

  /* Flex */
  display: flex;
  justify-content: center;
  align-items: center;
}

.photoEditPopUp_Box {
  background-color: #494949;
  color: white;
  border-radius: 20px;
  height: 68%;
  width: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photoUploadlabel {
  display: none;
}

.profilePhotoCropIMG {
  margin: 10% 0% 6% 0%;
  height: 43%;
  width: 80%;
  overflow: hidden;
  position: relative;
  display: flex !important;
  align-items: center;
}

#addPR_Cropping_img {
  width: auto;
  height: 100%;
  /* width: auto;
  height: auto; */
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
}

.profilePhotoCrop_Division {
  width: 65%;
}

.profilePhotoCropedPreview {
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  height: 150px;
  width: 150px;
  border: rgba(189, 181, 181, 0.6) 1.5px solid;
  border-radius: 5px;
  padding: 0px;
  margin-bottom: 5%;
  overflow: hidden;
}

.profilePhotoCropedPreview > canvas {
  object-position: center;
  object-fit: fill;
  width: 105%;
  height: 105%;
  margin: -2px;
  border: none;
  outline: none;
}

.profilePhotoCropedPreview_label {
  color: white;
}

.profilePhotoCrop_tools {
  margin-top: -15px;
  /* margin-left: 1%; */
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  gap: -50px;
}

.profilePhotoCrop_tools > div {
  margin: 18px 0px;
}

.profilePhotoCrop_tools > div > i {
  font-size: 1.5em;
  padding-right: 18px;
  margin-top: 10px;
}

.profilePhotoCrop_tools > div > label {
  background-color: #ffffff;
  color: #494949;
  font-size: 0.9em;
  /* font-size: 1em; */
  border-radius: 6px;
  border: none;
  outline: none;
  width: 60%;
  height: 30px;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 5px;
}
.profilePhotoCrop_tools > div > button {
  background-color: #ffffff;
  color: #494949;
  font-size: 0.9em;
  /* font-size: 1em; */
  border-radius: 6px;
  border: none;
  outline: none;
  width: 60%;
  height: 30px;
  font-family: Arial, Helvetica, sans-serif;
}

.profilePhotoCrop_PermissionButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-top: 3%;
}

.profilePhotoCrop_PermissionButtons > button {
  background-color: #ffffff;
  color: #494949;
  font-size: 0.9em;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 6px;
  border: none;
  outline: none;
  width: 90px;
  height: 25px;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .AddProfilePhoto_card_BrowseDiv {
    display: none;
  }

  .forMobileViewAddProfilePhoto_card_BrowseButton {
    display: inline;
    color: white;
    background-color: #e9117c;
    border: none;
    border-radius: 5px;
    padding: 4px 28px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    margin-top: 20%;
  }

  .AddProfilePhoto_card_Skip_link_Div {
    display: flex;
    justify-content: flex-end;

    margin-top: -20px;
  }

  .photoEditPopUp_Box {
    background-color: #494949;
    color: white;
    border-radius: 20px;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .photoUploadlabel {
    display: inline;
    margin-top: 50px;
    font-size: 1.2em;
  }

  .profilePhotoCropedPreview {
    display: none;
  }

  .profilePhotoCrop_PermissionButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    margin-top: 7%;
  }

  .AddProfilePhoto_Placeholder_AfterOnePicUpload {
    height: 98px;
    width: 88px;

    font-size: 12px;
    text-align: center;
  }

  .AddProfilePhoto_AfterOnePicUpload_img {
    height: 98px;
    width: 98px;
  }

  .AddProfilePhoto_AfterOnePicUpload {
  display: flex;
  flex-direction: row;
  gap: 30px;

  margin-top: 10%;
}

/* Proceed button */
.AddProfilePhoto_card_ProceedButton {
  color: white;
  background-color: #e9117c;
  border: none;
  border-radius: 5px;
  padding: 5px 28px;
  margin-top: 20%;
  font-weight: 400;
  font-size: 1.1em;
}

/* Skip button Popup */

.popupBox {
  background-color: white;
  width: 90%;
  height: 32%;
  border-radius: 15px;
  padding: 2% 1%;
}

.popupBox > h4 {
  color: #e9117c;
  font-size: 1.2em;
  margin: 10% 0% 8% 0%;
}

.popupBox > p {
  color: #b4b9e4;
  font-size: medium;
  margin-top: 1%;
  margin-bottom: 8%;
}

.PopupBox_withOutPhoto_button > .DesktopViewButton,
.PopupBox_AddPhoto_button > .DesktopViewButton{
  display: none;
}

.PopupBox_withOutPhoto_button >.MobileViewButton,
.PopupBox_AddPhoto_button >.MobileViewButton{
  display: inline;
}

.PopupBox_FuncButton_Div {
  display: flex;
  gap: 10%;
  justify-content: center;
  margin-top: 3%;
}

.popupBox > .PopupBox_X_button {
  
  margin: 2% 13px 0px 0px;
}


}
