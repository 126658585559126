.tryAgainButton > button {
  box-sizing: border-box;

  width: 148px;
  height: 49px;

  background: #e9117c;
  border-radius: 10px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 29px;

  color: #ffffff;
  border: none;
  outline: none;
  margin: 0px;
}


/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .tryAgainButton > button {
    margin: 25px 0px;
  }
}