.editEmailContainer {
  text-align: center;
  padding: 0px 20px;
}
.editEmaileHeader {
  margin: 20px 0px 10px;
  color: #e9117c;
  font-weight: 500;
  font-size: 22px;
}
.editEmailtext {
  color: #b4b9e4;
}
.editEmailInp > form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 3rem;
  margin-left: 2rem;
  position: relative;
}
.editEmailInp > form > .editEmailInpFeild {
  width: 280px;
  height: 40px;
  border-radius: 7px;
  padding-left: 10px;
  border: 1px solid rgb(201, 199, 199);
}
.editEmailInp > form > .editEmailInpFeild:focus {
  outline: none;
  border: 2px solid #e9117c;
}
.editEmailInp > form > button {
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  width: 250px;
}
.emailContactLink {
  color: #b4b9e4;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.emailContactLink > span {
  color: #30b8e3;
  cursor: pointer;
}
.editEmailErr {
  color: #e9117c;
  font-weight: 500;
  position: absolute;
  top: 40px;
  left: 80px;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .editEmailInp > form > .editEmailInpFeild {
  width: 280px;
  height: 40px;
  border-radius: 7px;
  padding-left: 10px;
  border: 1px solid rgb(201, 199, 199);
}

.editEmailInp > form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 3rem;
  margin-left: 0rem;
  position: relative;
}

.editEmailInp > form > button {
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  width: 250px;
  padding: 5px 20px;
}
}
