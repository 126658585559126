.partnerPreferenceBigOption {
  width: 17em;
  outline: none;
  /* border: 1px solid #b4b9e4; */
  border: none;
  border-right: 10px solid #fff;
  outline: 1px solid #b4b9e4;
  border-radius: 10px;
  font-size: 15px;
  padding: 10px;
  color: rgba(73, 73, 73, 0.9);
  cursor: pointer;
}
.partnerPreferenceSmallOption {
  width: 6em;
}
.partnerPreferenceMediumOption {
  width: 12em;
}
.partnerPreferenceMediumLargeOption {
  width: 7rem;
}
.partnerPreferenceHugeOption {
  width: 20em;
}
.selectFielBox {
  border-radius: 4px;
}


/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
.partnerPreferenceSmallOption {
  width: 5em;
}
.partnerPreferenceBigOption{
  width: 5em;
}

.partnerPreferenceMediumLargeOption{
    width: 10em;
}
}