.Missing_Details_BlueCard {
  border-radius: 25px;
  width: 80%;
  height: 240px;
  padding: 0;
  margin: -2% 0% 5% 0%;
  background: rgba(22, 171, 255, 0.79);
  box-shadow: 0px 2px 2px rgba(158, 158, 158, 0.5);
  border-radius: 25px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
}

.card_rose_pics {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.card_rose_pics > img {
  height: 200px;
  width: 200px;
}

.Missing_Details_contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  z-index: 1;
  width: 100%;
}

.Missing_Details_contents > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: 1.3em;
  line-height: 23px;
  color: #ffffff;
  margin: 0;
}

.Missing_Details_contents > .textbox {
  width: 318px;
  height: 39px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  outline: none;
  padding-left: 15px;
}

.Missing_Details_contents > .textbox::placeholder {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 16px;
  color: rgba(180, 185, 228, 0.6);
}

.Missing_Details_contents > .buttons {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}

.Missing_Details_contents > .buttons > .skip_button {
  width: 98px;
  height: 35px;
  background-color: transparent;
  border: 3px solid #ffbe00;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  background-color: transparent;
  color: #ffffff;
}

.Missing_Details_contents > .buttons > .skip_button:hover {
  /* background: #ffbe00; */
  color: #ffffff;
}

.Missing_Details_contents > .buttons > .submit_button {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;

  width: 98px;
  height: 35px;
  border: none;
  outline: none;
  background: #ffbe00;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.Missing_Details_contents > .buttons > .submit_button:hover {
  background: #e7ae05;
}

.Missing_Details_contents > .sibling_inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 21%;
}

.Missing_Details_contents > .sibling_inputs > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Missing_Details_contents > .sibling_inputs > div > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  padding: 0;
  color: #ffffff;
}

.SubTitlePara {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: -16px;
  margin-top: -10px;
  line-height: 16px;
  padding: 0;
  color: #ffffff;
}

.Missing_Details_contents > .sibling_inputs > div > .textbox {
  height: 39px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  outline: none;
  padding-left: 15px;
}

.Missing_Details_contents > .sibling_inputs > div > .textbox::placeholder {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 16px;
  color: rgba(180, 185, 228, 0.6);
}

.Missing_Details_BlueCard > form{
  z-index: 10;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .Missing_Details_BlueCard {
    border-radius: 25px;
    width: 100%;
    height: 250px;
    padding: 0;
    margin: 0% 0% 0% 0%;
    background: rgba(22, 171, 255, 0.79);
    box-shadow: 0px 2px 2px rgba(158, 158, 158, 0.5);
    border-radius: 0px;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
  }

  .card_rose_pics > img {
    height: 100px;
    width: 100px;
  }

  .Missing_Details_contents > p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-size: 0.9em;
    line-height: 23px;
    color: #ffffff;
    margin: 0;
  }
}
