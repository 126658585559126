.inputCheckBox {
  display: flex;
  column-gap: 20px;
}
.inputCheckBox > input {
  height: 20px;
  width: 20px;
  margin-bottom: 10px;
  color: #e9117c;

}
.inputCheckBox > label {
  font-size: 14px;
}
.inputCheckBox > input[type="checkbox"] {
  accent-color: #e9117c;
  border: none;
  /* outline: 1px solid #E9117C; */
  /* border: 1px solid #E9117C; */
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .inputCheckBox {
    display: flex;
    column-gap:8px;
  }
}
