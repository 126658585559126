.PagenotFound {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.PagenotFound-Context {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  color: #e9117c;
  padding-top: 100px;
  padding-left: 30px;
}

.PagenotFound-Context > span {
  font-size: 20px;
  color: #000000;
  padding-top: 20px;
}

.PagenotFound-Image > img {
  width: 930px;
  height: 824px;
  padding: 20px;
}

.PageHeading {
  display: none;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .PageNotFoundTapBar {
    background: linear-gradient(
      102.23deg,
      #e9117c 1.68%,
      rgba(255, 214, 152, 0.5) 118.95%
    );
    height: 205px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    position: relative;
  }

  .PageHeading {
    display: none;
  }

  .PageNotFoundProfilePics {
    width: 105px;
    height: 98px;
    overflow: hidden;
    border-radius: 15px;
    background: rgba(180, 185, 228, 0.44);
    border-radius: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .PageNotFoundProfilePics > img {
    width: 100%;
    height: 110%;
  }

  .PageNotFoundProfilePics > .placeHolderImg {
    width: 70%;
    height: 70%;
  }

  .PageNotFoundTapBar > .leftArrowIcon {
    color: white;
    font-size: 2em;
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .pageNotFoundProfileDetails {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: .9em;
    line-height: 14px;
    letter-spacing: 0.5px;

    color: #ffffff;
  }

  .PagenotFound {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.PagenotFound-Context {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8em;
  line-height: 46px;
  color: #e9117c;
  padding-top: 55px;
  padding-left: 30px;
}

.PagenotFound-Context > span {
  font-size: .7em;
  color: #000000;
  padding-top: 20px;
  width: 90%;
}

  .PagenotFound-Image{
    height: fit-content;
    width: 100vw;
  }

  .PagenotFound-Image > img {
    width: 100%;
    height: 100%;

    padding: 10%;
  }
}
