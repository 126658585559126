.shareInput > input {
  position: relative;
  border: 1px solid #e9117c;
}
.shareInput > button {
  border: none;
  outline: none;
  position: absolute;
  margin-top:3.5px;
  background-color: #e9117c;
  color: #fff;
  width: 108px;
  margin-left: -113px;
  height:1.8rem;
}
.shareInputMedium > input {
  width: 20rem;
}
.shareInputLarge > input {
  width: 32rem;
}


/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {

  .shareInputMedium > input {
  width: 15rem;
}
  .shareInputLarge > input {
  width: 22rem;
}
}
