.editMobileContainer {
  text-align: center;
}

.editMobileContainer > .mobileVerifiedIcon {
  padding-right: 20px;
  padding-top: 5px;
}

.editMobileHeader {
  margin: 80px 0px 10px;
  color: #e9117c;
  font-weight: 500;
  font-size: 27px;
}

.editMobileText {
  color: grey;
  font-size: 20px;
  margin-bottom: 36px;
}

.editMobileInp > form {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 3rem;
  margin-left: 2rem;
  margin-bottom: 5rem;
  position: relative;
}

.editMobileInp > form > .editDefaultFeild {
  width: 70px;
  color: #111;
  height: 40px;
  border-radius: 7px;
  padding-left: 10px;
  border: 1px solid rgb(201, 199, 199);
}

.editMobileInp > form > .editMobileInpFeild {
  width: 200px;
  height: 40px;
  border-radius: 7px;
  padding-left: 10px;
  border: 1px solid rgb(201, 199, 199);
}

.editMobileInp > form > input:focus {
  outline: none;
  border: 1.8px solid #e9117c;
}

.editMobileInp > form > input::placeholder {
  color: rgb(201, 199, 199);
  padding-left: 4px;
}

.editMobileInp > form > button {
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  border-radius: 5px;
  width: 160px;
}

.editMobileErr {
  color: red;
  position: absolute;
  top: 40px;
  left: 240px;
  font-weight: 500;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .editMobileInp > form {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 3rem;
    margin-left: 2rem;
    margin-bottom: 5rem;
    position: relative;
  }

  .editMobileHeader {
    margin: 100px 0px 20px;
    color: #e9117c;
    font-weight: 500;
    font-size: 1.3em;
  }

  .editMobileText {
    color: #b4b9e4;
    font-size: 1.2em;
    margin-bottom: 36px;
  }

  .editMobileInp > form {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 3rem;
    margin-left: 0rem;
    margin-bottom: 5rem;
    position: relative;
  }

  .editMobileInp > form > button {
    border: none;
    outline: none;
    background-color: #e9117c;
    color: #fff;
    border-radius: 5px;
    padding: 8px 20px;
    font-weight: 500;
    margin-top: 10px;
  }

  .editMobileErr {
    color: red;
    position: absolute;
    top: 130px;
    left: 68px;
    font-weight: 500;
  }
}
