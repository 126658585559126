.partnerTextField > .k-input > .k-input-inner {
  height: 130px;
  width: 75%;
  border: 1px solid #494949e6;
  padding-left: 10px;
}
.partnerTextField div:last-child {
  font-size: 13px;
  color: #000000;
}

.aboutPrefFields {
  position: relative;
  width: 100%;
  margin-top: -40px;
}

/* Edit Time */
.PartnerPreference_FewWords_EditTime > .partnerFieldAns {
  width: 80%;
  height: 150px;
  margin: 4% 0% 2% 0%;
  padding-left: 40px;
}

.PartnerPreference_FewWords_EditTime
  > .partnerFieldAns
  > span
  > .k-input-inner {
  width: 100%;
  height: 100%;
  border: 2px solid #e9117c;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: justify;

  padding: 10px 20px;
  color: rgba(73, 73, 73, 0.9);
}
.PartnerPreference_input_form_Bottomlabel {
  color: #000000;
  padding-top: 0%;
  margin-left: 40px;
}

.PRChar_Remaining_count_span {
  color: #e9117c;
  font-weight: 600;
}

.PartnerPreference_FewWords_PTag {
  width: 100%;
}

.PartnerPreferenceAboutDetails {
  margin: 40px 40px 40px 40px;
}

.PartnerPreferenceAboutDetails > p {
  width: 90%;

  /* white-space:nowrap; */
  word-wrap: break-word;
  font-size: 1.05em;
  font-weight: 500;
  text-align: justify;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .PartnerPreferenceAboutDetails {
    margin: 20px 0px 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .PartnerPreference_input_form_Bottomlabel {
  color: #000000;
  padding-top: 0%;
  margin-left: 70px;
  margin-bottom: 20px;
}

  .AboutPartnerFieldTitle{
    margin: 0px 0px;
  }

  .PartnerPreferenceAboutDetails > p {
    width: 100%;

    /* white-space:nowrap; */
    word-wrap: break-word;
    font-size: 1em;
    line-height: 28px;
    text-align: justify;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: justify;
    letter-spacing: 0.04em;

    color: rgba(73, 73, 73, 0.9);
  }

  .PartnerPreference_FewWords_EditTime > .partnerFieldAns {
  width: 90%;
  height: 150px;
  margin: 6% 0% 8% 0%;
  padding-left: 10px;
}

  .PartnerPreference_FewWords_EditTime
  > .partnerFieldAns
  > span
  > .k-input-inner {
  width: 100%;
  height: 100%;
  border: 2px solid #e9117c;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: justify;

  padding: 10px 20px;
  color: rgba(73, 73, 73, 0.9);
}
}
