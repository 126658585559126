

.Profile-Setting-Tab-Shortlisted {
  width: 382px;
  height: 382px;
}




.ShortlistedProfiles {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffecd2;
  padding: 150px 150px 100px 150px;
  gap: 40px;
}
.Shortlisted-Profiles {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #494949;
  width: 868px;
  height: 2090px;
  background: #ffffff;
  border-radius: 25px;
  padding: 40px;
}

/* .col-lg-8 {
  flex: none;
  width: 100%;
} */

.ShortlistedContainerPage {
  position: relative;
}
.ShortlistedContainerHeaders {
  margin-top: 45px;
  margin-left: 7rem;
  width: 80vw;
}

.ShortlistedId {
  color: #e9117c;
}
.ShortlistedContainerHeader1 {
  font-weight: 400;
  font-size: 27px;
}
.ShortlistedContainerHeaders div:last-child {
  color: #9e9e9e;
  margin-bottom: 45px;
}
.ShortlistedContainer {
  margin-top: 22px;
  display: flex;
  justify-content: center;
  width: 100%;
  column-gap: 50px;
  font-family: "Helvetica";
}
.ShortlistedCardContainerBox {
  border-radius: 25px;
  width: 800px;
  margin-bottom: 50px;
  border: 1px solid rgba(158, 158, 158, 0.24);
  border-radius: 15px;

  /* display: flex;
    column-gap: 2.5rem;
    width: 870px;
    margin-bottom: 50px; */
}
.ShortlistedCardContainer {
  display: flex;
  column-gap: 2.5rem;
  height: 400px;
  width: 789px;
  padding: 20px 30px;
}
.ShortlistedCardIdContainer {
  display: flex;
  justify-content: flex-end;
  color: #9e9e9e;
  column-gap: 10px;
  font-size: 12px;
  margin: 26px 30px 0px;
}
.ShortlistedShareIcon {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.ShortlistedShareIcon > img {
  height: 100%;
  width: 100%;
}
.ShortlistedCardPicture {
  height: 235px;
  width: 217px;
  border-radius: 15px;
  overflow: hidden;
}
.ShortlistedCardPicture > img {
  height: 100%;
  width: 100%;
  object-fit: inherit;
  object-position: center;
}
.ShortlistedCardPictureContainer {
  position: relative;
}
.ShortlistedCardPictureContainer div:last-child {
  cursor: pointer;
}
.ShortlistedCardPicture ~ div {
  color: #e9117c;
  margin-top: 10px;
  border-bottom: 2px solid #e9117c;
  position: absolute;
  left: 34%;
}

/* details */

.ShortlistedCardTick {
  height: 20px;
  width: 20px;
  font-size: 40px;
  color: #04923c;
  z-index: 1;
}
.ShortlistedCardIsVerified {
  font-size: 12px;
  display: flex;
  column-gap: 4px;
  margin-bottom: 0.5rem;
}
.ShortlistedCardVerified {
  color: #04923c;
}
.ShortlistedCardPending {
  color: #ff9900;
}
.ShortlistedCardYetToVerify {
  color: #ee0909;
}
.ShortlistedVerifiedIcon {
  height: 14px;
  width: 14px;
}
.ShortlistedVerifiedIcon > img {
  height: 100%;
  width: 100%;
}
/* .ShortlistedCardVerified > i {
    margin-right: 10px;
  } */
.ShortlistedCardName {
  color: #e9117c;
  font-size: 1.4rem;
  font-weight: 500;
}
.ShortlistedCardLocation {
  font-size: 0.8rem;
}
.ShortlistedCardDetails {
  margin-top: 14px;
  display: flex;
  gap: 40px;
  line-height: 1.8rem;
  font-size: 15px;
}
.ShortlistedCardGrey {
  color: rgba(158, 158, 158, 0.8);
}
.ShortlistedCardBlack {
  color: rgba(67, 66, 66, 0.71);
  font-weight: 500;
}
.ShortlistedCardHr {
  width: 100%;
  /* color: rgba(158, 158, 158, 0.8); */
}
.ShortlistedCardButtons > button {
  margin-right: 24px;
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  padding: 9px;
  border-radius: 7px;
  font-size: 15px;
}
.ShortlistedCardButtons button:first-child > div {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}
.ShortlistedChatIcon {
  height: 20px;
  width: 20px;
}
.ShortlistedChatIcon > img {
  height: 100%;
  width: 100%;
}
.ShortlistedCardButtons > button > i {
  margin-right: 10px;
}

/* share profile container */
.ShortlistBlack {
  position: absolute;
  top: -10px;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(145, 125, 125, 0.5);
  height: 2500px;
  margin-top: 75px;
  z-index: 1;
}
.shareProfileContainer {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.shareProfileContainer > div {
  position: absolute;
  background-color: #fff;
  border-radius: 25px;
  width: 80%;
  margin-top: 40%;
  padding: 20px 40px;
}
.ShortlistedShareClose {
  /* padding: 20px 30px; */
  text-align: right;
  color: #e9117c;
  cursor: pointer;
}
.ShortlistedShareContainerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ShortlistedShareHeader {
  display: flex;
  column-gap: 30px;
}
.ShortlistedShareBlue {
  height: 30px;
  width: 30px;
}
.ShortlistedShareBlue > img {
  height: 100%;
  width: 100%;
}
.ShortlistedShareContent {
  margin-top: 2.2rem;
  line-height: 2rem;
}
.ShortlistedShareContent > form {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.Profile-Setting-Tab-Shortlisted {
    display: inline;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    .ShortlistedProfiles {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: #ffecd2;
      padding: 137px 122px 74px 31px;
      gap: 15px;
  }
  .Profile-Setting-Tab-Shortlisted {
    width: 112px;
    height: 382px;
  }
  .Shortlisted-Profiles {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #494949;
    width: 815px;
    height: 2090px;
    background: #ffffff;
    border-radius: 25px;
    padding: 40px;
  }
  .HistoryCardContainerBox {
    border-radius: 25px;
    width: 685px;
    margin-bottom: 50px;
    border: 1px solid rgba(158, 158, 158, 0.24);
    border-radius: 15px;
  }
  .HistoryCardPicture ~ div {
    color: #e9117c;
    margin-top: 10px;
    border-bottom: 2px solid #e9117c;
    font-size: 0.9em;
    position: absolute;
    left: 31%;
    bottom: -50px;
  }
    
  }
/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .Profile-Setting-Tab-Shortlisted {
    display: none;
  }

   .ShortlistedContainerHeaders {
    display: none;
  }

  .ShortlistedProfiles {
   width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffffff;
    padding: 0px;
    gap: 0px;
}

.Shortlisted-Profiles{
   font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #494949;
    width: 100%;
    height: fit-content;
    background: #ffffff;
    border-radius: 25px;
    padding: 0px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ShortlistedesContainer {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .shareProfileContainer > div {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fff;
  border-radius: 0px;
  width: 100%;
  margin-top: 40%;
  padding: 20px 40px;
}
}