.emailContainer {
  text-align: center;
  padding: 7%;
}
.emailVerifiedContainer {
  height: 200px;
}
.emailHeader {
  margin: 20px 0px 10px;
  color: #e9117c;
  font-weight: 500;
  font-size: 20px;
}
.emailText {
  margin-top: 2rem;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 29px;

  color: #b4b9e4;
}
.emailText > span {
  font-weight: 500;
  margin-right: 2px;
}
.emailTextLink {
  margin-top: 2rem;
  color: #b4b9e4;
  font-size: small;
}
.emailTextLink > span {
  color: #30b8e3;
  cursor: pointer;
}

.EmailVerifiedIcon {
  color: #e9117c;
  font-size: 20px;
  position: absolute;
  right: 50px;
  top: 30px;
  cursor: pointer;
}
.EmailVerifiedTHeader {
  margin: 20px 0px 30px;
  color: #e9117c;
  font-weight: 500;
  font-size: 28px;
}
.EmailVerifiedText {
  color: #b4b9e4;
  font-size: 21px;
  margin-bottom: 50px;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .emailText {
  margin-top: 2rem;

  font-family: 'Helvetica';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 29px;

color: #B4B9E4;
}

.EmailVerifiedIcon {
  color: #e9117c;
  font-size: 20px;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}
}
