/* PR_Tab5_input */

.PR_Tab5_input_form {
  padding: 6% 0% 12% 5%;
}

/* PR_Tab5_input_Labels */
.PR_Tab5_input_form_Toplabel {
  color: #b4b9e4;
  font-size: 1.4em;
  padding-bottom: 2%;
}

.PR_Tab5_input_form_Bottomlabel {
  color: #b4b9e4;
  padding-top: 1%;
}

/* PR_Tab5_form_inputBox */
.k-input-inner {
  height: 230px;
  width: 98%;
  border: #e9117c 3px solid;
  border-radius: 10px;
  font-size: 1.2em;
  outline: none;
  padding-top: -50px;
  resize: none;
}

/* Char_Remaining_count_span */
.Char_Remaining_count_span {
  color: #e9117c;
  font-weight: 500;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .PR_Tab5_input_form_Toplabel {
    font-size: 1.1em;
  }
  .PR_Tab5_input_form {
    padding: 6% 5% 12% 5%;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* Media Query MOBILES*********************/

@media (min-width: 319px) and (max-width: 426px) {
  /* PR_Tab5_input_Labels */
  .PR_Tab5_input_form_Toplabel {
    color: #b4b9e4;
    font-size: 1.1em;
    padding-bottom: 2%;
    padding-left: 2%;
  }

  .PR_Tab5_input_form_Bottomlabel {
  color: #b4b9e4;
  padding-top: 1%;
  padding-left: 2%;
}
}
