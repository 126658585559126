.aadharUpload {
  box-shadow: 0px 4px 4px rgba(158, 158, 158, 0.75);
}
.aadharPending {
  border: 1px solid #1584d2;
}
.aadharSuccess {
  border: 1px solid #04923c;
}
.aadharStatusImage{
  position:absolute;
  right: 1.4rem;
  top: 1.3rem;
  height: 70px;
  width: 70px;
}
.aadharStatusImage > img{
  height: 100%;
  width: 100%;
}