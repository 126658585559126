.forgotContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20%;
  position: relative;
}
.forgotHeader {
  color: #e9117c;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.forgotText{
  font-size: 1em;
}

.forgotFull {
  height: 350px;
}

.goBack {
  color: #e9117c;
  display: flex;
  position: absolute;
  bottom: 100px;
  cursor: pointer;
  font-weight: 500;
}
.goBack > span {
  color: #e9117c;
  margin-right: 4px;
}

.sendCodeButton {
  margin-top: 60px;
}
.forgotTextBold {
  font-weight: 500;
  margin-left: 10px;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .forgotContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20%;
  position: relative;
}

.sendCodeMobileContainer {
    background-color: #fff;
    width: 85%;
    height: fit-content;
    z-index: 2;
    position: absolute;
    top: 0;
    margin-top: 140px;
    border-radius: 26px;

    overflow: hidden;
  }

.forgotHeader {
  color: #e9117c;
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 20px;
}

.forgotText{
  font-size: 1em;
  color: #969696;
}

.forgotFull {
  height: 50px;
}

.goBack {
  display: none;
}

.sendCodeButton {
  margin-top: 40px;
  width: 100%;
}
.forgotTextBold {
  font-weight: 600;
  margin-left: 10px;
  font-size: 1.1em;
  color: #494949E5;
  margin-top: 10px;
}
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
.forgotContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 13%;
  position: relative;
}
.forgotHeader {
  color: #e9117c;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
}