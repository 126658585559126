/* PersonalDetails_FamilyDetails */
.PersonalDetails_FamilyDetails,
.EditTimePersonalDetails_FamilyDetails_Card {
  width: 81.1%;
}

.PersonalDetails_FamilyDetails > div > h6,
.EditTimePersonalDetails_FamilyDetails_Card > div > h6 {
  color: #e9117c;
  margin: 3% 0% 0% 0%;
  font-size: 1.8em;
}

.PersonalDetails_FamilyDetails > .card,
.EditTimePersonalDetails_FamilyDetails_Card > .card {
  background-color: #ffffff;
  height: 5%;
  margin: 3% 0% 2% 0%;
  position: relative;
  align-items: center;
  border-radius: 30px;
  border-left: #e9117c 8px solid;
  border-right: #e9117c 8px solid;
}

.PersonalDetails_FamilyDetails > .card > p,
.EditTimePersonalDetails_FamilyDetails_Card > .card > p {
  width: 95%;
  font-weight: 400;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(73, 73, 73, 0.9);
  padding: 4% 4% 1% 0%;
}
.Details_section_values_Family {
  margin-top: 0.6rem;
  line-height: 2.2rem;
}
.PersonalDetails_familyDetails_Sibilings_Row {
  display: flex;
  column-gap: 40px;
  z-index: 1;
}

.PersonalDetails_familyDetails_withOutColon {
  display: none;
}

.PersonalDetails_familyDetails_FamilyLocation {
  margin-top: 6.4rem;
}

.PersonalDetails_familyDetails_FamilyLocation > .partnerFieldRadio > input {
  margin-top: 1rem;
}

.PersonalDetails_familyDetails_sibiling > label {
  margin-bottom: 0.8rem;
}

.mobileViewEditButton{
  display: none;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .FamilyDetails_section_colon_EditTime > li {
    margin-top: 35px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}


/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .PersonalDetails_FamilyDetails,
  .EditTimePersonalDetails_FamilyDetails_Card {
    width: 90.1%;

    position: relative;
  }

  .PersonalDetails_FamilyDetails > .fewWordBoxHeader,
  .EditTimePersonalDetails_FamilyDetails_Card > .fewWordBoxHeader {
    margin: 8% 0% 8% 0%;

    display: flex;
    align-items: center;
  }

  .DesktopViewEditButton {
    display: none;
  }

  .mobileViewEditButton {
    display: inline;
  }

  .EditPage_EditButton {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .PersonalDetails_FamilyDetails > div > h6,
  .EditTimePersonalDetails_FamilyDetails_Card > div > h6 {
    color: #e9117c;
    margin: 0%;
    font-size: 1.3em;
    font-weight: 500;
  }

  .PersonalDetails_FamilyDetails > div > .mobileViewEditButton,
  .EditTimePersonalDetails_FamilyDetails_Card > div > .mobileViewEditButton {
    color: #e9117c;
    margin: 7.5% 0px;
    font-size: 1em;
    font-weight: 500;
  }

  .PersonalDetails_FamilyDetails > .card {
    background-color: #ffffff;
    height: 5%;
    margin: 3% 0% 2% 0%;
    /* position: relative; */
    align-items: center;
    border-radius: 30px;
    border-left: #e9117c 4px solid;
    border-right: #e9117c 4px solid;
  }

  .EditTimePersonalDetails_FamilyDetails_Card> .card {
    background-color: #ffffff;
    height: 1350px;
    margin: 3% 0% 2% 0%;
    /* position: relative; */
    align-items: center;
    border-radius: 30px;
    border-left: #e9117c 4px solid;
    border-right: #e9117c 4px solid;
  }

  .FedwWordsInputBox {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 21px;
    letter-spacing: 0.5em;

    color: rgba(73, 73, 73, 0.6);
  }

  .EditFDRadioFields{
    display: flex;
    flex-direction: column;
  }
  
  .FD_Details_section_values{
    margin: 1px 0px 0px 10px;
  }

  .Details_section_label_EditTime > .EditFamilyValues,
  .Details_section_colon_EditTime > .EditFamilyValues{
    padding-bottom: 115px;
  }

  .Details_section_label_EditTime > .EditFamilyType,
  .Details_section_colon_EditTime > .EditFamilyType{
    padding-bottom: 80px;
  }

  .Details_section_label_EditTime > .EditFamilyStates,
  .Details_section_colon_EditTime > .EditFamilyStates{
    padding-bottom: 115px;
  }

  .Details_section_label_EditTime > .EditFamilyFathers{
    padding-bottom: 165px;

  }

  .Details_section_colon_EditTime > .EditFamilyFathers{
    padding-bottom: 188px;
  }

  .Details_section_label_EditTime > .EditFamilyMothers{
    padding-bottom: 165px;

  }

  .Details_section_colon_EditTime > .EditFamilyMothers{
    padding-bottom: 193px;
  }

  .PersonalDetails_familyDetails_Sibilings_Row {
  display: flex;
  flex-direction: column;
  column-gap: 40px;
  z-index: 1;
}

.PersonalDetails_familyDetails_sibiling{
  width: 150%;
  display: flex;
  gap: 20px;
}

.PersonalDetails_familyDetails_sibiling > label{
  padding-right: 80px;
}

.PersonalDetails_familyDetails_sibiling > .InputBoxNoOfSisters{
  margin-left: 10px;
}

.PersonalDetails_familyDetails_sibiling > .InputBoxNoOfSistersMarrid{
  margin-left: 5px;
}

.PersonalDetails_familyDetails_FamilyLocation {
  display: flex;
  flex-direction: column;
  margin-top: 16.3rem;
}
}
