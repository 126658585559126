.matchesContainerPage {
  position: relative;
}
.matchContainerHeaders {
  margin-top: 45px;
  margin-left: 7rem;
  width: 80vw;
}

.matchId {
  color: #e9117c;
}
.matchContainerHeader1 {
  font-weight: 400;
  font-size: 27px;
}
/* .matchContainerHeaders div:last-child {
  color: #9e9e9e;
  margin-bottom: 45px;
} */
.matchesContainer {
  margin-top: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  column-gap: 50px;
  font-family: "Helvetica";

  position: relative;
}
.matchesCardContainerBox {
  border: 1px solid rgba(158, 158, 158, 0.24);
  border-radius: 25px;
  width: 68%;
  margin-bottom: 50px;
}

.matchCardIdContainer {
  display: flex;
  justify-content: flex-end;
  color: #9e9e9e;
  column-gap: 10px;
  font-size: 12px;
  margin: 26px 30px 0px;
}

.matchesCardContainer {
  display: flex;
  flex-direction: row;
  column-gap: 2.5rem;
  width: 100%;
  padding: 50px 30px;
}

.matchesCardPictureContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 200px;
}

.matchesCardDetailsContainer {
  width: 50%;
}

.matchShareIcon {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.matchShareIcon > img {
  height: 100%;
  width: 100%;
}
.matchCardPicture {
  height: 235px;
  width: 217px;
  border-radius: 15px;
  overflow: hidden;
}
.matchCardPicture > img {
  height: 100%;
  width: 100%;
  object-fit: inherit;
  object-position: center;
}
.macthesNoProfilePic {
  background-image: url("../../../assets/images/Matches/requestProfilePic.png");
  background-size: cover;
  border-radius: 15px;
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0.9rem;
  text-align: center;
  height: 210px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -1;
}
.macthesNoProfilePic > div,
.macthesNoProfilePic > button {
  z-index: 1;
}
.macthesNoProfilePicRequest {
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  border-radius: 7px;
  padding: 0.5rem;
  font-weight: 500;
  margin: 1.2rem 0rem;
}
.matchCardPictureContainer {
  position: relative;
}
.matchCardPictureContainer div:last-child {
  cursor: pointer;
}
.matchCardPicture ~ div {
  color: #e9117c;
  margin-top: 10px;
  border-bottom: 2px solid #e9117c;
  position: absolute;
  left: 34%;
}

/* details */

.matchCardTick {
  height: 20px;
  width: 20px;
  font-size: 40px;
  color: #04923c;
  z-index: 1;
}
.matchCardIsVerified {
  font-size: 12px;
  display: flex;
  column-gap: 4px;
  margin-bottom: 0.5rem;
}
.matchCardVerified {
  color: #04923c;
}
.matchCardPending {
  color: #ff9900;
}
.matchCardYetToVerify {
  color: #ee0909;
}
.matchesVerifiedIcon {
  height: 14px;
  width: 14px;
}
.matchesVerifiedIcon > img {
  height: 100%;
  width: 100%;
}

.matchCardName {
  color: #e9117c;
  font-size: 1.4rem;
  font-weight: 500;
}
.matchCardLocation {
  font-size: 0.8rem;
}
.matchCardDetails {
  margin-top: 14px;
  display: flex;
  gap: 40px;
  line-height: 1.8rem;
  font-size: 15px;
}
.matchCardGrey {
  color: rgba(158, 158, 158, 0.8);
}
.matchCardBlack {
  color: rgba(67, 66, 66, 0.71);
  font-weight: 500;
}
.matchCardHr {
  width: 120%;
  /* color: rgba(158, 158, 158, 0.8); */
}
.matchCardButtons > button {
  margin-right: 24px;
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  padding: 9px;
  border-radius: 7px;
  font-size: 15px;
}
.matchCardButtons button:first-child > div {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}
.matchChatIcon {
  height: 20px;
  width: 20px;
}
.matchChatIcon > img {
  height: 100%;
  width: 100%;
}
.matchCardButtons > button > i {
  margin-right: 10px;
}

/* share profile container */
.matchesBlack {
  position: absolute;
  top: -10px;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(145, 125, 125, 0.5);
  height: 100%;
  margin-top: 75px;
  z-index: 1;
  overflow: hidden;
}
.shareProfileContainer {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.shareProfileContainer > div {
  position: absolute;
  background-color: #fff;
  border-radius: 25px;
  width: 40%;
  margin-top: 40%;
  padding: 20px 40px;
}
.matchShareClose {
  text-align: right;
  color: #e9117c;
  cursor: pointer;
}
.matchShareContainerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.matchShareHeader {
  display: flex;
  column-gap: 30px;
}
.matchShareBlue {
  height: 30px;
  width: 30px;
}
.matchShareBlue > img {
  height: 100%;
  width: 100%;
}
.matchShareContent {
  margin-top: 2.2rem;
  line-height: 2rem;
}
.matchShareContent > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.matchesOptions {
  display: inline;
}

.matchesCardHr {
  display: block;
  width: 120%;
  margin-left: 0px;
}

.matchesCardButtons {
  margin-left: 0px;
  display: flex;

  width: 115%;
}

.matchesCardButtons > button {
  margin-right: 30px;
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  padding: 5px 5px;
  border-radius: 7px;
  font-size: 0.8em;
  text-align: center;
  width: 140px;
  height: 35px;
}

.matchesCardButtons > button > div {
  display: flex;
  gap: 10px;
}

.matchesCardButtons > button > div > i {
  margin-top: 5px;
}

.matchesCardButtons > button > i {
  margin-right: 10px;
}

.matchesCardButtons > .ShowInteretedBTN {
  margin-right: 10px;
  width: 206px;
  padding: 5px 10px;
}

.NoDataProfilesContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;

  width: 100%;
  height: 100vh;
}

.MobileMatchesFilterButton {
  display: none;
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
  .matchesOptions {
    display: inline;
    width: 32%;
  }
  .matchesContainer {
    column-gap: 0px;
  }

  .matchesCardContainer {
    width: 65.6666%;
  }
  .matchesCardContainerBox {
    width: 90%;
  }
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .matchesOptions {
    display: inline;
    width: 35%;
  }
  .matchesContainer {
    column-gap: 0px;
  }
  .matchesCardContainer {
    width: 62%;
  }
  .matchesCardContainerBox {
    width: 100%;
  }
  .dropDownComponentTitle {
    font-size: 14px;
  }
  .partnerFieldRange {
    display: flex;
    column-gap: 15px;
    font-size: 12px;
  }
  .selectFielBox {
    border-radius: 4px;
    height: 40px;
  }
  .matchCardPicture {
    height: 150px;
    width: 150px;
  }
  .macthesNoProfilePic {
    text-align: center;
    height: 150px;
    width: 180px;
  }
  .macthesNoProfilePic > div,
  .macthesNoProfilePic > button {
    margin: 5px 10px 10px 10px;
    font-size: 12px;
  }
  .filterDropDownTitle {
    font-size: 14px;
  }
  .matchCardName {
    font-size: 20px;
  }
  .matchCardDetails {
    font-size: 12px;
  }
  .matchCardHr {
    width: 92%;
  }
  .matchCardPictureContainer div:last-child {
    display: flex;
    margin-left: -15px;
    font-size: 14px;
  }
  .matchCardButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
  }
  .matchChatIcon {
    height: 15px;
    width: 15px;
  }
  .matchCardButtons > button {
    padding: 5px;
    height: 35px;
    width: 95px;
    font-size: 10px;
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .matchesCardButtons > .ShowInteretedBTN {
    margin-right: 10px;
    width: 206px;
    padding: 0px 15px;
}
.matchesCardButtons > button {
  margin-right: 30px;
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  padding: 0px 15px;
  border-radius: 7px;
  font-size: 0.8em;
  text-align: center;
  width: 140px;
  height: 35px;
}

}
@media (min-width: 769px) and (max-width: 1024px) {
  .matchesCardButtons > .ShowInteretedBTN {
    margin-right: 10px;
    width: 206px;
    padding: 0px 15px;
}
.matchesCardButtons > button {
  margin-right: 30px;
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  padding: 0px 15px;
  border-radius: 7px;
  font-size: 0.8em;
  text-align: center;
  width: 140px;
  height: 35px;
}

}
@media (min-width: 426px) and (max-width: 768px) {
  .matchesCardButtons > .ShowInteretedBTN {
    margin-right: 10px;
    width: 206px;
    padding: 0px 15px;
}
.matchesCardButtons > button {
  margin-right: 30px;
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  padding: 0px 15px;
  border-radius: 7px;
  font-size: 0.8em;
  text-align: center;
  width: 140px;
  height: 35px;
}
}
/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .matchContainerHeaders {
    margin-top: 10%;
    margin-left: 0%;
    margin-bottom: 10%;
    width: 100vw;
  }

  .matchContainerHeader1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 27px;
    width: 100%;
    height: 60px;
    background-color: #e9117c;
    color: #fff;

    margin-bottom: 25%;
  }

  .matchContainerHeader1 > p {
    padding: 0px;
    margin: 0px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: lighter;
    font-size: 0.8em;
    line-height: 21px;
    text-align: center;

    color: #ffffff;
  }

  .matchesOptions {
    display: none;
  }

  .MobileMatchesFilterButton {
    display: inline;
    width: fit-content;

    position: absolute;
    top: -62px;
    left: 28px;
    border-radius: 20px;
    padding: 5px 30px;
    border: 1px solid #e9117c;
    filter: drop-shadow(0px 4px 4px rgba(236, 177, 177, 0.25));
    outline: none;
    background-color: #ffffff;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 0.9em;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0px;
    color: #e9117c;
  }

  .MobileMatchesFilterButton > i {
    padding-right: 6px;
  }

  .matchesCardContainerBox {
    width: 100%;
    margin-bottom: 30px;
    border: 1px solid #c5b2b2;
    border-radius: 15px;
    display: flex;
    flex-direction: column-reverse;
    margin-left: 13px;
  }

  .matchesCardContainer {
    display: flex;
    column-gap: 1rem;
    height: 286px;
    width: 100%;
    padding: 23px 20px;
  }

  .matchesCardPictureContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .matchesCardDetailsContainer {
    width: 100%;
  }

  .matchesCardHr {
    display: block;
    width: 106%;
    margin-left: -150px;
  }

  .matchesCardButtons {
    margin-left: -135px;
    display: flex;
    width: 99%;
  }

  .matchesCardButtons > .shortListBtn {
    width: 110px;
    display: flex;
    align-items: center;
  }

  .matchesCardButtons > button {
    margin-right: 32px;
    border: none;
    outline: none;
    background-color: #e9117c;
    color: #fff;
    padding: 5px 16px;
    border-radius: 7px;
    font-size: 0.8em;
    width: 193px;
    height: 30px;
  }

  .matchesCardButtons > button > div {
    display: flex;
    gap: 10px;
  }

  .matchesCardButtons > button > div > i {
    margin-top: 5px;
  }

  .matchesCardButtons > button > i {
    /* margin-top: 5px; */
    margin-right: 10px;
  }

  .matchesCardButtons > .ShowInteretedBTN {
    margin-right: 10px;
    width: 210px;
    padding: 5px 10px;
  }

  .matchCardDetails {
    margin-top: 14px;
    display: flex;
    gap: 10px;
    line-height: 1.8rem;
    font-size: 0.8em;
  }

  .matchContainerHeader2forMobile {
    display: none;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #262626;
  }

  .macthesNoProfilePic {
    background-image: url("../../../assets/images/Matches/requestProfilePic.png");
    background-size: cover;
    border-radius: 0px 0px 15px 15px;
    color: #fff;
    background-position: center;
    background-repeat: no-repeat;

    margin-top: -10px;
    padding: 0.9rem;
    text-align: center;

    height: 120%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: -1;
  }

  .macthesNoProfilePic > div {
    z-index: 1;
    font-size: 0.7em;
    letter-spacing: 0.2px;
    margin-top: 10%;
  }

  .macthesNoProfilePic > button {
    z-index: 1;
    font-size: 0.7em;
    margin-top: 10%;
    padding: 5px 10px;
  }

  .profileContentsDivision {
    width: 100%;
  }

  .NoDataProfilesContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    height: fit-content;
    width: 100%;
  }

  .NoDataProfilesContent > img {
    width: 180px;
  }

  .NoDataProfilesContent > p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1.2em;
    line-height: 21px;
    text-align: center;

    color: #494949;
  }

  .matchesBlack {
    position: absolute;
    top: -75px;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(145, 125, 125, 0.5);
    height: 100%;
    margin-top: 0px;
    z-index: 1;
    overflow: hidden;
  }

  .matchShareHeader {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 15px;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 16px;

    color: #e9117c;
  }

  .matchShareBlue {
    height: 30px;
    width: 30px;
    margin-top: -8px;
  }
  .matchShareBlue > img {
    height: 100%;
    width: 100%;
  }

  .HideFilterButton{
    display: none;
  }
}
