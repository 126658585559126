.activityHeaders div:last-child{
    border-bottom: none;
}
.activityHeader {
  padding: 1.3rem 1.3rem 0.6rem;
  border-bottom: 2px solid rgba(158, 158, 158, 0.24);
  transition: all 0.6s;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-family: "Helvetica";
  font-weight: 500;
  cursor: pointer;
}
.activityHeader div:last-child {
  color: rgba(158, 158, 158, 0.67);
}
.activityPink {
  color: #e9117c;
}
.activityPink div:last-child {
  color: #e9117c;
}
