.homeNotificationContainer {
  position: absolute;
  width: 28rem;
  top: 3.8rem;
  right: 12rem;
  background-color: #fff;
  border: 1px solid #e9117c;
  border-radius: 25px;
  padding: 1.5rem;
  z-index: 3;
  box-shadow: 0px 4px 10px rgba(247, 38, 101, 0.3);
}
.homeNotificationTitle {
  display: flex;
  justify-content: space-between;
}
.homeNotificationTitle div:last-child {
  color: #59cafc;
  cursor: pointer;
}
.homeNotification {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin: 30px 10px 0px;
  padding-bottom: 20px;
  column-gap: 20px;
  border-bottom: 1px solid rgba(73, 73, 73, 0.23);
}
.homeNotifications div:last-child {
  border: none;
}
.homeNotificationImage {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  overflow: hidden;
}
.homeNotificationImage > img {
  height: 100%;
  width: 100%;
}
.homeNotificationContent div:last-child {
  color: #ff9900;
  font-weight: 500;
}
