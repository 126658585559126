.PhotoPrivacy {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffecd2;
  padding: 150px 150px 100px 150px;
  gap: 40px;
}
.PhotoPrivacy-Header {
  display: flex;
  flex-direction: column;
}

.PhotoPrivacy-Inputs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-family: "Helvetica";
  font-weight: 400;
  font-size: 18px;
  color: #494949;
  width: 868px;
  height: 362px;
  background: #ffffff;
  border-radius: 25px;
  padding: 40px;
  gap: 20px;
}

.PhotoPrivacy-Header > span {
  font-size: 14px;
}

.PhotoPrivacy-Radio > input {
  accent-color: #e9117c;
  padding: 20px;
}

.PhotoPrivacy-Radio {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  font-size: 14px;
  text-align: center;
  color: #494949;
}

.PhotoPrivacyBackButton {
  display: none;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .PhotoPrivacy {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffffff;
    padding: 15px 15px 10px 15%;
    gap: 50px;

    position: relative;
  }

  .PhotoPrivacy-Inputs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    font-family: "Helvetica";
    font-weight: 400;
    font-size: 18px;
    color: #494949;
    width: 80%;
    height: 382px;
    background: #ffffff;
    border-radius: 25px;
    padding: 0px;
    gap: 50px;

    margin-bottom: 20%;
  }

  .PhotoPrivacy-Radio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;

    width: 100%;
    font-size: 14px;
    text-align: center;
    color: #494949;
  }

  .PhotoPrivacy-Header > span {
    font-size: 0.7em;
  }

  .PhotoPrivacy-Radio > label {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;

    color: #494949;
    font-size: 1em;
    text-shadow: 0px 0px 1px rgba(128, 0, 0, 0.81);
  }

  .PhotoPrivacyBackButton {
    display: inline;
    background: #e9117c;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    border: none;
    outline: none;
    width: 25%;
    height: 34px;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 16px;

    color: #ffffff;

    position: absolute;
    bottom: 15%;
    left: 38%;
  }
}
