.editMobileVerifiedContainer {
  width: 70%;
  height: fit-content;
  background-color: #ffffff;
  border: none;
  border-radius: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
}

.editMobileVerifiedContainer > button {
  background: transparent;
  color: #e9117c;
  font-size: medium;
  border: none;
  outline: none;
  font-weight: 600;

  position: absolute;
  right: 20px;
  top: 10px;
}

.editMobileVerifiedContainer > .editMobileHeader {
  margin-top: 40px;
  padding: 2%;
  text-align: center;
}

.editMobileVerifiedContainer > .editMobileText {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4em;
  line-height: 29px;

  color: #b4b9e4;
  padding-bottom: 2%;
  text-align: center;
}

.MobileEditMobileText > .MobileverifiedCloseButton{
background: #E9117C;

border: none;
border-radius: 10px;

font-family: 'Helvetica';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 23px;
/* identical to box height */

width: 200px;
height: 50px;

color: #FFFFFF;
}


/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
.editMobileVerifiedContainer {
  width: 85%;
  height: fit-content;
  background-color: #ffffff;
  border: none;
  border-radius: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
}

.desktopMobileVerifiedText{
 display: none;
}

.MobileEditMobileText{
  margin-bottom: 30px;
}

.MobileverifiedCloseButton{
  width: 119px;
height: 43px;
left: 86px;
top: 116px;

background: #E9117C;
border-radius: 10px;

font-family: 'Helvetica';
font-style: normal;
font-weight: 700;
font-size: 1em;
line-height: 14px;

color: #FFFFFF;

border: none;
outline: none;
}
}