.SubsAccessInfo {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 16px;

  text-align: left;
  color: #e9117c;
  padding-top: 15px;
}

.ShowAllBtn {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: .9em;
  line-height: 16px;

  text-align: left;
  color: #e9117c;
  padding-top: 15px;
}

.StarCheckBoxsList > .inputCheckBox > label {
    font-size: 14px;
    /* transform: translate(10px, 10px); */
    
}

.unSubscribeCheckList > .inputCheckBox > label {
     font-size: 14px;
    /* transform: translate(10px, 10px); */
    color: transparent;
    text-shadow: 0 0 5px rgb(0 0 0 / 50%);
}


/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .MobilePartnerPreferenceMediumOption {
    width: 300px;
    height: 35px;
  }

  .StarCheckBoxsList {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
