.policy {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 3% 5%;
}

.headerRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-bottom: 30px;
}

.headerRow > h4 {
  color: #e9117c;
  font-family: "Helvetica";
  font-weight: 500;
  font-size: 27px;
  text-align: center;
  padding: 30px;
  margin: 0px;
}

.descriptionRow > .para {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 19.5px;
  letter-spacing: 0.5px;
  line-height: 1.7em;

  color: #494949;
}

.informationRow,
.permissionsRow,
.tracksRow,
.MembersRow,
.sharesRow,
.longDoRow,
.securityRow,
.changePrivacyRow,
.AddressRow {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-top: 2%;
}

.informationRow > h6,
.permissionsRow > h6,
.tracksRow > h6,
.MembersRow > h6,
.sharesRow > h6,
.longDoRow > h6,
.securityRow > h6,
.changePrivacyRow > h6,
.AddressRow > h6 {
  color: #494949;
  font-family: "Helvetica";
  font-weight: 700;
  font-size: 19.5px;
  letter-spacing: 0.5px;
  margin: 0px;
  padding: 0px;
}

.informationRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 19.5px;
  letter-spacing: 0.5px;
  line-height: 1.7em;

  color: #494949;
  margin: 0px;
  padding: 0px;
}

.permissionsRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 1.7em;

  color: #494949;
  margin: 0px;
  padding: 0px;
}

.tracksRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 19.5px;
  letter-spacing: 0.5px;
  line-height: 1.7em;

  color: #494949;
  margin: 0px;
  padding: 0px;
}

.MembersRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 19.5px;
  letter-spacing: 0.5px;
  line-height: 1.7em;

  color: #494949;
  margin: 0px;
  padding: 0px;
}

.sharesRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 19.5px;
  letter-spacing: 0.5px;
  line-height: 1.7em;

  color: #494949;
  margin: 0px;
  padding: 0px;
}

.longDoRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 19.5px;
  letter-spacing: 0.5px;
  line-height: 1.7em;

  color: #494949;
  margin: 0px;
  padding: 0px;
}

.securityRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 19.5px;
  letter-spacing: 0.5px;
  line-height: 1.7em;

  color: #494949;
  margin: 0px;
  padding: 0px;
}

.changePrivacyRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 19.5px;
  letter-spacing: 0.5px;
  line-height: 1.7em;

  color: #494949;
  margin: 0px;
  padding: 0px;
}

.AddressRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 1.7em;

  color: #494949;
  margin: 0px;
  padding: 0px;
}

.paraGrp {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 1.7em;

  color: #494949;
  margin: 0px;
  padding: 0px;
}

.paraGrp > .paraHead {
  margin: 0px;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .searchBoxTapBar > .PageHeading {
    display: inline;
  }

  .policy {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    padding: 3% 5% 5%;
  }

  .headerRow {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    padding-bottom: 20px;
  }

  .headerRow > h4 {
    color: #e9117c;
    font-family: "Helvetica";
    font-weight: 600;
    font-size: 1.4em;
    text-align: center;
    padding: 0px;
    margin: 0px;
    padding-top: 40px;
  }

  .descriptionRow > .para {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    letter-spacing: 0.5px;
    line-height: 1.7em;
    text-align: justify;

    color: #494949;
    padding: 0px 20px 0px 12px;
  }

  .informationRow > h6,
  .permissionsRow > h6,
  .tracksRow > h6,
  .MembersRow > h6,
  .sharesRow > h6,
  .longDoRow > h6,
  .securityRow > h6,
  .changePrivacyRow > h6,
  .AddressRow > h6 {
    color: #494949;
    font-family: "Helvetica";
    font-weight: 700;
    font-size: 17.5px;
    letter-spacing: 0.5px;
    margin: 0px;
    padding: 10px 0px 0px 12px;
  }

  .informationRow > p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    letter-spacing: 0.5px;
    line-height: 1.7em;
    text-align: justify;

    color: #494949;
    margin: 0px;
    padding: 0px 0px 0px 12px;
  }

  .permissionsRow > p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    letter-spacing: 0.5px;
    line-height: 1.7em;
    text-align: justify;

    color: #494949;
    margin: 0px;
    padding: 0px 0px 0px 12px;
  }


  .paraGrp > p{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 100;
    font-size: .7em;
    letter-spacing: 0.5px;
    line-height: 1.7em;
    text-align: justify;

    color: #494949;
    margin: 0px;
    padding: 0px 0px 0px 12px;
  }

  .tracksRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.7em;
  text-align: justify;

  color: #494949;
  margin: 0px;
  padding: 0px 0px 0px 12px;
}

.MembersRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.7em;
  text-align: justify;

  color: #494949;
  margin: 0px;
  padding: 0px 0px 0px 12px;
}

.sharesRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.7em;
  text-align: justify;

  color: #494949;
  margin: 0px;
  padding: 0px 0px 0px 12px;
}

.longDoRow > p {
   font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.7em;
  text-align: justify;

  color: #494949;
  margin: 0px;
  padding: 0px 0px 0px 12px;
}

.securityRow > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.7em;
  text-align: justify;

  color: #494949;
  margin: 0px;
  padding: 0px 0px 0px 12px;
}

.changePrivacyRow > p {
   font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.7em;
  text-align: justify;

  color: #494949;
  margin: 0px;
  padding: 0px 0px 0px 12px;
}

.AddressRow > p {
   font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.7em;
  text-align: justify;

  color: #494949;
  margin: 0px;
  padding: 0px 0px 0px 12px;
}
}
