 .FilterboxPartnerFieldRange {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 26px;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .FilterboxPartnerFieldRange {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 35px;
}
}
