.tearmsAndConditionsHeaderRow {
  padding: 0px;
}

.ConditionsRow {
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 0% 5% 5% 5%;
}

p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;

  color: #494949;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 24px;
    text-align: justify;

    color: #494949;
    padding: 0px 0px 0px 4px;
  }
}
