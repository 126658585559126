.homeMatchesContainerBox {
  width: 870px;
  margin-top: 45px;
  border: 1px solid rgba(158, 158, 158, 0.67);
  border-radius: 25px;
  padding: 50px;
}

.homeMatchesHeader {
  display: flex;
  justify-content: space-between;
}

.homeMatchesHeader1 {
  font-size: 1.5em;
  font-weight: 500;
}

.homeMatchesHeader2 {
  margin-bottom: 20px;
}
.homeSeeAll {
  color: #e9117c;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}
.homeSeeAll:hover {
  color: #e9117c;
}
.newMatchesProfile {
  margin-top: 25px;
}

/* .profilePersonsRow{
    display: flex;
    flex-direction: row;
    gap: 45px;
  } */

.homeMatchesProfile > .DesktopRow {
  display: inline;

  display: flex;
  flex-direction: row;
  /* flex-wrap: nowrap; */
  gap: 45px;
}

.homeMatchesProfile > .MobileRow {
  display: none;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .homeMatchesContainerBox {
    width: 100%;
    margin-top: 35px;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
  }

  .homeMatchesHeader {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .homeMatchesHeader1 {
    font-size: 1.3em;
    font-weight: 600;
  }

  .homeMatchesHeader2 {
    display: none;
  }

  .homeSeeAll {
    color: #e9117c;
    font-size: 1em;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
  }

  /*   
  .profilePersonsRow{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 45px;
  } */

  .homeMatchesProfile > .DesktopRow {
    display: none;
  }

  .homeMatchesProfile > .MobileRow {
    display: inline;
    margin-top: 8%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 45px;
  }
}
