.successFormContiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 800px;

  overflow-y: hidden;
}

.SuccessHeader {
  color: #e9117c;
  font-size: 1.5em;
  font-weight: 500;
  padding: 100px 0px 30px;
  margin-top: 80px;
}

.succussfullPageButton {
  width: 200px;
}

.successButton {
  margin-top: 50px;
  text-align: center;
  height: 50px;

  /* margin-left: 10.5rem; */
}

.successButton > button {
  height: 50px;
  width: 290px;
  outline: none;
  border: none;
  background-color: #e9117c;
  color: #fff;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .successFormContiner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;

    overflow-y: hidden;
  }

  .SuccessHeader {
    color: #e9117c;
    font-size: 1.05em;
    font-weight: 500;
    padding: 100px 0px 30px;
    margin-top: -100px;
  }

  .successButton {
  margin-top: 20px;
  }

  .successButton > button {
  height: 50px;
  width: 190px;
  outline: none;
  border: none;
  background-color: #e9117c;
  color: #fff;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
}
