.IndividualSubscribeBlack {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(63, 54, 54, 0.8);
  /* background-color: rgba(0, 0, 0, 0.4); */
  height: 100%;
  z-index: 1;
}
.Individualpage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Individaulpage-Head-Section {
  display: flex;
  justify-content: center;
  align-items: revert;
  flex-direction: row;
  padding: 100px 250px 50px 250px;
  gap: 30px;
}

.Individaulpage-Head-Section-Left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.Individaulpage-Input > span > i {
  font-size: 20px;
  height: 50px;
  width: 50px;
}
.Individaulpage-Input > span > a {
  text-decoration: none;
  color: #e9117c;
}
.Individaulpage-Input > a {
  text-decoration: none;
  color: #e9117c;
}
.Individaulpage-Head-Section-Image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.Individaulpage-Head-Section-Main-Image > img {
  width: 266px;
  height: 242px;
  border-radius: 18px;
}
.Individaulpage-Head-Section-Small-Image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
}
.Individaulpage-Head-Section-Small-Image img:first-child {
  filter: blur(0);
}
.Individaulpage-Head-Section-Small-Image > img {
  width: 50px;
  height: 50px;
  border-radius: 7px;
  filter: blur(1px);
}
.Individaulpage-Head-Section-Matches > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #e9117c;
}

.Individaulpage-Head-Section-Matches > span {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #434242b5;
}

.Individaulpage-Head-Section-Right-Heading {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.Individaulpage-Head-Section-Right-Status > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: #e9117c;
  width: 200px;
}
.Individaulpage-Head-Section-Right-Status > b {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #04923c;
}

.Individaulpage-Head-Section-Right-Name > span {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #e9117c;
}
.Individaulpage-Head-Section-Matches > span > span {
  color: #04923c;
}

.Individaulpage-Head-Section-Right-Name > span > b {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #9e9e9e;
}
.Individaulpage-Head-Section-Right-Status {
  display: flex;
  /* justify-content:space-between; */
  align-items: center;
  flex-direction: row;
}
.Individaulpage-Head-Section-Right-Status > b > i {
  font-size: 7px;
}

.Buttons > div > button {
  border: none;
  outline: none;
  background-color: #59cafc;
  color: #fff;
  padding: 9px;
  border-radius: 7px;
  font-size: 15px;
}
.Buttons {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  gap: 10px;
}
.Chatnow > button {
  width: 140px;
  height: 40px;
}
.Chatnow > button > img {
  height: 20px;
  width: 20px;
}

.Share > button {
  width: 52px;
  height: 40px;
}

.Share img {
  height: 24px;
  width: 24px;
}

.Individaulpage-Head-Section-Right-Verification {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding-top: 10px;
  column-gap: 5px;
}

.Individaulpage-Head-Section-Right-Verification > span {
  font-family: "Helvetica";
  font-weight: 400;
  font-size: 12px;
  /* line-height: 14px; */
  text-align: center;
  color: #04923c;
}

.Individaulpage-Head-Section-Right-Verification > img {
  height: 16px;
  width: 16px;
}

.Caterogy {
  display: flex;
  justify-content: start;
  align-self: start;
  flex-direction: row;
  /* gap: 200px; */
  padding-top: 20px;
}

.Caterogy > div > div > img {
  height: 21px;
  width: 21px;
}
.Caterogy > div > div > span {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: rgba(67, 66, 66, 0.71);
  padding: 10px;
}

.Column-1,
.Column-2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 500px;
}

.Row-3 > a {
  font-family: "Helvetica";
  color: #e9117c;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
}

.Content-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 965px;
  height: 107px;
  border: 1.00361px solid rgba(158, 158, 158, 0.24);
  border-radius: 15.0542px;
  margin-top: 30px;
  gap: 50px;
}

.Content-box > div > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: rgba(67, 66, 66, 0.71);
}

.Shortlisted > button {
  width: 140px;
  height: 40px;
  background: #e9117c;
  border-radius: 7px;
  border: none;
  color: #ffffff;
}
.Shortlisted > button > i {
  padding: 5px;
}

.Individual-Page-Show-Interest > button {
  width: 140px;
  height: 40px;
  background: #e9117c;
  border-radius: 7px;
  border: none;
  color: #ffffff;
}

.Individual-Page-Block {
  width: 965px;
  text-align: right;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ee0909;
  padding: 10px;
  cursor: pointer;
}

.Individaulpage-Mid-Section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 150px 0px 150px;
  gap: 50px;
}
.Individaulpage-About {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid rgba(158, 158, 158, 0.24);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 1203px;
  height: 191px;
  padding: 50px;
}

.Individaulpage-Mid-Section > div > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  text-align: left;
  color: #e9117c;
  padding-bottom: 30px;
}

.Individaulpage-About > span {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: rgba(67, 66, 66, 0.71);
}

.Individaulpage-BasicDetails {
  display: flex;
  align-items: center;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid rgba(158, 158, 158, 0.24);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 1203px;
  height: fit-content;
  padding: 50px;
}

.Individaulpage-Table {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}
.Individaulpage-Lable,
.Individaulpage-Input {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 200px;
}
.Individaulpage-Table > div > span {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: rgba(73, 73, 73, 0.9);
}

.Individaulpage-Religious-Details,
.Individaulpage-Professional-Details,
.Individaulpage-Location-Details,
.Individaulpage-Family-Details,
.Individaulpage-Profile-Details {
  display: flex;
  align-items: center;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid rgba(158, 158, 158, 0.24);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 1203px;
  padding: 50px;
}
.Individaulpage-Religious-Details {
  height: fit-content;
}

.Individaulpage-Professional-Details {
  height: fit-content;
}

.Individaulpage-Location-Details {
  height: fit-content;
}

.Individaulpage-Family-Details {
  height: fit-content;
}

.Individaulpage-Table > .Individaulpage-Input > .profileContactNumber {
    color: transparent;
    text-shadow: 0 0 5px rgb(0 0 0 / 90%);
    font-size: 1em;
}

.Input-Group-1 {
  /* display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: 150px; */

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.Input-Group-2 {
  /* display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap:150px; */

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.Individaulpage-Profile-Details {
  height: 264px;
}

.ProfileVerificationCard {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  gap: 20px;
}

.IndividualCheckTickIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.IndividualCheckTickIcon > i {
  color: #04923c;
  font-size: large;
}

.Individaulpage-Profile-Condtion > span {
  /* text-align: right; */
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(73, 73, 73, 0.4);

  /* display: block; */
}
.Individaulpage-Profile-Condtion {
  width: 100%;
  text-align: right;
  margin-left: 50%;
  width: 535px;
}

.Individaulpage-Profile-Matches > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: #e9117c;
}
.div1 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
}

.Individaulpage-Profile-Matches-Image1 > img {
  width: 115px;
  height: 115px;
  border-radius: 50%;
}
.Individaulpage-Profile-Matches-Image2 > img {
  width: 115px;
  height: 115px;
  border-radius: 50%;
}
.Individaulpage-Profile-Matches {
  /* background: #ffffff; */
  border: 1px solid rgba(158, 158, 158, 0.24);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 1203px;
  height: 2551px;
  padding: 50px 100px 0px 100px;
  margin-bottom: 100px;
}
.div1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}
.Individaulpage-Profile-Matches-Context {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: rgba(73, 73, 73, 0.9);
}
.Individaulpage-Profile-Matches-Context > p > span {
  color: #04923c;
}
.Individaulpage-Profile-Matches-Parents > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: rgba(67, 66, 66, 0.71);
  padding-top: 60px;
}

.Individaulpage-Profile-Matches-Parents > span {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: rgba(73, 73, 73, 0.9);
}

.Individaulpage-Profile-Matches-Table {
  margin-top: 30px;
  width: 100%;
}
.Individaulpage-Profile-Matches-Label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 20px;
  border-top: 1.5px solid rgba(158, 158, 158, 0.31);
  width: 100%;
}
.Individaulpage-Profile-Matches-Category {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  padding-top: 30px;
}
.Individaulpage-Profile-Matches-Category > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: rgba(67, 66, 66, 0.71);
  margin: 0px;
}
.Individaulpage-Profile-Matches-Category > span {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: rgba(73, 73, 73, 0.9);
}
.Individaulpage-Profile-Matches-Right > span > i {
  padding-top: 30px;
  color: #04923c;
  font-size: 45px;
}
.Individaulpage-Profile-Matches-Wrong > span > i {
  padding-top: 30px;
  color: #ee0909;
  font-size: 45px;
}
.Individaulpage-Profile-Matches-NA {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 30px;
}
.Individaulpage-Profile-Matches-NA > p {
  color: #ee0909;
}

.macthesNoProfilePicIndiviudual {
  background-image: url("../../assets/images/Matches/requestProfilePic.png");
  background-size: cover;
  border-radius: 15px;
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  padding: 1.9rem;
  text-align: center;
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* filter: blur(7px); */
  z-index: -1;
  cursor: pointer;
}

.MobileView-Individaulpage-Head-Section {
  display: none;
}

.Individaulpage-Table > .Individaulpage-Input > .Individaulpage_organizationName {
    color: transparent;
    text-shadow: 0 0 5px rgb(0 0 0 / 90%);
    font-size: 1em;
}

.middleSubscriptionLine, .SubcriptionContactNumber {
    margin-top: 15px;
    font-size: 1em;
}

.SubscriptionLink{
  color: #e9117c;
  text-decoration: none;
}


/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
  .Individaulpage-About {
    height: 200px;
  }

  .Individaulpage-BasicDetails {
    height: 540px;
  }

  .Individaulpage-Religious-Details {
    height: 362px;
  }
  .Individaulpage-Professional-Details {
    height: 380px;
  }
  .Individaulpage-Location-Details {
    height: 300px;
  }
  .Individaulpage-Family-Details {
    height: 520px;
  }
  .Individaulpage-Profile-Details {
    height: 300px;
  }
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .Individaulpage-Head-Section {
    padding: 58px 300px 50px 170px;
  }
  .Individaulpage-Head-Section-Right {
    width: 520px;
  }
  .Individaulpage-Head-Section-Main-Image > img {
    width: 150px;
    height: 150px;
  }
  .Buttons > div > button {
    font-size: 14px;
  }
  .Caterogy > div > div > span {
    font-size: 14px;
  }
  .Chatnow > button {
    width: 130px;
    height: 40px;
  }

  .Content-box {
    height: 90px;
    width: 620px;
    padding: 20px;
    gap: 20px;
  }

  .Content-box > div > p {
    font-size: 14px;
  }
  .Shortlisted > button {
    width: 130px;
    font-size: 14px;
  }
  .Individual-Page-Show-Interest > button {
    width: 140px;
    font-size: 14px;
  }
  /* .Individaulpage-Head-Section-Matches > p {

  }

  .Individaulpage-Head-Section-Matches > span {
    
  } */
  .Individual-Page-Block {
    font-size: 14px;
    width: 620px;
  }
  .Individaulpage-About {
    width: 920px;
    height: 191px;
  }
  .Individaulpage-About > span {
    font-size: 16px;
  }
  .Individaulpage-Mid-Section > div > p {
    font-size: 22px;
    padding-bottom: 0px;
  }
  .Individaulpage-BasicDetails {
    height: 500px;
    width: 920px;
  }
  .Individaulpage-Mid-Section > div > p {
    font-size: 20px;
    padding-bottom: 0px;
  }
  .Individaulpage-Table > div > span {
    font-size: 16px;
  }
  .Individaulpage-Religious-Details {
    height: 362px;
    width: 920px;
  }
  .Individaulpage-Professional-Details {
    height: 362px;
    width: 920px;
  }
  .Individaulpage-Location-Details {
    height: 250px;
    width: 920px;
  }
  .Individaulpage-Family-Details {
    height: 480px;
    width: 920px;
  }
  .Individaulpage-Profile-Details {
    height: 280px;
    width: 920px;
  }
  .Individaulpage-Profile-Condtion {
    margin-left: 35%;
  }
  .Individaulpage-Profile-Matches {
    width: 920px;
  }

  .Individaulpage-Profile-Matches-Parents > span {
    font-size: 16px;
  }
  .Individaulpage-Profile-Matches-Category > p {
    font-size: 16px;
  }
  .Individaulpage-Profile-Matches-Image2 > img {
    width: 80px;
    height: 80px;
  }
  .Individaulpage-Profile-Matches-Image1 > img {
    width: 80px;
    height: 80px;
  }
  .Individaulpage-Profile-Matches-Category > span {
    font-size: 16px;
  }
  .Individaulpage-Profile-Matches-Right > span > i {
    font-size: 30px;
  }
  .Individaulpage-Profile-Matches-Wrong > span > i {
    font-size: 30px;
  }
  .Individaulpage-Profile-Matches-NA > span > img {
    height: 30px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .div1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 17px;
  }

  .Individualpage {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 20px;
}
  .Individaulpage-Head-Section {
    display: none;
  }

  .MobileView-Individaulpage-Head-Section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .IndividualUserDetails {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
    gap: 20px;
    padding: 8% 0px;
  }

  .userProfileImageDivision {
    width: 36%;
  }

  .userInformationDivision {
    width: 50%;
  }

  .userProfileImage {
    width: 100%;
  }

  .userProfileImage > img {
    width: 100%;
  }

  .CheckingUserVerification {
    width: 100%;
    background: #04923c;
    border-radius: 15px 15px 0px 0px;
    /* padding: 3% 0% 3%; */

    font-family: "Helvetica";
    font-style: normal;
    font-weight: lighter;
    font-size: 0.7em;
    letter-spacing: 0.5px;
    line-height: 25px;
    text-align: center;

    color: #ffffff;

    margin-bottom: -2px;
  }

  .CheckingUserVerification > i {
    padding-right: 5px;
  }

  .ProfilePictureSliderDivision {
    width: 100%;
    background: #04923c;
    border-radius: 0px 0px 15px 15px;
    padding: 0% 0% 0%;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: lighter;
    font-size: 1.5em;
    letter-spacing: 0.5px;
    /* line-height: 25px; */
    text-align: center;

    color: #ffffff;
  }

  .userInformationDivision > .userName {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;

    padding: 5px 0px 10px;

    color: #e9117c;
  }

  .userInformationDivision > .userName > .OnlineStatus {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 0.9em;
    line-height: 11px;
    text-align: center;

    padding-left: 10px;
    color: #04923c;
  }

  .userInformationDivision > .profilePersonalDetails {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 0.95em;
    line-height: 15px;
    letter-spacing: 0.1px;

    color: rgba(67, 66, 66, 0.71);
  }

  .profilePersonalDetails > .info {
    padding: 5px 0px;
  }

  .info > .ViewHoroScopeLink {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 0.8em;
    letter-spacing: -0.2px;
    line-height: 11px;

    color: #e9117c;
  }

  .MatchingScore {
    padding-top: 8px;
  }

  .MatchingScore > .MatchScoreLabel {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 0.95;
    line-height: 15px;
    padding-right: 15px;

    color: #e9117c;
  }

  .MatchingScore > .MatchScoreValue {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 1.1em;
    line-height: 16px;

    color: #04923c;
  }

  .MatchScoreValue > .FixedValue {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    line-height: 16px;

    color: #7a7979;
  }

  .MatchingScore > .MatchScoreValue > i {
    font-size: larger;
    padding-left: 8px;
    color: #e9117c;
    /* margin-top: 5px; */
  }

  .MatchScoreValue > img {
    width: 20px;
    height: 20px;
    rotate: 3deg;
    margin-left: 5px;
    margin-bottom: 5px;
    background-color: #e9117c;
    border-radius: 50px;
    padding: 3px 4px;
  }

  .individualProfileDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .UserIdDetails {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 16px;
    text-align: center;

    color: #9e9e9e;

    padding: 5% 0px;
  }

  .UserIdDetails > .dashSymbol {
    margin-top: 10px;
  }

  .UserIdDetails > .UserProfileID {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 550;
    font-size: 1em;
    line-height: 16px;
    text-align: center;

    color: #e9117c;
  }

  .likeHisProfileSection {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 16px;
    /* identical to box height */

    text-align: center;
    padding: 5% 0%;
    width: 95%;

    color: rgba(67, 66, 66, 0.71);
    border: 2px solid #e9117c;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .likeHisProfileLabel_Connect {
    color: #e9117c;
    font-weight: 600;
  }

  .ProfileHisButtons {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .ProfileHisButtons > .chatbtn {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 0.9em;
    line-height: 14px;
    text-align: center;

    color: #ffffff;

    background: #59cafc;
    border-radius: 7px;
    border: none;
    outline: none;
    padding: 5px 10px;

    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }

  .ProfileHisButtons > .shortListbtn {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 0.9em;
    line-height: 14px;
    text-align: center;

    color: #ffffff;

    border: none;
    outline: none;
    padding: 5px 10px;

    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    background: #e9117c;
    border-radius: 7px;
  }

  .ProfileHisButtons > .chatbtn > i {
    font-size: 1.5em;
    font-weight: lighter;
  }

  .share_block_profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;

    padding-top: 6%;
  }

  .shareProfile {
    border: 2px solid #e9117c;
    border-radius: 10px;
    padding: 10px 30px;

    display: flex;
    align-items: center;
    gap: 10px;
  }

  .blockProfile {
    border: 2px solid #e9117c;
    border-radius: 10px;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .shareProfile > i {
    color: #fff;
    background-color: #59cafc;
    padding: 3px;
    font-size: 1.5em;
    border-radius: 50px;
  }

  .blockProfile > i {
    color: #fff;
    background: #ee0909;
    border-radius: 10px;
    padding: 3px;
    font-size: 1.5em;
    border-radius: 50px;
  }

  .shareProfile > .ProfileLabel,
  .blockProfile > .ProfileLabel {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 16px;
    text-align: center;

    color: #e9117c;
  }

  .Individaulpage-Mid-Section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
    gap: 50px;
    width: 95%;
  }

  .Individaulpage-About {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    border: 1px solid rgba(158, 158, 158, 0.24);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 15px;
    width: 100%;
    height: fit-content;
    text-align: justify;
    padding: 8% 5%;
    margin-top: 12%;
  }

  .Individaulpage-About > span {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 22px;
    color: rgba(67, 66, 66, 0.71);
  }

  .Individaulpage-Mid-Section > div > p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    text-align: left;
    color: #e9117c;
    padding: 0px;
    margin-bottom: 0px;
  }

  .Individaulpage-BasicDetails {
    display: flex;
    align-items: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;

    border: 1px solid rgba(158, 158, 158, 0.24);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 15px;
    width: 100%;
    height: fit-content;
    padding: 8% 5%;
  }

  .Individaulpage-Table > .Individaulpage-Lable > span {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 21px;
    width: 150px;
    text-align: left;
    color: rgba(73, 73, 73, 0.9);
  }

  .Individaulpage-Table > .Individaulpage-Input {
    margin-top: 0px;
  }

  .Individaulpage-Table > .Individaulpage-Input > span {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 21px;
    width: 150px;
    text-align: left;
    color: rgba(73, 73, 73, 0.9);
    width: fit-content;
  }

  .Individaulpage-Table > .Individaulpage-Input > .profileContactNumber {
    color: transparent;
    text-shadow: 0 0 5px rgba(0.2, 0, 0, 0.9);
    font-size: 0.8em;
  }

  .SubcriptionContactNumber {
    width: 100%;
    text-align: center;
    font-size: 0.9em;
  }

  .SubcriptionContactNumber > .SubscriptionLink {
    color: #e9117c;
    text-decoration: none;
  }

  .Individaulpage-Religious-Details,
  .Individaulpage-Professional-Details,
  .Individaulpage-Location-Details,
  .Individaulpage-Family-Details,
  .Individaulpage-Profile-Details {
    display: flex;
    align-items: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;

    border: 1px solid rgba(158, 158, 158, 0.24);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 15px;
    width: 100%;
    height: fit-content;
    padding: 8% 5%;
  }

  .Individaulpage-Lable,
.Individaulpage-Input {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 150px;
}

  .Individaulpage-Profile-Details {
    gap: 30px;
  }

  .Individaulpage-Table
    > .Individaulpage-Input
    > .Individaulpage_organizationName {
    color: transparent;
    text-shadow: 0 0 5px rgba(0.2, 0, 0, 0.9);
    font-size: 1em;
  }

  .Annual_IncomeRow {
    padding-top: 30px;
  }

  .middleSubscriptionLine {
    position: relative;
    width: 100%;
  }

  .forSubcriptionTag {
    width: 100%;
    text-align: center;
    font-size: 0.9em;

    position: absolute;
    bottom: 55px;
  }

  .forSubcriptionTag > .SubscriptionLink {
    color: #e9117c;
    text-decoration: none;
  }

  .Individaulpage-Profile-Condtion {
    width: 100%;
    text-align: center;
    margin-left: 0%;
    width: 100%;
  }

  .Individaulpage-Profile-Condtion > span {
    font-weight: 400;
    font-size: 9px;
    line-height: 5px;
    color: rgba(73, 73, 73, 0.4);
  }

  .Individaulpage-Profile-Matches {
    border: 1px solid rgba(158, 158, 158, 0.24);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 15px;
    width: 100%;
    height: fit-content;
    padding: 8% 5%;
    margin-bottom: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Individaulpage-Profile-Matches > .IndividualProfileMatchesMainHeading {
    text-align: center;
    font-size: 1.1em;
    font-weight: 600;
    padding-bottom: 20px;
  }

  .Individaulpage-Profile-Matches-Image1 > img,
  .Individaulpage-Profile-Matches-Image2 > img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }

  .Individaulpage-Profile-Matches-Context > p {
    font-size: 0.9em;
    font-weight: 600;
    text-align: center;
  }

  .Individaulpage-Profile-Matches-Parents > p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 23px;
    color: rgba(67, 66, 66, 0.71);
    padding-top: 60px;

    text-align: center;
  }

  .Individaulpage-Profile-Matches-Parents > .headerParentsParagraph {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 21px;
    color: rgba(73, 73, 73, 0.9);
    text-align: justify;
  }

  .Individaulpage-Profile-Matches-Category {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
    padding-top: 30px;
    width: 85%;
  }

  .Individaulpage-Profile-Matches-Category > p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 23px;
    color: rgba(67, 66, 66, 0.71);
    margin: 0px;
  }

  .Individaulpage-Profile-Matches-Category > span {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 21px;
    color: rgba(73, 73, 73, 0.9);
  }

  .Individaulpage-Profile-Matches-Right > span > i {
    padding-top: 30px;
    color: #04923c;
    font-size: 30px;
  }

  .Individaulpage-Profile-Matches-Wrong > span > i {
    padding-top: 30px;
    color: #ee0909;
    font-size: 30px;
  }

  .NA-division-labels {
    display: flex;
    flex-direction: column;
    width: 85%;
  }

  .Individaulpage-Profile-Matches-Label > .NA-division {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .Individaulpage-Profile-Matches-NA > div {
    width: fit-content;
    display: inline;
  }

  .Individaulpage-Profile-Matches-NA > div > img {
    width: 30px;
    padding-top: 30px;
    color: #04923c;
    font-size: 30px;
  }

  .Individaulpage-Profile-Matches-NA {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0px;
  }
}
