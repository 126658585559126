/* EditPageBody */
.EditPageBody {
  background-color: antiquewhite;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 6%;
}

/* EditPageBody_profileCard  */

.EditPageBody_profileCard {
  background-color: white;
  border-radius: 25px;
  width: 80%;
  margin: 5% 0%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  padding: 1.8% 1.8%;

  /* Flex */
  display: flex;
  flex-direction: row;
}

.EditPageBody_profilePhotoCard {
  height: 250px;
  width: 210px;
  border-radius: 25px;
  overflow: hidden;
  background-color: rgba(229, 229, 229, 0.69);
  padding: 0;
  position: relative;
}

.PersonalDetails_card {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.EditPageBody_profilePhotoCard > img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.profilePhotoCard_EditPhotoLink {
  background-color: rgba(229, 229, 229, 0.69);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 17px;
  text-align: center;

  color: #e9117c;
  padding: 5% 0%;
}

.profilePhotoCard_EditPhotoLink > a {
  color: #e9117c;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.95em;
  margin-bottom: 10px;
  cursor: pointer;
}

.EditPageBody_Profile_details {
  margin-top: 5px;
  font-size: 1.1em;
  color: rgba(73, 73, 73, 0.55);
  font-weight: normal;
  padding: 0% 2.5%;
}

.EditPageBody_Profile_details > h4 {
     font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    padding: 5px 0px;
    color: #494949;
    margin-top: -10px;
}

.EditPageBody_Profile_details > p {
  /* margin-bottom: 9px; */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  color: rgba(73, 73, 73, 0.55);
  padding: 1px 0px 1px 0px;
}

#Profile_details_CreatedFor {
  margin-top: -7px;
}

.profile_verification_div{
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.profile_verification_div > div > img {
  height: 19px;
  width: 12px;
  margin-top: -5px;
}



.profile_verification_div > div > span {
  font-size: 0.8em;
  padding-left: 5px;
  font-weight: 400;
}

.profile_verification_div > div > .fa-circle-check {
  padding-left: 15px;
  color: #04923c;
  font-size: 0.85em;
}

.profile_verification_div > div > .Verified_span {
  color: #04923c;
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.profile_verification_div > div > .Edit_Link {
  color: #e9117c;
  font-size: 0.9em;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-decoration: none;
  margin-top: 2px;
  margin-left: 5px;
}

/* PersonalDetails_Bar */
.PersonalDetails_Bar {
  background: linear-gradient(90.08deg, #e9117c 0.45%, #ffecd2 128.32%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  width: 100%;
}

.PersonalDetails_Bar > div {
  width: 81.1%;
}

.PersonalDetails_Bar > div > span {
  color: #ffffff;
  font-size: 1.9em;
}

/* Edit Button */
.EditPage_EditButton {
  color: #e9117c;
  background: transparent;
  font-weight: 400;
  font-size: 1em;
  border: none;
  outline: none;
  position: absolute;
  top: 17px;
  right: 25px;
}

.EditPage_EditButton > i {
  font-size: 0.6em;
  padding: 0px 5px 0px 0px;
}

/* Edit Mobile number in Popup */

.EditMobileNumberPopupOn {
  background: rgba(63, 54, 54, 0.8);
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 100;
  height: 100%;
  margin-top: 70px;
  z-index: 20;
  overflow-y: hidden;
}

.MobileVerificationPopupOn {
  background: rgba(63, 54, 54, 0.8);
  position: absolute;
  top: 1.1%;
  left: 0;
  right: 0;
  bottom: 100;
  height: 100%;
  margin-top: 70px;
  z-index: 1;
  overflow-y: hidden;
}

.EditMobileNumberPopupOn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MobileVerificationPopup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.EditMobileNumberPopupOn > .editMobileContainer {
  width: 70%;
  height: fit-content;
  background-color: #ffffff;
  border: none;
  border-radius: 20px;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;

  position: relative;
}

.EditMobileNumberPopupOn > .editMobileContainer > button {
  background: transparent;
  color: #e9117c;
  font-size: medium;
  border: none;
  outline: none;
  font-weight: 600;

  position: absolute;
  right: 20px;
  top: 10px;
}

.EditMobileNumberPopupOn > .editMobileContainer > .editMobileHeader {
  margin-top: 40px;
}

.EditMobileNumberPopupOn > .editMobileContainer > .editMobileText {
  margin-bottom: 20px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3em;
  line-height: 29px;

  color: #b4b9e4;
}

.EditMobileNumberPopupOn > .editMobileContainer > .editMobileInp {
  margin: 30px 20px;
}

.EditMobileNumberPopupOn > .editMobileContainer > .editMobileInp > form {
  margin: 0px;
}

/* Edit Mobile number page Varification popup */
.MobileVerificationPopupOn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.EditProfilePageEditMobileErr {
  color: red;
  position: absolute;
  top: 40px;
  left: 95px;
  font-weight: 500;
}

.MobileEditPage_Header{
    display: none;
  }

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .EditPageBody_profileCard {
    gap: 20px;
  }
  .EditPageBody_profilePhotoCard {
    height: 200px;
    width: 180px;
  }
  .profilePhotoCard_EditPhotoLink {
    font-size: 16px;
  }
  .EditPageBody_Profile_details {
    font-size: 16px;
  }

  .EditPageBody_Profile_details > h4 {
    font-size: 20px;
  }
  .EditPageBody_Profile_details > p {
    font-size: 14px;
    line-height: 11px;
  }

  .Missing_Details_contents > p {
    font-size: 20px;
    text-align: center;
  }

  
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .EditPage_Header{
    display: none;
  }

  .MobileEditPage_Header{
    display: flex;
  }

  .MobileEditPage_Header > .searchBoxTapBar{
    display: flex;
    font-weight: 600;
    letter-spacing: 0.2px;
  }

  /* EditPageBody_profileCard  */

.EditPageBody_profileCard {
  background-color: white;
  border-radius: 0px;
  width: 100%;
  margin: 0%;
  box-shadow: none;
  padding: 8% 5%;

  /* Flex */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: row;
  gap: 20px;
}


.EditPageBody_profilePhotoCard {
  height: 180px;
  width: 150px;
  border-radius: 25px;
  overflow:visible;
  background-color: rgba(229, 229, 229, 0.69);
  padding: 0;
  position: relative;
}

.PersonalDetails_card {
  box-shadow: none;
}

.EditPageBody_profilePhotoCard > img {
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 25px;
}

.profilePhotoCard_EditPhotoLink {
  background-color: transparent;
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  height: 20%;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 17px;
  text-align: center;

  color: #e9117c;
  padding: 5% 0%;
}

.profilePhotoCard_EditPhotoLink > a {
  color: #e9117c;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.95em;
  margin-bottom: 10px;
  border-bottom: 2px solid #e9117c;
  padding-bottom: 5%;
  cursor: pointer;
}


.EditPageBody_Profile_details {
  margin-top: 3px;
  font-size: 1.05em;
  color: rgba(73, 73, 73, 0.55);
  font-weight: normal;
  padding: 0% 2.5%;
}

.EditPageBody_Profile_details > h4 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 29px;
  padding: 1px 0px;
  color: #e9117c;
  margin-top: -5px;
}

.EditPageBody_Profile_details > p {
  margin-bottom: 9px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: .9em;
  line-height: 21px;

  color: rgba(73, 73, 73, 0.55);
  padding: 0px 0px 0px 0px;
}

.profile_verification_div{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.profile_verification_div > div > img {
  height: 19px;
  width: 12px;
  margin-top: -5px;
}



.profile_verification_div > div > span {
  font-size: 0.8em;
  padding-left: 5px;
  font-weight: 400;
}

.profile_verification_div > div > .fa-circle-check {
  padding-left: 0px;
  color: #04923c;
  font-size: 0.85em;
}

.profile_verification_div > div > .Verified_span {
  color: #04923c;
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.profile_verification_div > div >  .Edit_Link {
  color: #e9117c;
  font-size: 0.9em;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-decoration: none;
  margin-top: 0px;
   margin-left: 10px;
}

.PersonalDetails_Bar > div > span {
  color: #ffffff;
  font-size: 1.5em;
}

/* Mobile number popup */

.EditMobileNumberPopupOn {
  background: rgba(63, 54, 54, 0.8);
  position: absolute;
  top: 0.2%;
  left: 0;
  right: 0;
  bottom: 100;
  height: 100%;
  margin-top: 70px;
  z-index: 1;
  overflow-y: hidden;
}

.EditMobileNumberPopupOn > .editMobileContainer {
  width: 85%;
  height: fit-content;
  background-color: #ffffff;
  border: none;
  border-radius: 10px;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
  padding: 20px;
}

.EditMobileNumberPopupOn > .editMobileContainer > .editMobileHeader {
  margin-top: 40px;
  font-size: 1.3em;
}

.EditMobileNumberPopupOn > .editMobileContainer > .editMobileText {
  margin-bottom: 20px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 29px;

  color: #b4b9e4;
}

.EditMobileNumberPopupOn > .editMobileContainer > .editMobileInp {
  margin: 10px 0px;
}
}