.SubscribePage {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 80px;
  /* padding: 0px; */
}
.SubscribePage-Cards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  gap: 35px;
  margin-bottom: 80px;
}

.SubscribePage-Header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110%;
  height: 100px;
  margin-top: 100px;
  background-color: #e9117c;
}

.SubscribePage-Header {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 39px;
  text-align: center;
  color: #ffffff;
}

.SubscribePage-Card {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  height: 580px;
  width: 230px;
  background-color: #ffffff;
  border: 1px solid #b4b9e4;
  border-radius: 10px;
  gap: 40px;
}
.SubscribeCardBlue {
  background: linear-gradient(329.73deg, #59cafc 19.14%, #1e88e5 70.04%);
}
.SubscribeCardOrange {
  background: linear-gradient(307.71deg, #f5886d 12.27%, #f25a81 80.97%);
}
.SubscribeCardGreen {
  background: linear-gradient(329.73deg, #64c1a5 19.13%, #00acb3 70.04%);
}
.SubscribeCardGold {
  background: linear-gradient(329.73deg, #fab760 19.14%, #ff8a4d 70.04%);
}
.SubscribePage-Card-Package {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  gap: 5px;
}

.SubscribePage-Card-Package > p {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  margin-top: 20px;
}
.SubscribePage-Card-Package > b {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 400;
  font-size: 40.231px;
  line-height: 49px;
  color: #ffffff;
}
.SubscribePage-Card-Package > span {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.SubscribePage-Card-1-Context {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: row;
  margin: 5px;
  /* row-gap: 30px; */
}
.SubscribePage-Card-Context-Checkmark > i {
  color: #75d42a;
  margin: 10px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.SubscribePage-Card-Context-Info > span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #989eb3;
  text-align: justify;
  margin-top: 20px;
}
.SubscribePage-Card-Context-Button > button {
  height: 50px;
  width: 180px;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
  /* background: linear-gradient(307.71deg, #00B1FF 12.27%, #1E88E5 80.97%); */
  border-radius: 50px;
  border: none;
}

.DesktopSubscriptionCards {
  display: flex;
}

.MobileSubscritionCards {
  display: none;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .SubscribePage-Card-Package > p {
    font-size: 25px;
  }

  .SubscribePage-Card-Package > b {
    font-size: 32px;
  }
  .SubscribePage-Card-Context-Info > div {
    font-size: 14px;
  }
  .SubscribePage-Cards {
    width: 620px;
    gap: 10px;
  }
  .SubscribePage-Card-Context-Button {
    padding: 20px;
  }
  .SubscribePage-Card-Context-Button > button {
    height: 40px;
    width: 150px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .DesktopSubscriptionCards {
    display: none;
  }

  .MobileSubscritionCards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 25%;

    width: 100%;
  }

  .SubscribePage-Header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-top: 40px;
    background-color: #ee4e9d;

    font-size: 1.3em;
    font-weight: 200;
    letter-spacing: 1px;
  }

  .subscriptionPageTab {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    background: rgba(237, 216, 230, 0.95);
    border-radius: 50px;
    width: 90%;
    height: 55px;
    transition: 0.5s;
  }

  .openSubscriptionPageTab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    padding: 0px 30px;
    background: linear-gradient(
      180deg,
      rgba(255, 138, 77, 0.88) 0%,
      rgba(255, 28, 140, 0.7832) 100%
    );
    border-radius: 20px;
    width: 90%;
    height: fit-content;
  }

  .openSubscriptionPageTab2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    padding: 0px 30px;
    background: linear-gradient(
      180deg,
      rgba(233, 17, 124, 0.7832) 0%,
      rgba(0, 172, 179, 0.7304) 100%
    );
    border-radius: 20px;
    width: 90%;
    height: fit-content;
  }

  .openSubscriptionPageTab3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    padding: 0px 30px;
    background: linear-gradient(
      180deg,
      rgba(233, 17, 124, 0.88) 0%,
      rgba(245, 133, 110, 0.88) 100%
    );
    border-radius: 20px;
    width: 90%;
    height: fit-content;
  }

  .openSubscriptionPageTab4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    padding: 0px 30px;
    background: linear-gradient(
      180deg,
      rgba(233, 17, 124, 0.7832) 0%,
      rgba(30, 136, 229, 0.88) 100%
    );
    border-radius: 20px;
    width: 90%;
    height: fit-content;
  }

  .subscriptionPageTab > .tabHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    position: relative;
  }

  .subscriptionPageTab > .tabHeader > i {
    position: absolute;
    top: 10px;
    right: 0px;
    transition: 0.5s;
  }

  .openSubscriptionPageTab > .tabHeader > i {
    position: absolute;
    top: 5px;
    right: 0px;
  }

  .openSubscriptionPageTab > .tabHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .openSubscriptionPageTab2 > .tabHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .openSubscriptionPageTab3 > .tabHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .openSubscriptionPageTab4 > .tabHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .subscriptionPageTab > .tabHeader > .tabHeaderContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 70%;
  }

  .openSubscriptionPageTab > .tabHeader > .tabHeaderContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    width: 70%;
  }

  .openSubscriptionPageTab2 > .tabHeader > .tabHeaderContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    width: 70%;
  }

  .openSubscriptionPageTab3 > .tabHeader > .tabHeaderContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    width: 70%;
  }

  .openSubscriptionPageTab4 > .tabHeader > .tabHeaderContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    width: 70%;
  }

  .Tab1Crown {
    width: 26%;
  }

  .OpenTab1Header > .Tab1content > .Tab1Crown {
    margin-top: -5px;
    margin-right: 20px;
    width: 28%;
  }

  .OpenTab1Header > .Tab2content > .Tab1Crown {
    margin-top: -5px;
    margin-right: 38px;
    width: 22%;
  }

  .OpenTab1Header > .Tab3content > .Tab3Silver {
    margin-top: -5px;
    margin-right: 34px;
    width: 22%;
  }

  .OpenTab1Header > .Tab4content > .Tab4Free {
    margin-top: -5px;
    margin-right: 34px;
    width: 22%;
  }

  .Tab3Silver {
    width: 23%;
  }

  .Tab4Free {
    width: 23%;
  }

  .SubscribePage {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    gap: 35px;
  }

  .subscriptionPageTab > .tabHeader > .Tab1content > h6 {
    font-size: 1.25em;
    margin-top: 8px;
    margin-right: 15px;
  }

  .openSubscriptionPageTab > .tabHeader > .Tab1content > h6 {
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-top: 6px;
    margin-right: 18px;
    font-family: "Inter", sans-serif;
  }

  .subscriptionPageTab > .tabHeader > .Tab2content > h6 {
    font-size: 1.25em;
    margin-top: 8px;
    margin-right: 30px;
  }
  .openSubscriptionPageTab2 > .OpenTab1Header > .Tab2content > h6 {
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-top: 6px;
    margin-right: 45px;
    font-family: "Inter", sans-serif;
  }

  .subscriptionPageTab > .tabHeader > .Tab3content > h6 {
    font-size: 1.25em;
    margin-top: 8px;
    margin-right: 24px;
  }

  .openSubscriptionPageTab3 > .tabHeader > .Tab3content > h6 {
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-top: 6px;
    margin-right: 36px;
    font-family: "Inter", sans-serif;
  }

  .subscriptionPageTab > .tabHeader > .Tab4content > h6 {
    font-size: 1.25em;
    margin-top: 8px;
    margin-right: 30px;
  }

  .openSubscriptionPageTab4 > .tabHeader > .Tab4content > h6 {
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-top: 6px;
    margin-right: 41px;
    font-family: "Inter", sans-serif;
  }

  .subscriptionPageTab > .tabHeader {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    line-height: 17px;

    color: #e9117c;
  }

  .subscriptionPageTab > .OpenTab1Header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    line-height: 17px;

    color: #ffffff;
    margin-top: 20px;
  }

  .subscriptionCloseTabBodyCard {
    display: none;
    /* height: 0px; */
    transition: 0.5s;
  }

  .subscriptionOpenTabBodyCard {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: #ffffff;
    background-color: #ffffff;
    width: 85%;
    border-radius: 10px;
    padding: 0px 30px 0px 25px;
    margin-bottom: 15%;
    box-shadow: rgba(11, 10, 10, 0.2) 0px 4px 12px;
    transition: 0.5s;
  }

  .subscriptionWishList {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
  }

  .silverSubscriptionWishList {
    margin-top: 50px;
  }

  .subscriptionWishList > div {
    display: flex;
    flex-direction: row;
    gap: 22px;
  }

  .subscriptionWishList > div > i {
    color: #75d42a;
    font-size: 1.2em;
    transform: rotate(20deg);
  }

  .subscriptionWishList > div > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 15px;
    text-align: left;

    color: #989eb3;
  }

  .subscriptionWishList > button {
    font-family: "Helvetica", sans-serif;
    font-style: normal;
    font-weight: lighter;
    font-size: 1.2em;
    letter-spacing: 1px;
    line-height: 24px;
    padding: 10px 12px;
    margin: 10px 0px 20px 0px;

    color: #ffffff;

    border: none;
    outline: none;
    background: linear-gradient(307.71deg, #fab760 12.27%, #ff8a4d 80.97%);
    border-radius: 50px;
    transform: rotate(0.07deg);
  }

  .subscriptionWishList > .subscriptionGoldenButton {
    font-family: "Helvetica", sans-serif;
    font-style: normal;
    font-weight: lighter;
    font-size: 1.2em;
    letter-spacing: 1px;
    line-height: 24px;
    padding: 10px 12px;
    margin: 10px 0px 20px 0px;

    color: #ffffff;

    border: none;
    outline: none;
    background: linear-gradient(307.71deg, #64c1a5 12.26%, #00acb3 80.96%);
    border-radius: 50px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  .subscriptionWishList > .subscriptionSilverButton {
    font-family: "Helvetica", sans-serif;
    font-style: normal;
    font-weight: lighter;
    font-size: 1.2em;
    letter-spacing: 1px;
    line-height: 24px;
    padding: 10px 12px;
    margin: 30px 0px 20px 0px;

    color: #ffffff;

    border: none;
    outline: none;
    background: linear-gradient(307.71deg, #f5886d 12.27%, #f25a81 80.97%);
    border-radius: 50px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  
  .subscriptionWishList > .subscriptionFreeButton {
    font-family: "Helvetica", sans-serif;
    font-style: normal;
    font-weight: lighter;
    font-size: 1.2em;
    letter-spacing: 1px;
    line-height: 24px;
    padding: 10px 12px;
    margin: 30px 0px 20px 0px;

    color: #ffffff;

    border: none;
    outline: none;
    background: linear-gradient(307.71deg, #00B1FF 12.27%, #1E88E5 80.97%);
    border-radius: 60px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  .platinumBadge {
    background: linear-gradient(329.73deg, #fab760 19.14%, #ff8a4d 70.04%);
    border-radius: 10px 10px 50% 50%;
    transform: rotate(0.07deg);

    width: 90%;
    text-align: center;

    display: flex;
    flex-direction: column;
    margin-top: -2px;
  }

  .platinumBadge > .badgeheading {
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: light;
    padding-bottom: 5px;
  }

  .platinumBadge > .badgeheading {
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: light;
    padding-bottom: 0px;
  }

  .platinumBadge > .priceTag {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 100;
    font-size: 1.5em;
    letter-spacing: 1px;
    line-height: 30px;

    color: #ffffff;
    margin-bottom: -8px;
  }

  .platinumBadge > .Yeartag {
    padding-bottom: 5%;
  }

  .goldBadge {
    background: linear-gradient(329.73deg, #64c1a5 19.13%, #00acb3 70.04%);
    border-radius: 10px 10px 50% 50%;
    transform: matrix(1, 0, 0, 1, 0, 0);

    width: 90%;
    text-align: center;

    display: flex;
    flex-direction: column;
    margin-top: -1px;
  }

  .goldBadge > .badgeheading {
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: light;
    padding-bottom: -2px;
  }

  .goldBadge > .priceTag {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 100;
    font-size: 1.5em;
    letter-spacing: 1px;
    line-height: 30px;

    color: #ffffff;
    margin-bottom: -8px;
  }

  .goldBadge > .Yeartag {
    padding-bottom: 5%;
  }

  .silverBadge {
    background: linear-gradient(329.73deg, #f5886d 19.14%, #f25a81 70.04%);
    border-radius: 10px 10px 50% 50%;
    transform: matrix(1, 0, 0, 1, 0, 0);

    width: 80%;
    text-align: center;

    display: flex;
    flex-direction: column;
    margin-top: -1px;
  }

  .silverBadge > img {
    width: 110%;
    margin-left: -11px;
  }

  .silverBadge > .badgeheading {
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: light;
    padding-bottom: -2px;
  }

  .silverBadge > .priceTag {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 100;
    font-size: 1.5em;
    letter-spacing: 1px;
    line-height: 30px;

    color: #ffffff;
    margin-top: 10px;
    margin-bottom: -8px;
  }

  .silverBadge > .Yeartag {
    padding-bottom: 5%;
  }

  .freeBadge {
    background: linear-gradient(329.73deg, #59cafc 19.14%, #1e88e5 70.04%);
    border-radius: 10px 10px 50% 50%;
    transform: matrix(1, 0, 0, 1, 0, 0);

    width: 80%;
    text-align: center;

    display: flex;
    flex-direction: column;
    margin-top: -1px;
  }

  .freeBadge > img {
    width: 106%;
    margin-left: -5px;
    margin-top: 10px;
  }

  .freeBadge > .badgeheading {
    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight:normal;
    padding-bottom: -2px;
  }

  .freeBadge > .priceTag {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 100;
    font-size: 1.5em;
    letter-spacing: 1px;
    line-height: 30px;

    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 8px;
  }

  .freeBadge > .Yeartag {
    padding-bottom: 5%;
  }
}
