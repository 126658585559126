.regularSearchContainer {
  padding: 2rem 6rem;
  font-family: "Helvetica";
}
.regularSearchIndividualContainer {
  margin: 52px;
}
.regularSearchHead {
  color: #e9117c;
  font-size: 22px;
}
.regularSearchInputs,
.regularSearchLabels {
  margin-top: 20px;
  line-height: 4.8rem;
}
.regularSearchContainerIndividualInputs {
  display: flex;
  column-gap: 60px;
}
.regularSearchButton {
  margin-left: 20rem;
}
.regularSearchButton > button {
  background: #e9117c;
  width: 140px;
  padding: 0.5rem;
  color: #fff;
  border-radius: 7px;
  border: none;
  outline: none;
  font-size: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 7rem;
}

.DesktopRegularSearchContainer {
  display: inline;
}

.SearchingPageTopHeading {
  display: none;
}

.MobileRegularSearchContainer {
  display: none;
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .regularSearchContainer {
    padding: 2rem 0rem;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .DesktopRegularSearchContainer {
    display: none;
  }

  .SearchingPageTopHeading {
    display: inline;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 16px;

    color: #494949;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0px 0px;
  }

  .MobileRegularSearchContainer {
    display: inline;
  }

  .RegularSearchButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0px 50px;
  }

  .RegularSearchButton > button {
    background: #e9117c;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 10px 30px;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    font-weight: lighter;
    line-height: 14px;
    text-align: center;

    color: #ffffff;
  }
}
