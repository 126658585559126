/* PR_Form_Tab3_NoOfChildren */
.PR_Form_Tab3_NoOfChildren {
  margin: 5px 0px 20px 23px;
  width: 488px;
}

.PR_Form_Tab3_no_of_children {
  padding-right: 0px;
  width: 95%;
  border: 3px solid rgb(211, 206, 206);
  margin-top: 10px;
  margin-bottom: 10px;
  outline: #e9117c;
}

.PR_Form_Tab3_NoOfChildren::placeholder {
  font-weight: 400;
  font-size: large;
  color: rgb(200, 191, 191);
  line-height: 5px;
  letter-spacing: 0.2px;
  font-family: Arial, Helvetica, sans-serif;
}

.PR_Form_Tab3_NoOfChildren:focus {
  border-color: #e9117c;
  box-shadow: 0 1px 1px rgba(255, 17, 124, 0.075) inset,
    0 0 8px rgba(126, 239, 104, 0.6);
  box-shadow: none;
  outline: 0 none;
}

/* PR_Tab2_buttons */
.PR_Tab3_buttons {
  display: flex;
  justify-content: center;
  padding-left: 0;
  gap: 20px;
}

.PR_Tab3_button1 {
  background-color: transparent;
  color: #e9117c;
  font-weight: 700;
  font-size: 25px;
  font-weight: 400;
  margin-top: 40px;
  outline: none;
  border-color: #e9117c;
  border-radius: 10px;
  width: 200px;
  padding: 10px 0px;
}

.PR_Tab3_button1:hover {
  background-color: #e9117c;
  color: white;
}

.PR_Tab3_button1:active {
  background-color: transparent;
  color: #e9117c;
}

.PR_Tab3_button2 {
  background-color: #e9117c;
  color: white;
  font-weight: 500;
  font-size: 25px;
  margin-top: 40px;
  outline: none;
  border: none;
  border-radius: 10px;
  width: 200px;
  padding: 10px 0px;
}

.PR_Tab3_button2:hover {
  background-color: #b91768;
}

.PR_Tab3_button2:active {
  background-color: #fa47a1;
}

.PR_Form_Tab3_Input_Fields {
  margin: 10px 0px 20px 25px;
  width: 488px;
  height: 63px;
  font-size: larger;
  font-weight: 450;
  font-family: "Times New Roman", Times, sans-serif;
  border-width: 3px;
  outline: #e9117c;
}

.PR_Page_inputs_validation_Label3 {
  margin-left: 30px;
}

.PR_FORM_Tab3_Select_option_Grey {
  color: grey;
  border: #f4f6f8 3px solid;
}

.PR_FORM_Tab3_Select_option_Black {
  color: #111;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .PR_Form_Tab3_Input_Fields {
    width: 400px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* Media Query MOBILES*********************/

@media (min-width: 319px) and (max-width: 426px) {
  .PR_Form_Tab3_no_of_children {
    margin: 0px 0px 5px 3px;
    width: 330px;
    height: 45px;
    font-size: 1.05em;
    font-weight: 450;
    font-family: "Times New Roman", Times, sans-serif;
    border-width: 3px;
    outline: #e9117c;
  }

  .PR_Form_Tab3_Input_Fields {
    margin: 0px 0px 5px 3px;
    width: 330px;
    height: 45px;
    font-size: 1.05em;
    font-weight: 450;
    font-family: "Times New Roman", Times, sans-serif;
    border-width: 3px;
    outline: #e9117c;
  }

  .PR_Page_inputs_validation_Label3 {
    margin-left: 8px;
  }

  /* PR_Tab2_buttons */
  .PR_Tab3_buttons {
    display: flex;
    justify-content: center;
    margin-left: 10px;
    gap: 20px;
  }

  .PR_Tab3_button1 {
    background-color: transparent;
    color: #e9117c;
    font-weight: 500;
    font-size: 20px;
    margin-top: 25px;
    outline: none;
    border-color: #e9117c;
    border-radius: 10px;
    width: 168px;
    box-shadow: none;
    padding: 4px 0px;
  }

  .PR_Tab3_button2 {
    background-color: #e9117c;
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-top: 25px;
    outline: none;
    border: none;
    border-radius: 10px;
    width: 168px;
  }
}
