.mobileInput > form {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
}

.mobileInput > form > input {
  width: 160px;
  height: 40px;
  border-radius: 7px;
  padding-left: 10px;
  border: 1px solid rgb(201, 199, 199);
}

.mobileInput > form > input:focus {
  outline: none;
  border: 1.8px solid #e9117c;
}
.mobileInput > form > input::placeholder {
  color: rgb(201, 199, 199);
  padding-left: 4px;
}
.mobileInput > form > button {
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  border-radius: 5px;
  width: 160px;
}

.mobileText {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 29px;

  color: #b4b9e4;
}

.mobileTextBold {
  color: #737171;
  font-weight: 600;
}

.editButon {
  color: #30b8e3;
  cursor: pointer;
  font-size: 0.8em;
}
.mobInpErr {
  color: red;
  position: absolute;
  margin-left: -260px;
  text-align: left;
  top: 40px;
  font-weight: 500;
  font-size: 13px;
}

.counter {
  color: grey;
  margin-top: 30px;
  margin-bottom: 30px;
}

.counter > span {
  color: #30b8e3;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
}

.VerifySkipdiv {
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;

  padding: 20px;
}

.VerifySkipButton {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 23px;

  color: #e9117c;
  background-color: transparent;
  border: none;
  outline: none;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {

    .mobileInput > form {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
}

    .mobileText {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 1.15em;
  line-height: 29px;

  color: #b4b9e4;

  padding: 5%;
}

.mobileInput > form > input {
  width: 140px;
  height: 50px;
  border-radius: 15px;
  padding-left: 10px;
  border: 1px solid rgb(201, 199, 199);
}

.mobileInput > form > button {
  border: none;
  outline: none;
  background-color: #e9117c;
  color: #fff;
  border-radius: 15px;
  width: 160px;
}

.counter {
  color: grey;
  margin-top: 15px;
  margin-bottom: 30px;
}

.counter > span {
  color: #30b8e3;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
}

.VerifySkipdiv {
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;

  padding: 30px 0px;
}

}
