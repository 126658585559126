.mobileVerifiedIcon > i {
  cursor: pointer;
}

.MobileViewImg {
  display: none;
}

.ApprovelPageText24{
    margin: 0px 0px 40px;
  }

   .ApprovelPageText{
    margin: 0px 0px 10px;
  }

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .MobileViewImg {
    display: inline;

  }

  .MobileViewImg > img {
    height: 120px;
    margin-top: 30%;

  }

  .Approvelheader{
    margin: 20px 0px 30px;
    color: #e9117c;
    font-weight: 500;
    font-size: 1.4em;
  }

  .ApprovelPageText{
    margin: 10px 0px 10px;
  }

  .ApprovelPageText24{
    margin: 10px 0px 40px;
  }
}
