.PR_Form_Tab4_Input_Fields {
  margin: 20px 0px 5px 25px;
  width: 500px;
  height: 63px;
  font-size: larger;
  font-weight: 450;
  font-family: "Times New Roman", Times, sans-serif;
  border-width: 3px;
  outline: #e9117c;
  cursor: pointer;
}

/*PR_Page_inputs_validation styles  */
.PR_Page_Tab4_inputs_validation_Label {
  color: #ee0909;
  margin: -10px 0px 0px 30px;
  padding-top: inherit;
  font-weight: 500;
}

.PR_Input_fields_Tab2 > input[type="number"]:focus,
.PR_Input_fields_Tab2 > input[type="text"]:focus {
  border-color: #e9117c;
  box-shadow: 0 1px 1px rgba(255, 17, 124, 0.075) inset,
    0 0 8px rgba(126, 239, 104, 0.6);
  box-shadow: none;
  outline: 0 none;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .PR_Form_Tab4_Input_Fields {
    width: 430px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
}

/********************* Media Query MOBILES*********************/

@media (min-width: 319px) and (max-width: 426px) {
.PR_Form_Tab4_Input_Fields {
   margin: 0px 0px 5px 3px;
    width: 330px;
    height: 45px;
  font-size: 1.05em;
  font-weight: 450;
  font-family: "Times New Roman", Times, sans-serif;
  border-width: 3px;
  outline: #e9117c;
}

/*PR_Page_inputs_validation styles  */
.PR_Page_Tab4_inputs_validation_Label {
  color: #ee0909;
  margin: -15px 0px 0px 13px;
  padding-top: inherit;
  font-weight: 500;
}

/*PR_Page_inputs_validation styles  */
.PR_Page_Tab4_inputs_validation_Label {
  color: #ee0909;
  margin-left: 8px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: .8em;
}

}