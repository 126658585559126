.matchesSubscribe {
  display: flex;
  justify-content: center;
}

.SubscribePopUp {
  position: fixed;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
  background-color: #fff;
  width: 1064px;
  height: 256px;
  border-radius: 25px;
}

.SubscribePopUp-Box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.SubscribePopUp-Close {
  text-align: right;
  height: 25px;
  width: 1055px;
  padding: 20px;
  cursor: pointer;
}
.SubscribePopUp-Close > i {
  text-align: right;
  color: #e9117c;
}
.SubscribePopUp-Header > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: #e9117c;
}

.SubscribePopUp-Context > p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #b4b9e4;
}

.SubscribePopUp-Buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 50px;
}

.SubscribePopUp-Button-1 > button {
  width: 245px;
  height: 60px;
  display: flex;
  align-items: center;
  font-family: "Helvetica";
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #e9117c;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #9e9e9e;
}

.SubscribePopUp-Button-2 > button {
  width: 245px;
  height: 60px;
  display: flex;  
  align-items: center;
  padding-left: 60px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  background: #e9117c;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
  .Mobile-Buttons {
    display: none;
  }
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
  .Mobile-Buttons {
    display: none;
  }
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
  .Mobile-Buttons {
    display: none;
  }
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .Buttons {
    display: none;
  }

  .SubscribePopUp-Context > p {
    font-size: 18px;
  }
  .SubscribePopUp {
    width: 795px;
    height: 260px;
  }
  .SubscribePopUp-Button-1 > button {
    width: 220px;
    height: 50px;
    font-size: 16px;
  }
  .SubscribePopUp-Button-2 > button {
    width: 220px;
    height: 50px;
    font-size: 16px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
  .Buttons {
    display: none;
  }

  .SubscribePopUp {
    width: 350px;
    border-radius: 5px;
  }
  .SubscribePopUp-Close {
    width: 350px;
  }
  .SubscribePopUp-Header > p {
    font-size: 14px;
    line-height: 16px;
  }
  .SubscribePopUp-Context > p {
    font-size: 14px;
    line-height: 16px;
    padding: 0px 10px;
  }

  .SubscribePopUp-Button-1 > button {
    width: 120px;
    height: 40px;
    font-size: 12px;
    line-height: 14px;
  }
  .SubscribePopUp-Button-2 > button {
    width: 120px;
    height: 40px;
    font-size: 12px;
    line-height: 14px;
  }
}
 @media (min-width: 319px) and (max-width: 426px){ 

 .SubscribePopUp {
    width: 350px;
    margin-right: 25px;
    margin-bottom: 442px;
  }

  .SubscribePopUp-Box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

  .SubscribePopUp-Close {
    width: 350px;
  }
  .SubscribePopUp-Header > p {
    font-size: 14px;
    line-height: 16px;
  }
  .SubscribePopUp-Context > p {
    font-size: 14px;
    line-height: 16px;
    padding: 0px 10px;
  }

  .SubscribePopUp-Button-1 > button {
    width: 120px;
    height: 40px;
    font-size: 12px;
    line-height: 14px;
  }
  .SubscribePopUp-Button-2 > button {
    width: 120px;
    height: 40px;
    font-size: 12px;
    line-height: 14px;
    padding-left: 20px;
  }

}

/********************* Media Query MOBILES*********************/

/* @media screen and (max-width: 425px) {
  .SubscribePopUp {
    width: 350px;
    margin-right: 150px;
    margin-bottom: 442px;
  }

  .SubscribePopUp-Box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

  .SubscribePopUp-Close {
    width: 350px;
  }
  .SubscribePopUp-Header > p {
    font-size: 14px;
    line-height: 16px;
  }
  .SubscribePopUp-Context > p {
    font-size: 14px;
    line-height: 16px;
    padding: 0px 10px;
  }

  .SubscribePopUp-Button-1 > button {
    width: 120px;
    height: 40px;
    font-size: 12px;
    line-height: 14px;
  }
  .SubscribePopUp-Button-2 > button {
    width: 120px;
    height: 40px;
    font-size: 12px;
    line-height: 14px;
    padding-left: 20px;
  }
} */
