/* div {
  font-family: "Helvetica";
  font-weight: 400;
} */

.ChangeEmail {
  background: #ffecd2;
  padding: 0px;
}

.ChangeEmail-Filter-Box {
  width: 382px;
  height: 382px;
}
.ChangeEmailTitle {
  background-color: #e9117c;
  color: #fff;
  padding: 1.3rem;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.ChangeEmail-Head {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  padding: 150px 150px 100px 150px;
  /* padding-top: 150px; */
  gap: 40px;
  /* margin-left: 150px; */
}
.ChangeEmail-Message-Box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 828px;
  height: 167px;
  background: #fff;
  border-radius: 25px;
  padding: 40px;
  gap: 20px;
}
.ChangeEmail-Message-Box {
  font-size: 18px;
  color: #494949;
}
.ChangeEmail-Input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.ChangeEmail-Input > input {
  width: 419px;
  border: 1px solid #b4b9e4;
  border-radius: 4px;
  padding-left: 15px;
}
.ChangeEmail-Input > input::placeholder {
  font-size: 14px;
  text-align: left;
  color: rgba(73, 73, 73, 0.5);
  /* padding: 20px; */
}

.ChangeEmail-Input > button {
  width: 140px;
  height: 40px;
  background: #e9117c;
  border-radius: 7px;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.ChangeEmail-Input > .PasswordButton {
  width: 140px;
  height: 40px;
  background: #e9117c;
  border-radius: 7px;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
}

.ChangeEmail-Input > .PasswordButton:hover {
  width: 140px;
  height: 40px;
  background: #ca1670;
  border-radius: 7px;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
}

.invalidPassword_inputs {
  margin-top: 15px;
}

.invalidPassword_label {
  margin-top: -10px;
}

.ChangeEmail-Background {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -150px;
}

.ChangeEmail-Background > img {
  width: 700px;
  height: 600px;
}

.CheckEmail_errors {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 0.85em;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.5px;
  margin-top: -10px;

  color: rgba(238, 9, 9, 0.65);
}

.DesktopChangeEmailContents {
  display: inline;
}

.MobileChangeEmailContents {
  display: none;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
}

/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .ChangeEmail-Head {
    padding: 150px 50px 150px 50px;
    gap: 20px;
  }
  .ChangeEmail-Message-Box {
    width: 640px;
    height: 150px;
  }
  .ChangeEmail-Background > img {
    width: 600px;
    height: 500px;
  }
  .ChangeEmail-Input > button {
    width: 120px;
    height: 40px;
  }
  .ChangeEmail-Input > input {
    width: 350px;
  }
}

/*********** Media Query TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
  
.ChangeEmail-Head {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  padding: 123px 109px 238px 40px;
  /* padding-top: 150px; */
  gap: 40px;
  /* margin-left: 150px; */
}
.ChangeEmail-Message-Box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 527px;
  height: 167px;
  background: #fff;
  border-radius: 25px;
  padding: 40px;
  gap: 20px;
}
.ChangeEmail-Input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 461px;
}

}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .DesktopChangeEmailContents {
    display: none;
  }
  .MobileChangeEmailContents {
    display: inline;
    position: relative;
  }

  .SettingsBoxLabel {
    align-self: flex-start;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 1.1em;
    line-height: 18px;
    color: #e9117c;
    margin: 30px 0px 20px;
    padding-left: 40px;
  }

  .MobileChangeEmailContents > h6 {
    margin: 0px 0px 20px;
    padding-left: 40px;
  }

  .ChangeEmailIDFormInput{
    display: flex;
    flex-direction: column;
  }

  .ChangeEmailIDFormInput > .CheckEmail_errors {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 0.85em;
    line-height: 16px;
    text-align: left;
    letter-spacing: 0.5px;
    padding-left: 40px;
    margin-top: 5px;
    color: rgba(238, 9, 9, 0.65);
  }

  .ChangeEmailIDFormInput > input {
    box-sizing: border-box;
    width: 80%;
    height: 34px;
    background: #ffffff;
    border: 1px solid #b4b9e4;
    border-radius: 5px;
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    padding-left: 11px;
    outline: none;
    color: rgba(73, 73, 73, 0.9);
    margin: 10px 40px;
  }

  .ChangeEmailIDFormInput > input::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8em;
    line-height: 14px;
    letter-spacing: 0.5px;

    color: #bdbdbd;
  }

  .ChangeEmailIDFormInput > .GoButton {
    background: #e9117c;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    border: none;
    outline: none;
    /* padding: 4px 35px; */
    margin: 10px 40px;

    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    font-weight: lighter;
    line-height: 16px;

    color: #ffffff;
    width: 22%;
    height: 30px;
  }

  .MobileChangeEmailContents > .ChangeEmailBGImage{
    width: 60%;
    margin: 10px 50px 0px 100px;
  }

  .MobileChangeEmailFooterRow{
    display: none;
  }
}
