.footer {
  background-color: #e9117c;
  color: #fff;
  height: 360px;
  padding: 30px 0px 0px 104px;
  overflow-x: hidden;
  z-index: 2;
  position: absolute;
  right: 0;
}
.footerHead1 {
  font-size: 25px;
}
.footerHead2 {
  font-size: 15px;
}
.footerBody {
  margin-top: 50px;
}
.footerSubHeader {
  font-weight: 500;
  margin-bottom: 30px;
}
.footerLink {
  margin-bottom: 10px;
  cursor: pointer;
}
.metaVideo {
  height: 180px;
  width: 290px;
}
.landingSubMail {
  border: none;
  outline: none;
  border-radius: 4px;
  height: 36px;
  padding-left: 10px;
  width: 300px;
  margin-top: 25px;
}

.footerNews {
  position: relative;
}
.landingSubButton {
  outline: none;
  border: none;
  height: 30px;
  position: absolute;
  left: 220px;
  margin-top: 28.5px;
  background-color: #ff9900;
  color: #fff;
  border-radius: 3px;
  width: 90px;
}

/******************* Media Query BIG SCREENS************************/

@media (min-width: 1921px) and (max-width: 2560px) {
}

/**************** Media Query DESKTOPS*****************************/

@media (min-width: 1441px) and (max-width: 1920px) {
}

/********* Media Query LAPTOPS********************/

@media (min-width: 1025px) and (max-width: 1440px) {
  .footer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .footer-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 90%;
  }

  .landingSubMail {
    /* width: 215px; */
    width: 210px;

  }
  .landingSubButton {
    height: 30px;
    left: 164px;
    width: 55px;
    font-size: 10px;
  }
  .metaVideo {
    height: 150px;
    width: 155px;
  }
  .footerLinks_mobileView{
    display: none;
  }
}


/*********** Media Query SMALL LAPTOPS*****************/

@media (min-width: 769px) and (max-width: 1024px) {
  .footer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 50px 0px 50px;
  }
  .footer-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .landingSubMail {
    height: 30px;
    width: 215px;
    margin-top: -8px;
  }
  .landingSubMail::placeholder {
    font-size: 12px;
  }
  .landingSubButton {
    bottom: 2px;
    left: 145px;
    height: 25px;
    width: 80px;
    font-size: 12px;
  }
  .metaVideo {
    height: 150px;
    width: 155px;
  }
}
/***********  TABLETS *****************/

@media (min-width: 426px) and (max-width: 768px) {
  .footer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 20px 0px 20px;
  }
  .footer-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: -20px;
  }
  .footer-row > div {
    font-size: 14px;
  }
  .footerHead1 {
    font-size: 23px;
  }
  .landingSubMail {
    height: 30px;
    width: 175px;
    margin-top: -10px;
  }
  .landingSubMail::placeholder {
    font-size: 12px;
  }
  .landingSubButton {
    position: absolute;
    /* top: 0; */
    bottom: 2px;
    left: 124px;
    height: 25px;
    width: 60px;
    font-size: 12px;
  }
  .metaVideo {
    height: 100px;
    width: 170px;
  }
}

/********************* Media Query MOBILES*********************/

@media (min-width: 319px) and (max-width: 426px) {
  .footer {
    background-color: #ee4e9d;
    color: #fff;
    height: fit-content;
    padding: 6% 12%;
    overflow-x: hidden;
    z-index: 2;
    position: absolute;
    right: 0;

    text-align: center;
  }

  .footerHead1 {
    font-size: 1.4em;
    font-weight: 600;
  }
  .footerHead2 {
    font-size: 15px;
  }

  .metaVideo {
    height: 130px;
    width: 220px;
    margin: -10px 0px 20px 0px;
  }

  .footerSubHeader {
    margin-bottom: 10px;
  }

  .subHeaderFooter {
    display: none;
  }

  .footerLink {
    margin-bottom: 10px;
    cursor: pointer;
    font-size: small;
  }

  .landingSubButton {
    outline: none;
    border: none;
    height: 30px;
    position: absolute;
    right: 10px;
    margin-top: 18px;
    background-color: #ff9900;
    color: #fff;
    border-radius: 3px;
    width: 95px;
  }

  .landingSubMail {
  border: none;
  outline: none;
  border-radius: 4px;
  height: 36px;
  padding-left: 10px;
  width: 295px;
  margin-top: 15px;
  padding-right: 0px;
}

.footerLinks_mobileView{
  display: inline;

  margin-top: 10%;
  font-weight: 500;
  letter-spacing: 0.5px;
}
}
