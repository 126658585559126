/* form container */
.formContainer {
  position: relative;
}

/* input */
.formContainer > input {
  outline: none;
  box-shadow: 0px;
  border-width: 100%;
  width: 552px;
  font-family: Arial, Helvetica, sans-serif;
  filter: blur(0);
  margin-bottom: 35px;
  height: 60px;
  padding-left: 10px;
  padding-top: 25px;
  font-size: 18px;
  border: none;
  border-bottom: 2px solid #ddd;
}
.formContainer > .loginPlaceholder {
  position: absolute;
  left: 0;
  top: 17px;
  font-size: 17px;
  padding-bottom: 250px;
  color: grey;
  transition: 0.6s;
}
.formContainer > input:focus {
  outline: none;
  border: none;
  border-bottom: 2px solid #e9117c;
}

/* .formContainer input:valid + .loginPlaceholder, */
.animatePlaceholder + .loginPlaceholder,
.formContainer input:focus + .loginPlaceholder,
.loginContainer input:focus + .loginPlaceholder {
  top: 6px;
  left: 4px;
  font-size: 14px;
  color: #e9117c;
  font-weight: 500;
}

.eyeicon {
  cursor: pointer;
  position: absolute;
  left: 280px;
  top: 20px;
}

.passwordChecking {
  height: 170px;
  padding: 20px;
  padding-top: 10px;
  width: 255px;
  position: absolute;
  margin-left: 40px;
  background: #ffe8f7;
  border-radius: 5px;
}

.MobilepasswordChecking {
  display: none;
}

.passwordCheckingHead {
  color: #e9117c;
}
.passwordCheckingRed {
  color: red;
}
.passwordCheckingGreen {
  color: green;
}
.inpErr {
  color: red;
  position: absolute;
  top: 63px;
  font-size: 12px;
  font-weight: 500;
  left: 0;
}
.inpErr > span {
  font-size: 13px;
  margin-right: 4px;
  color: red;
  font-weight: 700;
}
.passCheckIcon {
  font-size: 0.8rem;
  margin-right: 5px;
}

/********************* MOBILES *********************/

@media (min-width: 319px) and (max-width: 426px) {
  .formContainer > input {
    width: 100%;
    padding-top: 30px;
    margin-bottom: 10px;
    background: transparent;
  }

  .loginPasswordEye {
    padding-top: 0px;
  }

  .formContainer > .loginPlaceholder {
    position: absolute;
    left: 0;
    top: 25px;
    font-size: 14px;
    padding-bottom: 250px;
    color: grey;
    transition: 0.6s;
  }

  /* .formContainer input:valid + .loginPlaceholder, */
  .animatePlaceholder + .loginPlaceholder,
  .formContainer input:focus + .loginPlaceholder,
  .loginContainer input:focus + .loginPlaceholder {
    top: 10px;
    left: 4px;
    font-size: 14px;
    color: #e9117c;
    font-weight: 500;
  }

  .passwordChecking {
    display: none;
  }

  .MobilepasswordChecking {
    display: none;
    height: 170px;
    padding: 20px;
    padding-top: 10px;
    width: 255px;
    position: absolute;
    margin-left: 40px;
    background: #ffe8f7;
    border-radius: 5px;
  }

  .passwordCheckingHead {
    color: #e9117c;
  }
  .passwordCheckingRed {
    color: red;
  }
  .passwordCheckingGreen {
    color: green;
  }
}
